import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import themesApi from '../api/themes.api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';

export const CONTENT_THEMES_FEATURE_KEY = 'contentThemes';
export const contentThemesAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const setSelectedContentThemeId = createAction(
  'SET_SELECTED_CONTENT_THEME_ID',
);

export const fetchContentThemesForContentRepository = createAsyncThunk(
  `${CONTENT_THEMES_FEATURE_KEY}/fetchThemes`,
  async ({ contentRepositoryId }, { rejectWithValue }) => {
    try {
      return await themesApi.getThemesForContentRepository({
        contentRepositoryId,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);
export const initialContentThemesState = contentThemesAdapter.getInitialState({
  selected: null,
  loadingStatus: requestStatus.initial,
  error: null,
});

const mapAiToStore = (theme) => ({
  ...theme,
  label: theme.title,
});

export const contentThemesSlice = createSlice({
  name: CONTENT_THEMES_FEATURE_KEY,
  initialState: initialContentThemesState,
  reducers: {
    add: contentThemesAdapter.addOne,
    remove: contentThemesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedContentThemeId, (state, { payload }) => {
        state.selected = payload;
      })

      .addCase(fetchContentThemesForContentRepository.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(
        fetchContentThemesForContentRepository.fulfilled,
        (state, { payload }) => {
          contentThemesAdapter.setAll(state, payload.data.map(mapAiToStore));
          state.loadingStatus = requestStatus.fulfilled;
        },
      )
      .addCase(
        fetchContentThemesForContentRepository.rejected,
        (state, { error }) => {
          state.loadingStatus = requestStatus.error;
          state.error = error.message;
        },
      );
  },
});

export const contentThemesReducer = contentThemesSlice.reducer;
export const contentThemesActions = contentThemesSlice.actions;

const { selectAll, selectEntities } = contentThemesAdapter.getSelectors();

export const getContentThemesState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][CONTENT_THEMES_FEATURE_KEY];

export const contentThemesConfigSelectors = {
  selectLoadingStatus: createSelector(
    getContentThemesState,
    (state) => state.loadingStatus,
  ),
  selectAllThemes: createSelector(getContentThemesState, selectAll),
  selectThemesEntities: createSelector(getContentThemesState, selectEntities),
};
