import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import requestStatus from './requestStatus';
import { PLATFORM_FEATURE_KEY } from './index';
import toolApi from '../api/tool.api';
import {getActiveToolsWithLabels, isLocalhost} from '@clatter/platform';
export const TOOLS_FEATURE_KEY = 'tools';

export const toolsAdapter = createEntityAdapter({
  selectId: (row) => row.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchToolsConfig = createAsyncThunk(
  `${TOOLS_FEATURE_KEY}/fetch`,
  toolApi.getConfiguration,
);

export const addToolTemplate = createAsyncThunk(
  `${TOOLS_FEATURE_KEY}/add_template`,
  async ({ toolId, templateId }, { rejectWithValue }) => {
    try {
      const response = await toolApi.addToolTemplate({
        toolId: toolId,
        templateId: templateId,
      });

      return Promise.resolve(response.data);
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
        error: true,
      });
    }
  },
);

const isPendingAction = (action) => action.type.endsWith(`/pending`);

const isRejectedAction = (action) => action.type.endsWith(`/rejected`);

export const initialToolsState = toolsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});

const mapApiToStore = (tool) => {
  // let's check if we are running the app on localhost if so, we need to map tools urls to localhost ones
  const getToolUrl = (tool) => {
    if (!isLocalhost()) {
      return tool.url;
    }

    const url = new URL(tool.url);

    return url.href.replace(url.origin, `http://localhost:${tool.local_port === 2000 ? 3006 : tool.local_port}`);
  };

  return {
    ...tool,
    label: tool.name,
    url: getToolUrl(tool),
  };
};

export const toolsSlice = createSlice({
  name: TOOLS_FEATURE_KEY,
  initialState: initialToolsState,
  reducers: {
    add: toolsAdapter.addOne,
    remove: toolsAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToolsConfig.fulfilled, (state, { payload }) => {
        toolsAdapter.setAll(state, payload.data.tools.map(mapApiToStore));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(addToolTemplate.fulfilled, (state, { payload }) => {
        toolsAdapter.upsertOne(state, mapApiToStore(payload));
      })
      .addMatcher(isPendingAction, (state, action) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addMatcher(isRejectedAction, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

const { selectAll, selectTotal } = toolsAdapter.getSelectors();
export const getToolsState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][TOOLS_FEATURE_KEY];

export const selectToolsNamesMap = () =>
  createSelector(getToolsState, ({ entities }) => {
    return Object.keys(entities).reduce((acc, key) => {
      acc[entities[key].slug] = entities[key].name;
      return acc;
    }, {});
  });

export const toolConfigSelectors = {
  selectAllTools: createSelector(getToolsState, selectAll),
  selectTotal: createSelector(getToolsState, selectTotal),
  selectActiveToolsLabels: createSelector(
    getToolsState,
    (state) => {
      return getActiveToolsWithLabels(
        (selectAll(state) || []).filter(tool => tool.hasOwnProperty('active') && tool.active)
      );
    },
  ),
  selectToolsNamesMap: selectToolsNamesMap,
};
