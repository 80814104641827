import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
import requestStatus from './requestStatus';
import featureFlagApi from '../api/featureFlag.api';
export const FEATUREFLAG_FEATURE_KEY = 'featureFlag';

export const featureFlagAdapter = createEntityAdapter({
  selectId: (row) => row.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialFeatureFlagState = featureFlagAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});

export const fetchFeatureFlag = createAsyncThunk(
  `${FEATUREFLAG_FEATURE_KEY}/fetch`,
  featureFlagApi.fetchFeatureFlag,
);

export const updateFeatureFlag = createAsyncThunk(
  `${FEATUREFLAG_FEATURE_KEY}/update`,
  async ({ id, active }, { rejectWithValue }) => {
    try {
      const response = await featureFlagApi.updateFeatureFlag({
        id: id,
        active: active,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const featureFlagSlice = createSlice({
  name: FEATUREFLAG_FEATURE_KEY,
  initialState: initialFeatureFlagState,
  reducers: {
    add: featureFlagAdapter.addOne,
    remove: featureFlagAdapter.removeOne,
    addBulk: featureFlagAdapter.addMany,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureFlag.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchFeatureFlag.fulfilled, (state, { payload }) => {
        featureFlagAdapter.setAll(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchFeatureFlag.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
      .addCase(updateFeatureFlag.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateFeatureFlag.fulfilled, (state, { payload }) => {
        featureFlagAdapter.upsertOne(state, payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateFeatureFlag.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      });
  },
});

const { selectAll, selectEntities } = featureFlagAdapter.getSelectors();
export const getFeatureFlagState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][FEATUREFLAG_FEATURE_KEY];

export const featureFlagSelectors = {
  loadingStatus: createSelector(
    getFeatureFlagState,
    ({ loadingStatus }) => loadingStatus,
  ),
  selectAllFeatureFlags: createSelector(getFeatureFlagState, selectAll),
  selectFeatureFlagEntities: createSelector(
    getFeatureFlagState,
    selectEntities,
  ),
};
