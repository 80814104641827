import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useInterval } from '@clatter/platform';

const StyledMessage = styled.div`
  span {
    font-weight: bold;
  }
`;

const CountdownTimer = ({ miliseconds }) => {
  const [countdown, setCountdown] = useState(miliseconds);
  const seconds = (countdown / 1000).toFixed();

  useInterval(
    () => setCountdown((prev) => prev - 10),
    countdown <= 0 ? null : 10,
  );

  return (
    <StyledMessage>
      Please wait for <span>{seconds}</span>s
    </StyledMessage>
  );
};

CountdownTimer.propTypes = {
  miliseconds: PropTypes.number,
};

export default CountdownTimer;
