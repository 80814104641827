import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const StyledButton = styled.button`
  cursor: pointer;
  background: white;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.buttonTextColor};
  border: 1px solid ${({ theme }) => theme.palette.grey};

  > span {
    flex-grow: 1;
  }

  &.has-menu-items {
    padding: 0 0 0 16px;
  }
`;

const StyledButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  height: 100%;
  max-width: 50px;
  justify-self: flex-end;
  padding: 0 10px;
  border-left: 1px solid ${({ theme }) => theme.palette.darkGrey};
`;

const StyledMenuContainer = styled.div`
  position: relative;
`;

const StyledMenu = styled.div`
  position: absolute;
  display: none;
  background-color: white;
  border-radius: 5px;
  z-index: 999999;
  min-width: 180px;
  top: 45px;
  right: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey};
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  &.opened {
    display: block;
  }
`;

const StyledMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 30px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverBackgroundColor};
  }

  &.item-divider {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey};
  }
`;

const DownloadSlidesButton = ({ onClick, menuItems }) => {
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef(null);

  const hasMultipleMenuItems = useMemo(
    () => Array.isArray(menuItems) && menuItems.length > 1,
    [menuItems],
  );

  const handleButtonClick = () => {
    if (hasMultipleMenuItems) {
      return setOpened((prev) => !prev);
    }
    onClick(menuItems[0].value);
  };

  const handleClickOutside = (event) => {
    if (opened && dropDownRef && !dropDownRef?.current?.contains(event?.target)) {
      setOpened(false);
    }
  };

  const handleMenuItemClick = (value) => {
    onClick(value);
    setOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);

  return (
    <>
      <StyledMenuContainer ref={dropDownRef}>
        <StyledButton
          onClick={handleButtonClick}
          className={classNames({ 'has-menu-items': hasMultipleMenuItems })}
          data-test-id="content-slide-download-button"
        >
          <span>Download</span>

          {hasMultipleMenuItems && (
            <StyledButtonIcon>
              {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </StyledButtonIcon>
          )}
        </StyledButton>

        {hasMultipleMenuItems && (
          <StyledMenu className={opened && 'opened'}>
            {menuItems.map(({ label, value, testId }, index) => (
              <StyledMenuItem
                key={value}
                className={classNames({
                  'item-divider': index !== menuItems.length - 1,
                })}
                onClick={() => handleMenuItemClick(value)}
                data-test-id={testId}
              >
                {label}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        )}
      </StyledMenuContainer>
    </>
  );
};

DownloadSlidesButton.propTypes = {
  onClick: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      testId: PropTypes.string.isRequired,
    }),
  ),
};

export default DownloadSlidesButton;
