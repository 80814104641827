import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import contentSlidesApi from '../api/contentSlides.api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';
import {
  getContentSlidesDecksIds,
  getFilteredSlides,
  getSlides,
  getSlidesdGroups,
  updateFilteredSlides,
} from '../helpers';
import { cloneDeep, difference } from 'lodash';

export const CONTENT_SLIDES_FEATURE_KEY = 'contentSlides';
export const contentSlidesAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const setSelectedContentSlidesIds = createAction(
  'SET_SELECTED_CONTENT_SLIDES_IDS',
);

export const setSelectedContentSlide = createAction(
  'SET_SELECTED_CONTENT_SLIDE',
);

export const clearSelectedContentSlide = createAction(
  'CLEAR_SELECTED_CONTENT_SLIDE',
);

export const resetContentSlidesState = createAction(
  'RESET_CONTENT_SLIDES_STATE',
);

export const fetchContentSlides = createAsyncThunk(
  `${CONTENT_SLIDES_FEATURE_KEY}/fetchContentSlides`,

  async ({ contentRepositoryId, themeId }, { rejectWithValue }) => {
    try {
      const slidesRequest = contentSlidesApi.getContentSlides({
        contentRepositoryId,
        themeId,
      });

      const blankSlidesRequest = contentSlidesApi.getContentSlidesByType({
        contentRepositoryId,
        themeId,
        type: 'Blank',
      });

      const coverSlidesRequest = contentSlidesApi.getContentSlidesByType({
        contentRepositoryId,
        themeId,
        type: 'Cover',
      });

      const dividerSlidesRequest = contentSlidesApi.getContentSlidesByType({
        contentRepositoryId,
        themeId,
        type: 'Divider',
      });

      const [
        slidesResponse,
        blankSlidesResponse,
        coverSlidesResponse,
        dividerSlidesResponse,
      ] = await Promise.all([
        slidesRequest,
        blankSlidesRequest,
        coverSlidesRequest,
        dividerSlidesRequest,
      ]);

      const slides = getSlides(slidesResponse.data);

      return {
        slides: slides,
        hierarchy: slidesResponse.data,
        blankSlides: blankSlidesResponse.data,
        coverSlides: coverSlidesResponse.data,
        dividerSlides: dividerSlidesResponse.data,
        requiredSlides: slides.filter((slide) => slide.isRequired) || [],
      };
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const logDownloadContentSlide = createAsyncThunk(
  `${CONTENT_SLIDES_FEATURE_KEY}/logDownloadContentSlide`,
  async (data) => await contentSlidesApi.downloadContentSlide(data),
);

export const initialContentSlidesState = contentSlidesAdapter.getInitialState({
  selectedContentSlidesIds: [],
  selectedContentSlide: null,
  slides: [],
  blankSlides: [],
  groups: null,
  coverSlides: [],
  dividerSlides: [],
  requiredSlidesIds: [],
  decksIds: [],
  loadingStatus: requestStatus.initial,
  error: null,
});

export const contentSlidesSlice = createSlice({
  name: CONTENT_SLIDES_FEATURE_KEY,
  initialState: initialContentSlidesState,
  reducers: {
    add: contentSlidesAdapter.addOne,
    remove: contentSlidesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedContentSlidesIds, (state, { payload }) => {
        const selectedWithoutRequired = difference(
          payload,
          state.requiredSlidesIds,
        );
        state.selectedContentSlidesIds = selectedWithoutRequired;
      })
      .addCase(setSelectedContentSlide, (state, { payload }) => {
        state.selectedContentSlide = payload;
      })
      .addCase(clearSelectedContentSlide, (state) => {
        state.selectedContentSlide = null;
      })

      .addCase(fetchContentSlides.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchContentSlides.fulfilled, (state, { payload }) => {
        contentSlidesAdapter.setAll(state, payload.hierarchy);
        state.slides = payload.slides;
        state.groups = getSlidesdGroups(payload.slides);
        state.requiredSlidesIds = payload.requiredSlides.map(
          (slide) => slide._id,
        );
        state.decksIds = getContentSlidesDecksIds(payload.hierarchy);
        state.blankSlides = payload.blankSlides;
        state.coverSlides = payload.coverSlides;
        state.dividerSlides = payload.dividerSlides;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentSlides.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(resetContentSlidesState, (state) => initialContentSlidesState);
  },
});

export const contentSlidesReducer = contentSlidesSlice.reducer;

const { selectAll, selectEntities } = contentSlidesAdapter.getSelectors();

export const getContentSlidesState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][CONTENT_SLIDES_FEATURE_KEY];

export const contentSlidesConfigSelectors = {
  selectLoadingStatus: createSelector(
    getContentSlidesState,
    (state) => state.loadingStatus,
  ),
  selectAllContentSlides: createSelector(getContentSlidesState, selectAll),
  selectSelectedContentSlidesEntities: createSelector(
    getContentSlidesState,
    selectEntities,
  ),
  selectedWithRequiredSlidesIds: createSelector(
    getContentSlidesState,
    ({ selectedContentSlidesIds, requiredSlidesIds }) => {
      return [...requiredSlidesIds, ...selectedContentSlidesIds];
    },
  ),
  selectSelectedContentSlidesIds: createSelector(
    getContentSlidesState,
    ({ selectedContentSlidesIds }) => {
      return selectedContentSlidesIds;
    },
  ),
  selectSelectedSlidesCount: createSelector(
    getContentSlidesState,
    ({ selectedContentSlidesIds }) => {
      return selectedContentSlidesIds.length;
    },
  ),
  selectContentSlidesByIds: createSelector(
    getContentSlidesState,
    (state, slidesIds) => slidesIds,
    ({ slides }, slidesIds) => {
      if (slidesIds && slidesIds.length > 0) {
        return slidesIds.map(id => slides.find(item => item._id === id)).filter(Boolean);
      }
      return [];
    },
  ),
  selectAllSlidesByIdsMap: createSelector(
    getContentSlidesState,
    (state, slidesIds) => slidesIds,
    ({ slides, coverSlides, dividerSlides, blankSlides }, slidesIds) => {
      return [...slides, ...coverSlides, ...dividerSlides, ...blankSlides].reduce((acc, item) => {
        acc[item?._id] = item
        return acc;
      }, {})
    },
  ),
  selectAnySlideTypeById: (slideId) =>
    createSelector(
      getContentSlidesState,
      ({ slides, blankSlides, coverSlides, dividerSlides }) =>
        [...slides, ...blankSlides, ...coverSlides, ...dividerSlides].find(
          (item) => item._id === slideId,
        ),
    ),
  selectContentSlidesHierarchyIds: createSelector(
    getContentSlidesState,
    ({ slides }) => {
      return slides.map(({ _id }) => _id);
    },
  ),
  selectContentSlidesHierarchy: createSelector(
    getContentSlidesState,
    ({ slides, blankSlides, dividerSlides, coverSlides }) => {
      const slidesMap = slides.reduce((acc, slide) => {
        acc[slide._id] = slide;
        return acc;
      }, {});
      const dividersMap = dividerSlides.reduce((acc, slide) => {
        acc[slide._id] = slide;
        return acc;
      }, {});
      const blankMap = blankSlides.reduce((acc, slide) => {
        acc[slide._id] = slide;
        return acc;
      }, {});
      const coversMap = coverSlides.reduce((acc, slide) => {
        acc[slide._id] = slide;
        return acc;
      }, {});
      return {
        ...slidesMap,
        ...dividersMap,
        ...blankMap,
        ...coversMap,
      };
    },
  ),
  selectContentSlidesByFilters: createSelector(
    getContentSlidesState,
    (state) => {
      return {
        selectedContentFiltersIds:
          state.platform.contentFilters.selectedContentFiltersIds,
        repoFilters: [...Object.values(state.platform.contentFilters.entities)],
      };
    },
    ({ slides, entities }, { selectedContentFiltersIds, repoFilters }) => {
      //@todo - resolve immutability issue here
      const hierarchy = cloneDeep([...Object.values(entities)]);
      if (selectedContentFiltersIds && selectedContentFiltersIds.length > 0) {
        const filteredSlides = getFilteredSlides(
          slides,
          repoFilters,
          selectedContentFiltersIds,
        );
        updateFilteredSlides(hierarchy, filteredSlides);
      }
      return hierarchy;
    },
  ),
};

export const contentSlidesActions = {
  clearSelectedContentSlide,
  setSelectedContentSlidesIds,
  setSelectedContentSlide,
};
