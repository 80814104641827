import React, { useState } from 'react';
import styled from 'styled-components';
import { sidebarNavLinkBaseStyles } from './sidebarNavLinkBaseStyles';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import SidebarNavLink from './SidebarNavLink';

const StyledExpandableNavLinkItem = styled.div`
  ${sidebarNavLinkBaseStyles}
  cursor: pointer;
`;

const StyledExpandIcon = styled.div`
  position: relative;
  margin-left: auto;
  top: 4px;
`;

const SidebarExpandableNavLink = ({ item }) => {
  const [opened, setOpened] = useState(true);
  const { label, children, icon: Icon } = item;

  return (
    <>
      <StyledExpandableNavLinkItem onClick={() => setOpened(!opened)}>
        <Icon />
        <span>{label}</span>
        <StyledExpandIcon>
          {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledExpandIcon>
      </StyledExpandableNavLinkItem>

      {opened &&
        children &&
        Array.isArray(children) &&
        children.map((listItem) => (
          <SidebarNavLink item={listItem} key={listItem.url} isIndented/>
        ))}
    </>
  );
};

export default SidebarExpandableNavLink;
