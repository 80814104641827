import React from 'react';
import classNames from 'classnames';
import { StyledCheckbox } from '.';

const Checkbox = React.forwardRef(({ text, ...inputProps }, ref) => (
  <StyledCheckbox withLabel={!!text?.length}>
    <input
      className={classNames('checkbox-input', { checked: inputProps?.checked })}
      type="checkbox"
      {...inputProps}
      ref={ref}
    />
    {!!text && <span className="checkbox-text">{text}</span>}
  </StyledCheckbox>
));

export default Checkbox;
