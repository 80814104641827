import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const pxToRem = (px) => `${px / 16}rem`;
const round = (value) => Math.round(value * 1e5) / 1e5;

const buildVariant = (fontWeight, size, lineHeight, letterSpacing) => ({
  fontWeight,
  fontSize: pxToRem(size),
  lineHeight,
  letterSpacing: `${round(letterSpacing / size)}em`,
});

const variants = {
  body1: buildVariant(400, 18, 1.5, 0.15),
  body2: buildVariant(400, 16, 1.43, 0.15),
  h4: buildVariant(500, 20, 1.5, 0.15),
};

const StyledTypography = styled.div`
  color: ${({ theme }) => theme?.sectionHeader?.color};
  font-family: ${({ theme }) => theme?.sectionHeader?.fontFamily};
  font-weight: ${({ variant }) => variants[variant].fontWeight};
  font-size: ${({ variant }) => variants[variant].fontSize};
  line-height: ${({ variant }) => variants[variant].lineHeight};
  letter-spacing: ${({ variant }) => variants[variant].letterSpacing};
`;

const Typography = ({
  variant = 'body1',
  component = 'p',
  children,
  className,
  ...props
}) => {
  let usedVariant = variant in variants ? variant : 'body1';

  if (!variant in variants) {
    console.warn(
      `Invalid prop 'variant' (${variant}). Using body1 as a fallback.`,
    );
  }

  return (
    <StyledTypography
      variant={usedVariant}
      className={classNames(usedVariant, className)}
      as={component}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['body1', 'body2', 'h4']),
  component: PropTypes.oneOf(['a', 'h4', 'div', 'p', 'span', 'section']),
};
export default Typography;
