export { default as BuildChoiceView } from './BuildChoiceView';
export { default as BuildStateButtons } from './BuildStateButtons';
export { default as SharePresentationDialog } from './SharePresentationDialog';
export * from './BuildPresentationLoader';

export const deckBuildStatuses = {
  completed: 'Completed',
  inProgress: 'In Progress',
  failed: 'Failed',
};
