import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@clatter/ui';

const StyledStatusIcon = styled.div`
  cursor: default;
  position: relative;
  width: 22px;
  height: 22px;
  font-size: 1.1em;
  border-radius: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  flex-shrink: 0;
  margin-left: 1.5em;

  &.slide-new {
    background-color: ${({ theme }) => {
      return theme.slideStatusInfo.newBackgroundColor;
    }};
    color: ${({ theme }) => theme.slideStatusInfo.newTextColor};
  }

  &.slide-updated {
    background-color: ${({ theme }) =>
      theme.slideStatusInfo.updatedBackgroundColor};
    color: ${({ theme }) => theme.slideStatusInfo.updatedTextColor};
  }
`;

const SlideStatusInfo = ({ status }) => {
  return (
    <Tooltip text={status === 'new' ? 'New Slide' : 'Updated Slide'} left>
      <StyledStatusIcon
        title={status === 'new' ? 'New' : 'Updated'}
        className={classNames(
          { 'slide-new': status === 'new' },
          { 'slide-updated': status === 'updated' },
        )}
      >
        {status === 'new' ? 'N' : 'U'}
      </StyledStatusIcon>
    </Tooltip>
  );
};

SlideStatusInfo.propTypes = {
  status: PropTypes.oneOf(['new', 'updated']),
};

export default SlideStatusInfo;
