import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Button, Card, CardContent, Checkbox, FormControl } from '@clatter/ui';
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

const StyledHeader = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledItem = styled.div`
  width: 100%;

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledExpandIcon = styled.div`
  cursor: pointer;
`;

const RepositoryFilters = ({ options, expandAll, onChange }) => {
  const filtersArray = Object.keys(options);
  const [openedFilters, setOpenedFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const isOpened = (id) => !!openedFilters.find((filterId) => filterId === id);

  useEffect(() => {
    onChange(selectedFilters);
  }, [selectedFilters.length]);

  const handleClearAllClicked = () => setSelectedFilters([]);

  const checkIfAllSelected = (groupId) => {
    const children = options[groupId]?.children || [];

    return (children || []).every(({ _id: childrenId }) =>
      selectedFilters.includes(childrenId),
    );
  };

  const handleSelectAllClicked = (groupId) => {
    const children = (options[groupId]?.children || []).map(({ _id }) => _id);
    if (checkIfAllSelected(groupId)) {
      return uncheckCheckbox(children);
    }
    return checkCheckbox(children);
  };

  const handleShowFilters = (id) => {
    if (isOpened(id)) {
      return setOpenedFilters(
        openedFilters.filter((filterId) => filterId !== id),
      );
    }
    return setOpenedFilters([...openedFilters, id]);
  };

  useEffect(() => {
    if (expandAll) {
      setOpenedFilters(Object.keys(options));
    }
  }, [expandAll, options]);

  const checkCheckbox = (checkCheckboxes) => {
    let checkCheckboxesArray = checkCheckboxes;
    if (!Array.isArray(checkCheckboxes)) {
      checkCheckboxesArray = [checkCheckboxes];
    }

    setSelectedFilters([
      ...new Set([...selectedFilters, ...checkCheckboxesArray]),
    ]);
  };

  const uncheckCheckbox = (clickedCheckboxIds) => {
    let uncheckArray = clickedCheckboxIds;
    if (!Array.isArray(clickedCheckboxIds)) {
      uncheckArray = [clickedCheckboxIds];
    }

    setSelectedFilters([
      ...new Set(selectedFilters.filter((id) => !uncheckArray.includes(id))),
    ]);
  };

  const toggleCheckbox = (clickedCheckboxId) => {
    if (selectedFilters.includes(clickedCheckboxId)) {
      return uncheckCheckbox(clickedCheckboxId);
    }
    return checkCheckbox(clickedCheckboxId);
  };

  return (
    <Card data-testid="select-filters-card">
      <CardContent>
        <StyledHeader>
          <h3 style={{ marginBottom: 0 }}>Filters</h3>
          <Button variant="button-clear" onClick={handleClearAllClicked}>
            Clear All
          </Button>
        </StyledHeader>

        {filtersArray.map((groupId) => {
          const { label: groupLabel, children } = options[groupId];
          return (
            <StyledItem key={groupId}>
              <div
                className="item-header"
                onClick={() => handleShowFilters(groupId)}
              >
                <h4>{groupLabel}</h4>
                <StyledExpandIcon>
                  {isOpened(groupId) ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </StyledExpandIcon>
              </div>

              {isOpened(groupId) && (
                <>
                  <FormControl>
                    <Checkbox
                      onChange={() => handleSelectAllClicked(groupId)}
                      checked={checkIfAllSelected(groupId)}
                      text="All"
                    />
                  </FormControl>
                  {children.map(({ _id: childrenId, label: childrenLabel }) => (
                    <FormControl key={childrenId}>
                      <Checkbox
                        checked={selectedFilters.includes(childrenId)}
                        onChange={() => toggleCheckbox(childrenId)}
                        value={childrenId}
                        text={childrenLabel}
                      />
                    </FormControl>
                  ))}
                </>
              )}
            </StyledItem>
          );
        })}
      </CardContent>
    </Card>
  );
};

RepositoryFilters.propTypes = {
  options: PropTypes.object.isRequired,
  expandAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default RepositoryFilters;
