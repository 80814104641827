import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep, get, orderBy } from 'lodash';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  DeleteOutlined as DeleteOutlinedIcon
} from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import Checkbox from '../Forms/Checkbox';
import Table from '../Table/Table';
import { FormControl, TextControl } from '../Forms';
import useSortModel from '../../hooks/useSortModel';
import classNames from "classnames";
import { IconButton } from "../../index";
import { useDeepCompareEffect, useDeepCompareMemo } from "@clatter/platform";

const StyledTableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`

const compareIdentifiers = (first, second) => {
  const firstType = typeof first;
  const secondType = typeof second;

  if (firstType === secondType) {
    return first === second;
  }

  if (firstType === 'string' && secondType === 'number') {
    return first === second.toString();
  }

  if (firstType === 'number' && secondType === 'string') {
    return first === Number(second);
  }

  return false;
};

const StyledDataTable = styled.div`
  .table-scroll {
    overflow-y: auto;
  }

  .table-th-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-th-label {
    line-height: 24px;
  }

  .selected-row {
    background-color: ${({ theme }) => theme?.palette?.grey || 'lightgrey'};
  }

  .sortable-header {
    cursor: pointer;
  }

  .table-th-sort {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }
  }

  ${(props) =>
    !props.autofit
      ? `
    .table-scroll {
      max-height: 200px;
    }
      `
      : `
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;

    .table-scroll {
      max-height: auto;
    }
  `}
`;

export const defaultSortFunction = (array, key, sort = 'asc') =>
  orderBy(array, key, sort);

const DataTable = ({
  autofit = false,
  columns,
  defaultSortField,
  filterColumns = [],
  name,
  noRowsMessage = 'No items',
  onSelectionChange,
  rows = [],
  onRemoveItems,
  onReorder,
  selectedRows,
  fullHeight = false,
  selectable = false,
  stickyHeader = true,
  hideSelectAllCheckbox = false,
  disableSearch = false,
  selectableActions = false,
  renderActionColumn,
}) => {
  const { sortModel, handleSortModelChange } = useSortModel(name);
  const [selected, setSelected] = useState(selectedRows || []);
  const [displayRows, setDisplayRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const selectAllCheckboxRef = useRef(null);

  const canMoveDown = useDeepCompareMemo(() => {
    if (selected?.length) {
      const selectedItemsIds = selected?.map(item => item?.id);
      return !selectedItemsIds.includes(displayRows[displayRows.length -1]?.id)
    }
    return false;
  }, [selected, displayRows])

  const canMoveUp = useDeepCompareMemo(() => {
    if (selected?.length) {
      const selectedItemsIds = selected?.map(item => item?.id);
      return !selectedItemsIds.includes(displayRows[0]?.id)
    }
    return false;
  }, [selected, displayRows]);

  useEffect(() => {
    let nextDisplayRows = Array.isArray(rows) ? rows : [];

    if (searchTerm) {
      nextDisplayRows = nextDisplayRows.filter((row) =>
        filterColumns.some((column) =>
          row[column]?.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }

    if (defaultSortField || sortModel?.field) {
      setDisplayRows(rows);

      const sortColumn =
        sortModel?.field &&
        columns.find(({ name }) => name === sortModel?.field);

      nextDisplayRows = defaultSortFunction(
        nextDisplayRows,
        sortColumn?.customSortValue || sortModel?.field || defaultSortField,
        sortModel?.sort,
      );
    }

    setDisplayRows(nextDisplayRows);
  }, [rows, sortModel, searchTerm, defaultSortField, filterColumns.length]);

  useEffect(() => {
    if (selectable && Array.isArray(selectedRows)) {
      setSelected(selectedRows);
    }
  }, [selectedRows, selectable]);

  const isSelected = (itemId) =>
    selected.some((item) => compareIdentifiers(itemId, item.id));

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    let nextSelected = [];

    if (selectableActions) {
      if (!selected.length) {
        nextSelected = rows.map((row) => row);
      }
    } else {
      if (event.target.checked) {
        nextSelected = rows.map((row) => row);
      }
    }

    typeof onSelectionChange === 'function' && onSelectionChange(nextSelected);
    setSelected(nextSelected);
  };

  const handleSelectClick = (event) => {
    const clickedItem = rows.find((item) =>
      compareIdentifiers(item.id, event.target.value),
    );

    let nextSelected = cloneDeep(selected);

    if (selected.some((item) => clickedItem.id === item.id)) {
      nextSelected = nextSelected.filter((item) => clickedItem.id !== item.id);
    } else {
      nextSelected.push(clickedItem);
    }

    typeof onSelectionChange === 'function' && onSelectionChange(nextSelected);
    setSelected(nextSelected);
  };

  const handleSortClick = (event) => {
    const { sortField } = event.currentTarget.dataset;
    if (sortModel?.field === sortField && sortModel?.sort === 'desc') {
      handleSortModelChange([]);
    } else {
      const nextSortModel = {
        field: sortField,
        sort: sortModel?.field === sortField ? 'desc' : 'asc',
      };
      handleSortModelChange([nextSortModel]);
    }
  };

  const handleRemoveClick = () => {
    if (window.confirm(`Remove ${selected.length} item(s) from list?`)) {
      const selectedIds = selected.map(item => item.id);
      const updatedDisplayRows = displayRows.filter(item => !selectedIds.includes(item.id));
      onRemoveItems(updatedDisplayRows);
      setSelected([]);
    }
  }

  const handleReorder = (direction) => {
    // Find the indexes of selected rows in the displayRows array
    const selectedIndexes = selected.map((selectedRow) =>
      displayRows.findIndex((row) => row.id === selectedRow.id)
    );

    // Sort the selected indexes based on the direction
    const sortedIndexes =
      direction === 'up'
        ? selectedIndexes.sort((a, b) => a - b)
        : selectedIndexes.sort((a, b) => b - a);

    // Calculate the new indexes for the selected rows after the reorder
    const newIndexes = sortedIndexes.map((index) =>
      direction === 'up' ? index - 1 : index + 1
    );

    // Create a copy of the displayRows array
    const updatedDisplayRows = [...displayRows];

    // Swap the positions of selected rows in the array
    sortedIndexes.forEach((selectedIndex, i) => {
      const newIndex = newIndexes[i];
      const [removedRow] = updatedDisplayRows.splice(selectedIndex, 1);
      updatedDisplayRows.splice(newIndex, 0, removedRow);
    });

    // Use setDisplayRows to update the state with the new displayRows
    onReorder(updatedDisplayRows);
  }

  const renderCell = (row, column) => {
    if (typeof column.customRender === 'function') {
      return column.customRender(row, get(row, column.name));
    }

    return get(row, column.name);
  };

  const renderRows = () => {
    if (!displayRows || !displayRows.length) {
      const columnsCount =
        typeof renderActionColumn === 'function'
          ? columns.length + 1
          : columns.length;
      return (
        <tr>
          <td colSpan={columnsCount}>{noRowsMessage}</td>
        </tr>
      );
    }

    return displayRows.filter(row => row?.id).map((row, index) => {
      const isRowSelected = isSelected(row.id);

      return (
        <tr key={row.id} className={classNames({ 'selected-row': isRowSelected })}>
          {selectable && (
            <td>
              <Checkbox
                checked={isRowSelected}
                value={row.id}
                onChange={handleSelectClick}
              />
            </td>
          )}
          {columns.map((column) => (
            <td key={`${row.id}-${column.name}`} align={column.align}>
              {renderCell(row, column)}
            </td>
          ))}
          {typeof renderActionColumn === 'function' && (
            <td align="right">{renderActionColumn(row, index)}</td>
          )}
        </tr>
      )}
    );
  };

  const renderSortIndicator = (column) => {
    if (sortModel?.field !== column.name) {
      return <SortIcon />;
    }

    return sortModel?.sort === 'desc' ? (
      <ArrowDownwardIcon />
    ) : (
      <ArrowUpwardIcon />
    );
  };

  const renderHeaderCell = (column) => {
    if (column.sortable) {
      return (
        <th
          className="sortable-header"
          onClick={handleSortClick}
          data-sort-field={column.name}
          key={column.label}
          align={column.align || 'left'}
        >
          <div className="table-th-sort">
            <div className="table-th-label">{column.label}</div>
            {renderSortIndicator(column)}
          </div>
        </th>
      );
    }

    return (
      <th key={column.label} align={column.align || 'left'}>
        <div className="table-th-label">{column.label}</div>
      </th>
    );
  };

  useDeepCompareEffect(() => {
    if (selectableActions) {
      if (!selectAllCheckboxRef?.current?.indeterminate && selected?.length && selected?.length < displayRows?.length) {
        selectAllCheckboxRef.current.indeterminate = true
        return;
      }

      if (selectAllCheckboxRef?.current?.indeterminate && !selected?.length) {
        selectAllCheckboxRef.current.indeterminate = false
      }

      if (selectAllCheckboxRef?.current &&selected?.length === displayRows?.length && selectAllCheckboxRef?.current?.indeterminate) {
        selectAllCheckboxRef.current.indeterminate = false
        selectAllCheckboxRef.current.checked = true
      }
    }
  }, [selectableActions, selected, displayRows,  selectAllCheckboxRef?.current?.indeterminate])

  if (!columns || !columns.length) {
    return null;
  }

  return (
    <StyledDataTable autofit={autofit}>
      { !disableSearch &&
        <>
          {selectable && `Rows selected: ${selected?.length}`}
          {filterColumns?.length > 0 && (
            <div className="filters">
              <FormControl>
                <TextControl
                  onChange={handleSearchTermChange}
                  placeholder="Search..."
                  value={searchTerm}
                />
              </FormControl>
            </div>
          )}
        </>
      }
      {
        selectableActions &&
        <StyledTableHeader>
          <div>
            <IconButton
              disabled={!canMoveUp}
              onClick={() => handleReorder('up')}
              tooltip="Move up"
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              disabled={!canMoveDown}
              onClick={() => handleReorder('down')}
              tooltip="Move down"
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              disabled={!selected.length}
              onClick={() => handleRemoveClick()}
              tooltip="Remove"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </div>
        </StyledTableHeader>
      }
      <div className={`${stickyHeader && !fullHeight ? 'table-scroll' : ''}`}>
        <Table stickyHeader={stickyHeader}>
          <thead>
            <tr>
              {selectable && (
                <th style={{ textAlign: 'left'}}>
                  <Checkbox
                    ref={selectAllCheckboxRef}
                    checked={
                      displayRows.length > 0 &&
                      selected &&
                      selected.length === displayRows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </th>
              )}
              {columns.map(renderHeaderCell)}
              {typeof renderActionColumn === 'function' && (
                <th align="right">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
    </StyledDataTable>
  );
};

DataTable.propTypes = {
  autofit: PropTypes.bool,
  columns: PropTypes.array,
  defaultSortField: PropTypes.string,
  filterColumns: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  noRowsMessage: PropTypes.string,
  onSelectionChange: PropTypes.func,
  rows: PropTypes.array,
  selectable: PropTypes.bool,
  selectedRows: PropTypes.array,
  stickyHeader: PropTypes.bool,
  renderActionColumn: PropTypes.func,
};

export default DataTable;
