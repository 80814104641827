import React  from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { platformSelectors } from '@clatter/platform';
import { themes } from '@clatter/ui';

const AppThemeProvider = ({
  children
}) => {
  const toolInfo = useSelector(platformSelectors.toolInfo.selectToolInfo);

  return (
    <ThemeProvider theme={{
      ...themes.default,
      palette: {
        ...themes.default.palette,
        primaryColor: toolInfo?.data?.color?.primaryColor,
      },
    }}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
