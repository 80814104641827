import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { userCustomAttributeApi } from '../api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';

export const USER_CUSTOM_ATTRIBUTES_KEY = 'userCustomAttributes';
export const userCustomAttributeAdapter = createEntityAdapter({
  selectId: (row) => row.id,
});

export const fetchCustomAttributes = createAsyncThunk(
  `${USER_CUSTOM_ATTRIBUTES_KEY}/fetchUserCustomAttributes`,
  async () => {
    return await userCustomAttributeApi.getUserCustomAttributes();
  },
);

export const deleteCustomAttribute = createAsyncThunk(
  `${USER_CUSTOM_ATTRIBUTES_KEY}/deleteUserCustomAttribute`,
  async (attributeId) => {
    return await userCustomAttributeApi.deleteUserCustomAttribute({ attributeId });
  },
);

export const createCustomAttributes = createAsyncThunk(
  `${USER_CUSTOM_ATTRIBUTES_KEY}/createUserCustomAttributes`,
  async (newAttributeData) => {
    return await userCustomAttributeApi.createUserCustomAttribute({ newAttributeData });
  },
);

export const updateCustomAttributes = createAsyncThunk(
  `${USER_CUSTOM_ATTRIBUTES_KEY}/updateUserCustomAttributes`,
  async (payload) => {
    return await userCustomAttributeApi.updateUserCustomAttribute(payload);
  },
);

export const initialUserCustomAttributesState = userCustomAttributeAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  customAttributes: [],
  error: null,
});

export const userCustomAttributesSlice = createSlice({
  name: USER_CUSTOM_ATTRIBUTES_KEY,
  initialState: initialUserCustomAttributesState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomAttributes.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchCustomAttributes.fulfilled, (state, { payload }) => {
        state.userAttributes = payload.data;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchCustomAttributes.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(deleteCustomAttribute.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deleteCustomAttribute.fulfilled, (state, { payload, meta }) => {
        state.userAttributes = state.userAttributes.filter((attribute) => attribute.id !== meta.arg);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deleteCustomAttribute.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(createCustomAttributes.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(createCustomAttributes.fulfilled, (state, { payload }) => {
        state.userAttributes = [...state.userAttributes, payload.data];
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createCustomAttributes.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(updateCustomAttributes.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateCustomAttributes.fulfilled, (state, { payload }) => {
        const index = state.userAttributes.findIndex((attribute) => attribute.id === payload.data.id);
        const updatedAttributes = [...state.userAttributes];
        updatedAttributes.splice(index, 1, payload.data);

        state.userAttributes = updatedAttributes;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateCustomAttributes.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

export const getCustomUserAttributesState = (rootState) => rootState[PLATFORM_FEATURE_KEY][USER_CUSTOM_ATTRIBUTES_KEY];

export const userCustomAttributesSelectors = {
  loadingStatus: createSelector(getCustomUserAttributesState, ({ loadingStatus }) => loadingStatus),
  selectAllUserAttributes: createSelector(getCustomUserAttributesState, ({ userAttributes }) => userAttributes),
};
