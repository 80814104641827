import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Interaction,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getRelativePosition } from 'chart.js/helpers';
import styled from 'styled-components';
import 'chartjs-adapter-date-fns';
import { getLabelForDateValue } from "@clatter/platform";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const StyledChartContainer = styled.div`
  position: relative;
  width: 99%; // width 100% will break the chart out of container...
  height: 450px;
`;

Interaction.modes.myCustomMode = function (
  chart,
  e,
  options,
  useFinalPosition,
) {
  const position = getRelativePosition(e, chart);

  const items = [];
  Interaction.evaluateInteractionItems(
    chart,
    'x',
    position,
    (element, datasetIndex, index) => {
      if (element.inXRange(position.x, useFinalPosition)) {
        items.push({ element, datasetIndex, index });
      }
    },
  );
  return items;
};

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value) {
          return getLabelForDateValue(this.getLabelForValue(value));
        },
        align: 'start',
      },
    },
  },
  interaction: {
    mode: 'myCustomMode',
  },
  parsing: {
    xAxisKey: 'id',
    yAxisKey: 'nested.value',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      backgroundColor: '#fff',
      bodyColor: '#000',
      borderColor: '#969696',
      titleColor: '#5e5e5e',
      titleFont: {
        size: 14,
      },
      titleMarginBottom: 10,
      borderWidth: 1,
      boxPadding: 5,
      cornerRadius: 1,
      padding: 20,
      yAlign: 'bottom',
      bodySpacing: 3,
      callbacks: {
        title: (tooltipItem) => {
          return getLabelForDateValue(tooltipItem[0]?.label, true);
        },
        label: (tooltipItem) => {
          const hasOneToolSelected = tooltipItem?.chart?.tooltip?.body?.length <= 1
          if(!tooltipItem.dataset.label) {
            return `${tooltipItem.dataset.toolName}: ${tooltipItem.formattedValue}`;
          }
          if (!hasOneToolSelected && tooltipItem.dataset.toolName) {
            return ` ${tooltipItem.dataset.toolName} - ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
          }
          return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
        },
      },
    },
  },
};

const LineChart = ({ reportData }) => {
  return (
    <StyledChartContainer>
      <Line options={chartOptions} data={reportData} />
    </StyledChartContainer>
  );
};

export default LineChart;
