import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import contentCategoryApi from '../api/contentCategory';
import requestStatus from './requestStatus';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
export const CONTENT_CATEGORY_FEATURE_KEY = 'contentCategory';
export const contentCategoryAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const fetchContentCategory = createAsyncThunk(
  `${CONTENT_CATEGORY_FEATURE_KEY}/fetchStatus`,
  async ({ contentRepositoryId }, thunkAPI) =>
    contentCategoryApi.getContentCategories({
      contentRepositoryId,
    }),
);

export const initialContentCategoryState =
  contentCategoryAdapter.getInitialState({
    loadingStatus: requestStatus.initial,
    error: null,
  });
export const contentCategorySlice = createSlice({
  name: CONTENT_CATEGORY_FEATURE_KEY,
  initialState: initialContentCategoryState,
  reducers: {
    add: contentCategoryAdapter.addOne,
    remove: contentCategoryAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentCategory.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchContentCategory.fulfilled, (state, action) => {
        contentCategoryAdapter.setAll(state, action.payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentCategory.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
    ;
  },
});

export const contentCategoryReducer = contentCategorySlice.reducer;
export const contentCategoryActions = contentCategorySlice.actions;
const { selectAll, selectEntities } = contentCategoryAdapter.getSelectors();
export const getContentCategoryState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][CONTENT_CATEGORY_FEATURE_KEY];

export const contentCategoriesSelectors = {
  selectAllContentCategories: createSelector(
    getContentCategoryState,
    selectAll,
  ),
  selectContentCategoriesEntities: createSelector(
    getContentCategoryState,
    selectEntities,
  ),
};
