import React, { useState } from 'react';
import styled from 'styled-components';
import { EmailLoginScreen } from './EmailLoginScreen';
import { SSOLoginScreen } from './SSOLoginScreen';

const StyledScreensContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  height: 100%;

  .form-wrapper {
    flex: 1;
    min-height: 280px;
  }
`;



const StyledFooter = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;

  @media (max-width: 760px) {
    margin-top: 3rem;
  }
`;

const loginScreensTypes = {
  SSO: 'sso',
  EMAIL: 'email',
};

export const LoginScreens = ({ successfulLoginRedirectUrl, supportEmail, supportSubject = 'Clatter Contact' }) => {
  const isSSOEnabled = process.env.NX_SSO_ENABLED === 'true';
  const [activeScreen, setActiveScreen] = useState(() =>
    isSSOEnabled ? loginScreensTypes.SSO : loginScreensTypes.EMAIL,
  );

  const handleChangeScreen = (e) => {
    e.preventDefault();
    setActiveScreen((prev) => (prev === loginScreensTypes.EMAIL ? loginScreensTypes.SSO : loginScreensTypes.EMAIL));
  };

  return (
    <StyledScreensContainer>
      <div className="form-wrapper">
        {activeScreen === loginScreensTypes.SSO ? (
          <SSOLoginScreen successfulLoginRedirectUrl={successfulLoginRedirectUrl} supportEmail={supportEmail} />
        ) : (
          <EmailLoginScreen successfulLoginRedirectUrl={successfulLoginRedirectUrl} />
        )}
      </div>

      <StyledFooter>
        <a href={`mailto:${supportEmail}?subject=${supportSubject}`}>Contact Us</a>

        {isSSOEnabled && (
          <>
            <span>|</span>
            <a href="#" onClick={(e) => handleChangeScreen(e)}>
              {activeScreen === loginScreensTypes.EMAIL ? 'Return to SSO login' : 'Login with email/password'}
            </a>
          </>
        )}
      </StyledFooter>
    </StyledScreensContainer>
  );
};
