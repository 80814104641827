import { getAxiosApiV2WithAuthorization } from '../services';

const ssoApi = {
  callback: (data) =>
    getAxiosApiV2WithAuthorization({
      url: '/sso/callback',
      method: 'post',
      data: data,
    }),
};

export default ssoApi;
