import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCard = styled.div.attrs((props) => ({
  width: props.width || '100%',
}))`
  width: ${({ width }) => width};
  border-radius: ${({ theme: { defaultBorderRadius } }) => defaultBorderRadius};
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
`;

const Card = forwardRef(({ children, ...props }, ref) => (
  <StyledCard ref={ref} {...props}>
    {children}
  </StyledCard>
));

export default Card;
