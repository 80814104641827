import { generatePath } from 'react-router-dom';
import { format, parseISO } from "date-fns";
import { ShowChart as ShowChartIcon } from '@mui/icons-material';
import { hasActiveFeatureFlag } from '@clatter/platform';
import { forOwn } from 'lodash';

export const reportsRequestTypes = {
  microsites: "microsites",
  pages: "pages",
}

export const generateReportsUrls = ({ reports, featureFlags }) => {
  if ((!reports && !Array.isArray(reports)) || (!featureFlags && !Array.isArray(featureFlags))) {
    return [];
  }

  return reports.reduce((acc, item) => {
    if (
      !!item.featureFlag &&
      hasActiveFeatureFlag({
        flagName: item.featureFlag,
        featureFlags: featureFlags,
      })
    ) {
      acc.push({
        ...item,
        icon: ShowChartIcon,
        url: generatePath(item.url),
      });
    }
    return acc;
  }, []);
};

export const isReportsGroupByOptionEnabled = (selectedFiltersValues, filterByInputs, name) => {
  const excludedValues = [];

  forOwn(selectedFiltersValues, (keyValue, filterByKey) => {
    const isValueSelected = keyValue?.value !== 'none';
    const hasSingleOption = (
      filterByInputs.find(filterByInput => filterByInput.key === filterByKey)?.options || []
    ).filter(option => option.value !== 'none').length <= 1;
    const groupByKey = (() => {
      if (filterByKey === 'template') {
        return 'template_id';
      }

      return filterByKey;
    })();

    // hide group by "tool" when a single { tool, download source } is selected
    if (filterByKey === 'tool' || filterByKey === 'download_source') {
      if (keyValue?.filter((item) => item?.value !== 'all').length === 1) {
        excludedValues.push(groupByKey);
      }

      return;
    }

    if (isValueSelected || hasSingleOption) {
      excludedValues.push(groupByKey);
    }
  });

  return !excludedValues?.includes(name);
};

export const generateGroupByOptionsWithAdditionalProps = ({
  options = [],
  filterByInputs = [],
  selectedFilterBy = {}
}) => options?.map((optionItem) => ({
  ...optionItem,
  isEnabled: isReportsGroupByOptionEnabled(selectedFilterBy, filterByInputs, optionItem?.value),
}));

export const generateFilterByTemplatesOptions = (templates, activeTool) => {
  if (!activeTool) {
    return;
  }
  return templates
    ?.filter((template) => template?.active && template?.toolId?.toLowerCase() === activeTool?.toLowerCase())
    .map((toolTemplate) => ({
      label: toolTemplate?.name,
      value: toolTemplate?._id,
    }));
};

export const generateFilterByContentRepositoriesOptions = (contentRepositories) => {
  return contentRepositories.map((contentRepository) => ({
    label: contentRepository?.name,
    value: contentRepository?._id,
  }));
};

export const getUserCustomAttributeData = (customAttributes) => {
  const groupByOptions = [];
  const filtersInputs = [];

  (customAttributes || []).forEach((item) => {
    if (item?.attribute_name) {
      groupByOptions.push({
        label: item?.display_name || 'N/A',
        value: item?.attribute_name,
      });

      filtersInputs.push({
        key: item?.attribute_name,
        type: 'select',
        label: `${item?.display_name}: `,
        maxWidth: '200px',
        isMultiSelect: false,
        options: [
          {
            label: 'Any',
            value: 'none',
          },
          ...item?.attribute_values?.map((itemOption) => ({
            label: itemOption?.value,
            value: itemOption?.value, // FIXME: ideally it should be id of the item but currently api is based on the item.value...
          })),
        ],
      });
    }
  });

  return {
    groupByOptions,
    filtersInputs,
  };
};

export const getFilterByToolOptions = ({ toolsNames, includeHome = true }) => {
  const toolsNamesMap = (() => {
    const clonedToolsNames = { ...toolsNames };

    // add home tool
    if (includeHome) {
      clonedToolsNames.home = 'Home';
    }

    if (clonedToolsNames.pga) {
      clonedToolsNames.pma = clonedToolsNames.pga;

      delete clonedToolsNames.pga;
    }

    const sortedToolsNames = {};

    for (let key of Object.keys(clonedToolsNames).sort()) {
      sortedToolsNames[key] = clonedToolsNames[key];
    }

    return sortedToolsNames;
  })();
  const filterByToolOptions = Object.entries(toolsNamesMap).map(([toolSlug, toolName]) => ({
    value: toolSlug,
    label: toolName,
  }));

  return {
    toolsNamesMap,
    filterByToolOptions,
  };
};

export const getChartColorsForToolSlug = (toolSlug) => {
  switch (toolSlug) {
    case 'home':
      return {
        mauColor: 'rgb(32,86,124)',
        dauColor: 'rgb(115,187,239)',
      };
    case 'admin':
      return {
        mauColor: 'rgb(101,32,124)',
        dauColor: 'rgb(185,54,215)',
      };
    case 'msm':
      return {
        mauColor: 'rgb(124,32,43)',
        dauColor: 'rgb(167, 115, 121)',
      };
    case 'pm':
    case 'pg':
      return {
        mauColor: 'rgb(52,124,32)',
        dauColor: 'rgb(54,215,75)',
      };
    case 'sl':
      return {
        mauColor: 'rgb(199,139,5)',
        dauColor: 'rgb(243,196,109)',
      };
    case 'active_users_by_period':
      return {
        mauColor: 'rgb(44,56,197)',
        dauColor: 'rgb(165,172,250)',
      };
    case 'sm':
      return {
        mauColor: 'rgb(199, 5, 70)',
        dauColor: 'rgb(199, 73, 115)',
      };
    case 'oe':
      return {
        mauColor: 'rgb(100, 125, 15)',
        dauColor: 'rgb(143, 163, 78)',
      };
    case 'qc':
      return {
        mauColor: 'rgb(108, 19, 118)',
        dauColor: 'rgb(145, 78, 153)',
      };
    case 'cm':
      return {
        mauColor: 'rgb(21, 94, 99)',
        dauColor: 'rgb(57, 154, 161)',
      };
    case 'oekit':
      return {
        mauColor: 'rgb(131, 23, 27)',
        dauColor: 'rgb(199, 59, 64)',
      };
    default:
    function generateRandomColor() {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);

      return `rgb(${red}, ${green}, ${blue})`;
    }

      return {
        mauColor: generateRandomColor(),
        dauColor: generateRandomColor(),
      };
  }
};

export const getLabelForDateValue = (value, isTooltipLabel = false) => {
  const strippedValue = value.split('_')[0];
  const granularity = value.split('_')[1];
  // get week name
  if (granularity === 'weekly') {
    return isTooltipLabel
      ? `Week ${strippedValue.split('-')[1]} / ${strippedValue.split('-')[0]}`
      : `Week ${strippedValue.split('-')[1]}`
  }
  // get month name
  if (granularity === 'monthly') {
    const parsedDate = parseISO(`${strippedValue}-01`);
    return isTooltipLabel
      ? format(parsedDate, 'MMMM yyyy')
      : format(parsedDate, 'MMM');
  }
  // get day name
  return isTooltipLabel
    ? format(parseISO(strippedValue), 'dd MMMM yyyy')
    : format(parseISO(strippedValue), 'dd MMM');
};
