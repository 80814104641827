import React, { useState } from 'react';
import styled from 'styled-components';
import { featureFlagsMap, useHasActiveFeatureFlag } from '@clatter/platform';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    margin-bottom: 7px;
  }

  span {
    margin-left: 20px;
  }
`;

const defaultChoices = [
  { label: 'PowerPoint (.PPTX)', value: 'ppt' },
  { label: 'PDF', value: 'pdf' },
  { label: 'Both', value: 'zip' },
];

const BuildChoiceView = ({
  defaultChoice = defaultChoices?.[0].value,
  onChange,
  choices = defaultChoices,
}) => {
  const [choice, setChoice] = useState(defaultChoice);

  const handleChange = ({ currentTarget }) => {
    setChoice(currentTarget.value);
    onChange(currentTarget.value);
  };

  const allowBuildAllPresentationTypes = useHasActiveFeatureFlag(
    featureFlagsMap.common.allowBuildAllPresentationTypes,
  );

  return allowBuildAllPresentationTypes && (
    <StyledContainer>
      {choices.map(({ value, label }) => (
        <label key={value}>
          <input
            checked={choice === value}
            onChange={handleChange}
            data-testid={`${value}-checkbox`}
            type="radio"
            value={value}
          />
          <span>{label}</span>
        </label>
      ))}
    </StyledContainer>
  );
};

export default BuildChoiceView;
