import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';

export const IMPERSONATE_FEATURE_KEY = 'impersonate';

export const initialImpersonateState = {
  user: null,
};

export const impersonateSlice = createSlice({
  name: IMPERSONATE_FEATURE_KEY,
  initialState: initialImpersonateState,
  reducers: {
    set: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const getImpersonateState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][IMPERSONATE_FEATURE_KEY];

export const impersonateSelectors = {
  selectImpersonatedUser: createSelector(
    getImpersonateState,
    (state) => state?.user,
  ),
};
