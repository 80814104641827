import React from 'react';
import {
  StyledProfileOptions,
  StyledProfileHeader,
  StyledProfileListLinkItem,
  StyledProfileListText,
  StyledProfileListIcon,
  StyledProfileVersionListItem,
  StyledProfileWrapper,
  StyledProfileListTextItem,
} from './components/StyledProfileOptions';
import {
  StyledUserIconCircle,
  StyledUserIconInitial,
} from './components/StyledUserIconCircle';

const DropDownOptions = ({ listItems = [] }) => {
  if (listItems.length === 0) {
    return null;
  }

  return listItems.map((listItem) => {
    const { clickEvent, name, link, icon } = listItem;

    if (clickEvent) {
      return (
        <StyledProfileListTextItem title={name} onClick={clickEvent} key={name}>
          <StyledProfileListIcon>{icon}</StyledProfileListIcon>
          <StyledProfileListText>{name}</StyledProfileListText>
        </StyledProfileListTextItem>
      );
    }

    return (
      <StyledProfileListLinkItem title={name} to={link} key={name}>
        <StyledProfileListIcon>{icon}</StyledProfileListIcon>
        <StyledProfileListText>{name}</StyledProfileListText>
      </StyledProfileListLinkItem>
    );
  });
};

const UserProfileIcon = ({
  testId = 'user-circle',
  handleUserIconClick,
  isOpen = false,
  user,
  dropdownOptions = [],
}) => {
  const initialLetter = (user?.name || '').charAt(0);
  const appVersion = process.env.NX_APP_VERSION;

  return (
    <StyledUserIconCircle data-test-id={testId}>
      <StyledUserIconInitial onClick={handleUserIconClick}>
        {initialLetter}
      </StyledUserIconInitial>
      <StyledProfileWrapper isActive={isOpen} onClick={handleUserIconClick} />
      <StyledProfileOptions isActive={isOpen}>
        <StyledProfileHeader>{user?.name}</StyledProfileHeader>
        <DropDownOptions listItems={dropdownOptions} />
        {appVersion && (
          <StyledProfileVersionListItem>
            <StyledProfileListText>{appVersion}</StyledProfileListText>
          </StyledProfileVersionListItem>
        )}
      </StyledProfileOptions>
    </StyledUserIconCircle>
  );
};

export default UserProfileIcon;
