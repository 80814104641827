import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appUiSlice, APP_UI_FEATURE_KEY, appUiActions } from './appUi.slice';
import {
  eventsSlice,
  EVENTS_FEATURE_KEY,
  eventsSelectors,
} from './events.slice';
import {
  toolsSlice,
  TOOLS_FEATURE_KEY,
  toolConfigSelectors,
} from './toolConfig.slice';
import {
  contentRepositoriesSlice,
  CONTENT_REPOSITORIES_FEATURE_KEY,
  repositoriesConfigSelectors,
} from './contentRepositories.slice';
import {
  contentFiltersSlice,
  CONTENT_FILTERS_FEATURE_KEY,
  contentFiltersConfigSelectors,
} from './contentFilters.slice';
import {
  contentCategorySlice,
  CONTENT_CATEGORY_FEATURE_KEY,
  contentCategoriesSelectors,
} from './contentCategory.slice';
import {
  featureFlagSlice,
  FEATUREFLAG_FEATURE_KEY,
  featureFlagSelectors,
} from './featureFlag.slice';
import {
  impersonateSlice,
  IMPERSONATE_FEATURE_KEY,
  impersonateSelectors,
} from './impersonate.slice';
import {
  toolInfoSlice,
  TOOLINFO_FEATURE_KEY,
  toolInfoSelectors,
} from './toolInfo.slice';
import {
  CONTENT_SLIDES_FEATURE_KEY,
  contentSlidesActions,
  contentSlidesConfigSelectors,
  contentSlidesSlice,
} from './contentSlides.slice';
import {
  contentThemesSlice,
  CONTENT_THEMES_FEATURE_KEY,
  contentThemesConfigSelectors,
} from './contentThemes.slice';
import {
  noticesSlice,
  noticesSelectors,
  NOTICES_FEATURE_KEY,
} from './notices.slice';
import {
  persistedSlice,
  PERSISTED_FEATURE_KEY,
  persistedSelectors,
} from './persisted.slice';
import {
  PRESENTATION_FEATURE_KEY,
  presentationConfigSelectors,
  presentationSlice,
} from './presentation.slice';

import {
  systemProfileSlice,
  SYSTEM_PROFILE_FEATURE_KEY,
  systemProfileSelectors,
} from './systemProfile.slice';
import { rolesSelectors, ROLES_FEATURE_KEY, rolesSlice } from './roles.slice';
import {
  userCustomAttributesSelectors,
  USER_CUSTOM_ATTRIBUTES_KEY,
  userCustomAttributesSlice,
} from './userCustomAttributes.slice';

import {
  reportsSelectors,
  REPORT_FEATURE_KEY,
  reportSlice,
} from './report.slice';

import {
  STARTER_DECKS_FEATURE_KEY,
  starterDeckSlice,
  starterDecksSelectors,
} from './starterDeck.slice';

import {
  GLOBAL_ERROR_FEATURE_KEY,
  globalErrorSelectors,
  globalErrorSlice,
} from './globalError.slice';
import {
  CONTENT_SLIDE_DIALOG_FEATURE_KEY,
  contentSlideDialogSlice
} from "./contentSlideDialog.slice";
import {
  ROUTES_STATE_FEATURE_KEY,
  routesStateSelectors,
  routesStateSlice
} from "./routesState.slice";

export const PLATFORM_FEATURE_KEY = 'platform';

export { trackEvent, fetchEvents, eventRequestTypes } from './events.slice';
export { fetchContentCategory } from './contentCategory.slice';
export {
  fetchContentSlides,
  logDownloadContentSlide,
  setSelectedContentSlide,
  setSelectedContentSlidesIds,
  clearSelectedContentSlide,
  resetContentSlidesState,
} from './contentSlides.slice';
export {
  fetchUserContentRepositories,
  fetchContentRepository,
  fetchContentRepositories,
  fetchContentRepositoriesV2,
  fetchContentRepositoriesCategories,
  setSelectedContentRepositoryId,
} from './contentRepositories.slice';
export {
  fetchContentFiltersForRepository,
  setSelectedContentFiltersIds,
} from './contentFilters.slice';
export {
  fetchUserPresentation,
  updatePresentation,
  createPresentation,
  setPresentationBuildStatus,
  fetchUserPresentations,
  deletePresentation,
} from './presentation.slice';
export {
  fetchContentThemesForContentRepository,
  setSelectedContentThemeId,
} from './contentThemes.slice';
export { default as requestStatus } from './requestStatus';
export { default as reportTypes } from './reportTypes';
export { fetchToolInfo } from './toolInfo.slice';
export { fetchToolsConfig, addToolTemplate } from './toolConfig.slice';
export { fetchSystemProfile } from './systemProfile.slice';
export {
  deleteCustomAttribute,
  createCustomAttributes,
  updateCustomAttributes,
  fetchCustomAttributes,
} from './userCustomAttributes.slice';
export { fetchFeatureFlag, updateFeatureFlag } from './featureFlag.slice';
export { fetchRoles } from './roles.slice';
export { fetchReport } from './report.slice';
export {
  addNoticeByTypeAndMessage,
  setNoticeContextKey,
} from './notices.slice';
export {
  setSelectedDeck,
  fetchStarterDecks,
  createStarterDeck,
  updateStarterDeck,
  clearStarterDecksState,
} from './starterDeck.slice';
export {
  setContentSlideDialogPreviewMode,
  setContentSlideDialogModalOpened,
} from './contentSlideDialog.slice'
export { ROUTES_STATE_FEATURE_KEY } from './routesState.slice'
export { IMPERSONATE_FEATURE_KEY } from './impersonate.slice'
export { PERSISTED_FEATURE_KEY } from './persisted.slice'


export { globalErrorHandlerMiddleware } from './globalError.slice';

export const platformReducers = combineReducers({
  [APP_UI_FEATURE_KEY]: appUiSlice.reducer,
  [EVENTS_FEATURE_KEY]: eventsSlice.reducer,
  [CONTENT_CATEGORY_FEATURE_KEY]: contentCategorySlice.reducer,
  [CONTENT_FILTERS_FEATURE_KEY]: contentFiltersSlice.reducer,
  [CONTENT_REPOSITORIES_FEATURE_KEY]: contentRepositoriesSlice.reducer,
  [CONTENT_SLIDES_FEATURE_KEY]: contentSlidesSlice.reducer,
  [CONTENT_THEMES_FEATURE_KEY]: contentThemesSlice.reducer,
  [CONTENT_SLIDE_DIALOG_FEATURE_KEY]: contentSlideDialogSlice.reducer,
  [SYSTEM_PROFILE_FEATURE_KEY]: systemProfileSlice.reducer,
  [FEATUREFLAG_FEATURE_KEY]: featureFlagSlice.reducer,
  [IMPERSONATE_FEATURE_KEY]: persistReducer(
    { key: IMPERSONATE_FEATURE_KEY, storage: storage },
    impersonateSlice.reducer,
  ),
  [TOOLINFO_FEATURE_KEY]: toolInfoSlice.reducer,
  [NOTICES_FEATURE_KEY]: noticesSlice.reducer,
  [TOOLS_FEATURE_KEY]: toolsSlice.reducer,
  [PERSISTED_FEATURE_KEY]: persistReducer(
    { key: PERSISTED_FEATURE_KEY, storage: storage },
    persistedSlice.reducer,
  ),
  [ROUTES_STATE_FEATURE_KEY]: persistReducer(
    { key: ROUTES_STATE_FEATURE_KEY, storage: storage },
    routesStateSlice.reducer,
  ),
  [PRESENTATION_FEATURE_KEY]: presentationSlice.reducer,
  [ROLES_FEATURE_KEY]: rolesSlice.reducer,
  [REPORT_FEATURE_KEY]: reportSlice.reducer,
  [STARTER_DECKS_FEATURE_KEY]: starterDeckSlice.reducer,
  [USER_CUSTOM_ATTRIBUTES_KEY]: userCustomAttributesSlice.reducer,
  [GLOBAL_ERROR_FEATURE_KEY]: globalErrorSlice.reducer,

});

export const platformActions = {
  [APP_UI_FEATURE_KEY]: appUiActions,
  [EVENTS_FEATURE_KEY]: eventsSlice.actions,
  [FEATUREFLAG_FEATURE_KEY]: featureFlagSlice.actions,
  [TOOLINFO_FEATURE_KEY]: toolInfoSlice.actions,
  [TOOLS_FEATURE_KEY]: toolsSlice.actions,
  [CONTENT_CATEGORY_FEATURE_KEY]: contentCategorySlice.actions,
  [CONTENT_THEMES_FEATURE_KEY]: contentThemesSlice.actions,
  [CONTENT_FILTERS_FEATURE_KEY]: contentFiltersSlice.actions,
  [CONTENT_SLIDES_FEATURE_KEY]: contentSlidesActions,
  [CONTENT_REPOSITORIES_FEATURE_KEY]: contentRepositoriesSlice.actions,
  [CONTENT_SLIDE_DIALOG_FEATURE_KEY]: contentSlideDialogSlice.actions,
  [IMPERSONATE_FEATURE_KEY]: impersonateSlice.actions,
  [NOTICES_FEATURE_KEY]: noticesSlice.actions,
  [PERSISTED_FEATURE_KEY]: persistedSlice.actions,
  [PRESENTATION_FEATURE_KEY]: presentationSlice.actions,
  [SYSTEM_PROFILE_FEATURE_KEY]: systemProfileSlice.actions,
  [ROLES_FEATURE_KEY]: rolesSlice.actions,
  [REPORT_FEATURE_KEY]: reportSlice.actions,
  [ROUTES_STATE_FEATURE_KEY]: routesStateSlice.actions,
  [STARTER_DECKS_FEATURE_KEY]: starterDeckSlice.actions,
  [USER_CUSTOM_ATTRIBUTES_KEY]: userCustomAttributesSlice.actions,
  [GLOBAL_ERROR_FEATURE_KEY]: globalErrorSlice.actions,
};

export const platformSelectors = {
  [CONTENT_CATEGORY_FEATURE_KEY]: contentCategoriesSelectors,
  [EVENTS_FEATURE_KEY]: eventsSelectors,
  [CONTENT_THEMES_FEATURE_KEY]: contentThemesConfigSelectors,
  [CONTENT_FILTERS_FEATURE_KEY]: contentFiltersConfigSelectors,
  [CONTENT_REPOSITORIES_FEATURE_KEY]: repositoriesConfigSelectors,
  [SYSTEM_PROFILE_FEATURE_KEY]: systemProfileSelectors,
  [FEATUREFLAG_FEATURE_KEY]: featureFlagSelectors,
  [IMPERSONATE_FEATURE_KEY]: impersonateSelectors,
  [TOOLINFO_FEATURE_KEY]: toolInfoSelectors,
  [TOOLS_FEATURE_KEY]: toolConfigSelectors,
  [NOTICES_FEATURE_KEY]: noticesSelectors,
  [CONTENT_SLIDES_FEATURE_KEY]: contentSlidesConfigSelectors,
  [PERSISTED_FEATURE_KEY]: persistedSelectors,
  [PRESENTATION_FEATURE_KEY]: presentationConfigSelectors,
  [REPORT_FEATURE_KEY]: reportsSelectors,
  [ROLES_FEATURE_KEY]: rolesSelectors,
  [ROUTES_STATE_FEATURE_KEY]: routesStateSelectors,
  [STARTER_DECKS_FEATURE_KEY]: starterDecksSelectors,
  [USER_CUSTOM_ATTRIBUTES_KEY]: userCustomAttributesSelectors,
  [GLOBAL_ERROR_FEATURE_KEY]: globalErrorSelectors,
};
