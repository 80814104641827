import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const systemSettings = {
  updateSystemProfile: (params) =>
    getAxiosApiV2WithAuthorization({
      url: `/system-profile`,
      method: 'patch',
      data: params,
    }),
  getSystemProfile: () =>
    getAxiosApiV2WithAuthorization({
      url: `/system-profile`,
      method: 'get',
    }),
};

export default systemSettings;
