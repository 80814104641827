import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNotices,
  useInterval,
  downloadFromUrl,
  useGetActiveTool,
  slideTypes,
  downloadTypes,
  contentSlidesApi,
  deckBuildStatuses,
  logDownloadContentSlide,
} from '@clatter/platform';

/// legacy api is returning error when trying to log
// download event for slide other than "contentSlide"
// Valid types in the legacy api for download event are:
// ['contentSlideCategory', 'contentSlide', 'downloadPresentation']
// there's no direct support for blank/dividers/covers
const mapDownloadTypeToApi = (downloadType) => {
  switch (downloadType) {
    case downloadTypes.BLANK: // will not log properly
      return 'blank';
    case downloadTypes.COVER: // will not log properly
      return 'cover';
    case downloadTypes.DIVIDER: // will not log properly
      return 'divider';
    case downloadTypes.PRIMARY_COVER: // will not log properly
      return 'primaryCover';
    case downloadTypes.CONTENT:
      return 'contentSlide';
    case downloadTypes.DECK:
      return 'contentSlideCategory';
    case downloadTypes.PRESENTATION:
      return 'downloadPresentation';
    default:
      throw new Error('MAP SLIDE TYPE: Unknown slide type.');
  }
};

const getDownloadUrlBySlideType = (slideData, themeId) => {
  switch (slideData?.slideType) {
    case slideTypes.CONTENT:
      return slideData.slideListByThemes.find((item) => item.themeId === themeId)?.fileLocation?.url || null;
    case slideTypes.BLANK:
    case slideTypes.DIVIDER:
    case slideTypes.COVER:
    case slideTypes.PRIMARY_COVER:
      return slideData?.fileLocation?.url;
    case slideTypes.DECK:
      return slideData.url;
    default:
      throw new Error('Unknown slide type');
  }
};

const usePresentationGeneratorDownload = ({ themeId } = {}) => {
  const dispatch = useDispatch();
  const { addNotice } = useNotices();
  const activeTool = useGetActiveTool()?.slug;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [intervalTimer, setIntervalTimer] = useState(null);
  const [buildId, setBuildId] = useState(null);
  const [fetchBuildStatusData, setFetchBuildStatusData] = useState({
    themeId: null,
    coverId: null,
    dividerId: null,
    blankId: null,
    contentRepositoryId: null,
    contentSlideCategory: null,
  });

  //region METHODS
  const download = useCallback(({ downloadUrl }) => downloadFromUrl(downloadUrl), []);

  const downloadSlide = useCallback(
    async (slideData) => {
      try {
        let downloadUrl = null;
        const slideType = slideData.slideType;

        downloadUrl = getDownloadUrlBySlideType(slideData, themeId);

        if (!downloadUrl) {
          throw new Error('No slide data found with given theme id.');
        }

        if (slideType === slideTypes.CONTENT || slideTypes.DECK) {
          await dispatch(
            logDownloadContentSlide({
              id: slideData._id,
              type: mapDownloadTypeToApi(slideData.slideType),
              themeId: themeId,
              tool: activeTool?.toUpperCase(),
            }),
          );
        }

        download({ downloadUrl: downloadUrl });
      } catch (error) {
        return addNotice({
          type: 'error',
          title: 'Unable to download slide',
          message: error || 'Error downloading slide.',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, activeTool, themeId],
  );

  const downloadDeck = useCallback(
    async ({ deckId, contentRepositoryId, coverId, dividerId, blankId } = {}) => {
      try {
        setIsLoading(true);
        const { data: responseData } = await contentSlidesApi.downloadContentSlidesDeck({
          contentRepositoryId: contentRepositoryId,
          contentSlideCategoryId: deckId,
          data: {
            themeId: themeId,
            coverId: coverId,
            dividerId: dividerId,
            blankId: blankId,
          },
        });

        if (!responseData.ingestId) {
          await downloadSlide({
            _id: deckId, // must be a deck ID instead of slide
            url: responseData?.url,
            slideType: slideTypes.DECK,
          });
          return;
        }

        // if deck is not build start pulling build status
        // NOTE: fetchBuildStatus will trigger 'downloadSlide'
        // when finished so it wil provide its
        // own 'slideData' to the method...
        setFetchBuildStatusData({
          coverId: coverId,
          dividerId: dividerId,
          blankId: blankId,
          themeId: themeId,
          contentRepositoryId: contentRepositoryId,
          contentSlideCategory: deckId,
        });
        setBuildId(responseData.ingestId);
        setIntervalTimer(3000);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(error.response?.data?.error?.message || 'Sorry, there was an error while downloading a deck');
      }
    },
    [themeId],
  );

  const downloadPresentation = useCallback(
    async (presentation) => {
      if (presentation.status !== deckBuildStatuses.completed) {
        return addNotice({
          type: 'error',
          title: 'Unable to download presentation',
          message: 'Presentation not built.',
        });
      }

      let downloadUrl =
        presentation?.pdfLocation?.url || presentation?.pptLocation?.url || presentation?.zipLocation?.url;

      if (!downloadUrl) {
        return addNotice({
          type: 'error',
          title: 'Unable to download presentation',
          message: 'Download URL not found.',
        });
      }

      await dispatch(
        logDownloadContentSlide({
          id: presentation._id,
          type: mapDownloadTypeToApi(downloadTypes.PRESENTATION),
          themeId: presentation.theme,
          tool: activeTool?.toUpperCase(),
        }),
      );

      download({ downloadUrl: downloadUrl });
    },
    [activeTool],
  );

  const fetchBuildStatus = async () => {
    try {
      const { data: buildData, error: buildError } = await contentSlidesApi.getIngestStatus({
        ingestId: buildId,
      });

      if (buildData.status === deckBuildStatuses.completed) {
        const data = {
          themeId: fetchBuildStatusData?.themeId,
          coverId: fetchBuildStatusData?.coverId,
          dividerId: fetchBuildStatusData?.dividerId,
          blankId: fetchBuildStatusData?.blankId,
        };

        const { data: responseData } = await contentSlidesApi.downloadContentSlidesDeck({
          contentRepositoryId: fetchBuildStatusData?.contentRepositoryId,
          contentSlideCategoryId: fetchBuildStatusData?.contentSlideCategory,
          data,
        });

        await downloadSlide({
          url: responseData.url,
          slideType: slideTypes.DECK,
          _id: fetchBuildStatusData?.contentSlideCategory,
        });

        setIntervalTimer(null);
      }

      if (buildError) {
        setIsLoading(false);
        setIntervalTimer(null);
        setErrorMessage(buildError.message || 'Unable to build deck');
      }
    } catch (error) {
      setIsLoading(false);
      setIntervalTimer(null);
      setErrorMessage(error.response?.data?.error?.message || 'Unable to build deck');
    }
  };
  //endregion

  //region EFFECTS
  useInterval(async () => {
    await fetchBuildStatus();
  }, intervalTimer);
  //endregion

  return {
    download: download,
    downloadDeck: downloadDeck,
    downloadSlide: downloadSlide,
    downloadPresentation: downloadPresentation,
    errorMessage: errorMessage,
    setErrorMessage: setErrorMessage,
    isLoading: isLoading,
  };
};

export default usePresentationGeneratorDownload;
