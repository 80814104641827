import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { groupBy, orderBy, head } from 'lodash';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
import requestStatus from './requestStatus';
import eventsApi from '../api/events.api';
export const EVENTS_FEATURE_KEY = 'events';
export const eventsAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const initialNoticesState = eventsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  loginEvents: {},
});

export const eventRequestTypes = {
  userLogin: 'USER_LOGGED_IN',
  tokenRefreshed: 'TOKEN_REFRESHED',
  presentationCreate: 'PRESENTATION_CREATE',
  presentationDownload: 'PRESENTATION_DOWNLOAD',
  slideDownload: 'SLIDE_DOWNLOAD',
  deckDownload: 'DECK_DOWNLOAD',
  documentDownload: 'DOCUMENT_DOWNLOAD',
  documentCreate: 'DOCUMENT_CREATE',
  documentShare: 'DOCUMENT_SHARE',
  pagevisit: 'PAGEVISIT',
  unauthorizedUserAccessAttempt: 'UNAUTHORIZED_USER_ACCESS_ATTEMPT',
  failedLogin: 'FAILED_LOGIN',
  micrositeCreated: 'MICROSITE_CREATED',
  micrositePageCreated: 'MICROSITE_PAGE_CREATED',
};

export const trackEvent = createAsyncThunk(
  `${EVENTS_FEATURE_KEY}/eventTrack`,
  async (data) => {
    if ([eventRequestTypes.failedLogin, eventRequestTypes.unauthorizedUserAccessAttempt].includes(data.type)) {
      return eventsApi.trackPublic(data);
    }

    return eventsApi.track(data);
  },
);

export const fetchEvents = createAsyncThunk(
  `${EVENTS_FEATURE_KEY}/fetchEvents`,
  async (params) => eventsApi.getEvents(params),
);

export const eventsSlice = createSlice({
  name: EVENTS_FEATURE_KEY,
  initialState: initialNoticesState,
  reducers: {
    setLoginEvents: (state, data) => {
      // rows grouped by userId
      const loginsByUserId = groupBy(data, (row) => row.data.user_id);

      state.loginEvents = Object.keys(loginsByUserId).reduce((acc, userId) => {
        const foundLastLoginEvent = head(orderBy(loginsByUserId[userId], 'timestamp', 'desc'));

        return {
          ...acc,
          [userId]: {
            user_id: userId,
            timestamp: foundLastLoginEvent ? foundLastLoginEvent.timestamp : null,
            count: loginsByUserId[userId].length,
          },
        };
      }, {});
    },
    setTokenRefreshedEvents: (state, data) => {
      // rows grouped by userId
      const tokenRefreshedEventsByUserId = groupBy(data, (row) => row.data.user_id);

      state.tokenRefreshedEvents = Object.keys(tokenRefreshedEventsByUserId).reduce(
        (acc, userId) => {
          const foundTokenRefreshedEvent = head(orderBy(tokenRefreshedEventsByUserId[userId], 'timestamp', 'desc'));

          return ({
            ...acc,
            [userId]: {
              user_id: userId,
              last_login: foundTokenRefreshedEvent ? foundTokenRefreshedEvent.timestamp : null,
            },
          });
        },
        {},
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchEvents.fulfilled, (state, { payload }) => {
        const { data } = payload;
        eventsAdapter.setAll(state, data);

        // clear state and fill all data later
        state.loadingStatus = requestStatus.fulfilled;
        state.loginEvents = initialNoticesState.loginEvents;

        const groupedEvents = groupBy(data, 'type');

        Object.entries(groupedEvents).forEach(([eventName, eventData]) => {
          switch (eventName) {
            case eventRequestTypes.userLogin:
              return eventsSlice.caseReducers.setLoginEvents(state, eventData);
            case eventRequestTypes.tokenRefreshed:
              return eventsSlice.caseReducers.setTokenRefreshedEvents(state, eventData);
            default:
              return console.warn('Unhandled event type "%s"', eventName);
          }
        });
      })
      .addCase(fetchEvents.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

const { selectAll } = eventsAdapter.getSelectors();
export const getEventsState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][EVENTS_FEATURE_KEY];

export const eventsSelectors = {
  loadingStatus: createSelector(
    getEventsState,
    ({ loadingStatus }) => loadingStatus,
  ),
  loginEvents: createSelector(getEventsState, ({ loginEvents }) => loginEvents),
  tokenRefreshedEvents: createSelector(getEventsState, ({ tokenRefreshedEvents }) => tokenRefreshedEvents),
  selectAllEvents: createSelector(getEventsState, selectAll),
};
