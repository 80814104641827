import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckboxLegacy from './Checkbox';
import ThumbnailTooltip from '../../ThumbnailTooltip';
import { chunk } from 'lodash';
import SlideStatusInfo from './SlideStatusInfo';
import { getSlideGroupClassName } from '@clatter/platform';
import classNames from 'classnames';

const Slide = ({
  slide,
  selectedSlides,
  selectSlide,
  deselectSlide,
  selectedContentThemeId,
  onSlideLabelClick,
  slidesGrops,
  index,
}) => {
  const { title, _id: slideId, isRequired } = slide;

  if (slide.hidden) {
    return null;
  }

  const isInGroup = useMemo(() => {
    return !!slidesGrops[slide?.group?._id];
  }, [slideId, Object.keys(slidesGrops)?.length]);

  const slideClassName = useMemo(() => {
    if (isInGroup) {
      return getSlideGroupClassName({
        slideGroup: slidesGrops[slide.group._id],
        slideId: slideId,
      });
    }
    return '';
  }, [slideId, isInGroup, Object.keys(slidesGrops)?.length]);

  const idx = selectedSlides.indexOf(slideId);
  const selected = idx > -1 || isRequired;

  const thumbnailUrl = (() => {
    if (slide?.slideListByThemes?.length > 0) {
      const thumbnailByTheme = slide.slideListByThemes.find(
        (item) => item.themeId === selectedContentThemeId,
      );
      return thumbnailByTheme?.thumbnailLocation.url ?? '';
    }
    return '';
  })();

  const handleLabelClick = () => {
    onSlideLabelClick(slide);
  };

  return (
    <StyledSlide
      key={slideId}
      className={classNames(slideClassName, {
        'column-first-slide': index === 0,
      })}
    >
      <div className="slide-content">
        <ThumbnailTooltip url={thumbnailUrl} alt={slide.title}>
          <CheckboxLegacy
            label={`${title}`}
            value={selected}
            onLabelClick={handleLabelClick}
            onChange={() =>
              selected
                ? deselectSlide(
                    isInGroup ? [...slidesGrops[slide?.group?._id]] : [slideId],
                  )
                : selectSlide(
                    isInGroup ? [...slidesGrops[slide?.group?._id]] : [slideId],
                  )
            }
            enableLabelClick
            disabled={isRequired}
            testId="hierarchy-slide-checkbox"
          />
        </ThumbnailTooltip>
        {(slide.isNew || slide.isUpdated) && (
          <SlideStatusInfo status={slide.isNew ? 'new' : 'updated'} />
        )}
      </div>
    </StyledSlide>
  );
};

const renderCategoryChildren = (allChildren, _renderChild) => {
  const { children, headers } = allChildren.reduce(
    (res, child) => {
      if (child.label !== 'slide') {
        res['headers'].push(child);
      } else if (!child.hidden) {
        res['children'].push(child);
      }
      return res;
    },
    {
      children: [],
      headers: [],
    },
  );
  const columnsNum = Math.ceil(children.length / 3);
  const columns = chunk(children, columnsNum);

  return (
    <>
      {children.length > 0 && (
        <CategoryChildrenContainer>
          <CategoryChildrenColumn>
            {columns[0].map((child, index) => _renderChild(child, index))}
          </CategoryChildrenColumn>
          {columns[1] && (
            <CategoryChildrenColumn>
              {columns[1].map((child, index) => _renderChild(child, index))}
            </CategoryChildrenColumn>
          )}
          {columns[2] && (
            <CategoryChildrenColumn>
              {columns[2].map((child, index) => _renderChild(child, index))}
            </CategoryChildrenColumn>
          )}
        </CategoryChildrenContainer>
      )}
      <CategoryChildren>
        {headers.map((child, index) => _renderChild(child, index))}
      </CategoryChildren>
    </>
  );
};

const renderHeaderChildren = (allChildren, _renderChild) => {
  const children = allChildren.reduce((res, child) => {
    if (child.label !== 'slide') {
      // check if "sub-header" has visible children
      // if not - remove it from hierarchy
      const canShow = child.children.some((item) => !item.hidden);
      if (canShow) res.push(child);
    } else if (!child.hidden) {
      res.push(child);
    }
    return res;
  }, []);

  const numChildren = children.length;
  const countCol1 = Math.ceil(numChildren / 3);
  const countCol2 = Math.ceil((numChildren - countCol1) / 2);
  const countCol3 = numChildren - countCol1 - countCol2;

  const column1 = children.slice(0, countCol1);
  const column2 =
    countCol2 > 0 ? children.slice(countCol1, countCol1 + countCol2) : [];
  const column3 = countCol3 > 0 ? children.slice(countCol1 + countCol2) : [];

  return (
    <HeaderChildrenContainer>
      <HeaderChildrenColumn>
        {column1.map((child, index) => _renderChild(child, index))}
      </HeaderChildrenColumn>
      <HeaderChildrenColumn>
        {column2.map((child, index) => _renderChild(child, index))}
      </HeaderChildrenColumn>
      {countCol3 > 0 && (
        <HeaderChildrenColumn>
          {column3.map((child, index) => _renderChild(child, index))}
        </HeaderChildrenColumn>
      )}
    </HeaderChildrenContainer>
  );
};

const renderSubheaderChildren = (allChildren, _renderChild, maxColumns = 1) => {
  const children = allChildren.filter((child) => !child.hidden);
  let numChildren = children.length;
  let countCol1;
  let countCol2;
  let countCol3;

  if (maxColumns == 3) {
    countCol1 = Math.ceil(numChildren / 3);
    countCol2 = Math.ceil((numChildren - countCol1) / 2);
    countCol3 = numChildren - countCol1 - countCol2;
  }

  if (maxColumns == 2) {
    countCol1 = Math.ceil(numChildren / 2);
    countCol2 = numChildren - countCol1;
    countCol3 = 0;
  }

  if (maxColumns == 1) {
    countCol1 = numChildren;
    countCol2 = 0;
    countCol3 = 0;
  }

  let column1 = children.slice(0, countCol1);
  let column2 =
    countCol2 > 0 ? children.slice(countCol1, countCol1 + countCol2) : [];
  let column3 = countCol3 > 0 ? children.slice(countCol1 + countCol2) : [];

  return (
    <SubheaderChildrenContainer>
      <SubheaderChildrenColumn>
        {column1.map((child, index) => _renderChild(child, index))}
      </SubheaderChildrenColumn>
      {countCol2 > 0 && (
        <SubheaderChildrenColumn>
          {column2.map((child, index) => _renderChild(child, index))}
        </SubheaderChildrenColumn>
      )}
      {countCol3 > 0 && (
        <SubheaderChildrenColumn>
          {column3.map((child, index) => _renderChild(child, index))}
        </SubheaderChildrenColumn>
      )}
    </SubheaderChildrenContainer>
  );
};

const visitChildSlides = (forest, vfn) => {
  // console.info('visitChildSlides: ', { forest });
  forest.forEach((root) => {
    console.info({ root });
    if (root.label === 'slide') {
      let id = root._id;
      console.info('vfn(', id, ')');
      vfn(id);
    }
  });
};

// visit all the nodes in the forest, calling vfn() on each node
// @todo reimplement visitChildSlides in terms of this fn
const visit = (forest, vfn) => {
  // console.info("visit: ", {forest})
  forest.forEach((node) => {
    // console.info({node});
    vfn(node);
    // if( root.label === "slide" ) {
    //     let id = root._id;
    //     console.info( 'vfn(', id,')')
    //     vfn(id);
    // }
    if ('children' in node) {
      visit(node.children, vfn);
    }
  });
};

const getCategories = (forest) => {
  const ids = [];
  const collect = (node) => {
    if (node.label === 'category') {
      ids.push(node._id);
    }
  };
  visit(forest, collect);
  return ids;
};

const getHeaders = (forest) => {
  const ids = [];
  const collect = (node) => {
    if (node.label === 'header') {
      ids.push(node._id);
    }
  };
  visit(forest, collect);
  return ids;
};

const getNode = (forest, id) => {
  let _node = undefined;
  const findId = (node) => {
    if (node._id === id) {
      _node = node;
    }
  };
  visit(forest, findId);
  // console.log( 'getNode: returning ', _node )
  return _node;
};

const checkHasVisibleChildren = (forest) => {
  if (!forest) {
    return false;
  }
  let canShow = false;
  const performCheck = (child) => {
    if (child.label === 'slide' && !child.hidden) {
      canShow = true;
    }
  };
  visit(forest, performCheck);
  return canShow;
};

const getDescendantSlides = (node) => {
  const slideIds = [];
  const collectSlides = (node) => {
    if (node.label === 'slide') {
      slideIds.push(node._id);
    }
  };
  visit(node.children, collectSlides);
  return slideIds;
};

const getDescendantSubheaders = (node) => {
  const ids = [];
  const collect = (node) => {
    // console.log({node})
    if (node.label === 'sub-header') {
      ids.push(node._id);
    }
  };
  visit(node.children, collect);
  return ids;
};

const getDescendantHeaders = (node) => {
  const ids = [];
  const collect = (node) => {
    if (node.label === 'header') {
      ids.push(node._id);
    }
  };
  visit(node.children, collect);
  return ids;
};

const addSlideCounts = (forest) => {
  const addSlideCount = (node) => {
    if ('children' in node) {
      node.numDescendantSlides = getDescendantSlides(node).length;
    }
  };
  // @todo this is far from optimal. extra credit if you identify the "correct"
  // implementation that visits each node only once
  visit(forest, addSlideCount);
};

// a 'child' can be any of header, subheader, slide
const renderChild = (
  element,
  expandedCategories,
  expandedHeaders,
  selectedHeaders,
  selectHeaders,
  selectedSubheaders,
  selectSubheaders,
  selectedSlides,
  selectSlide,
  deselectSlide,
  themeId,
  onSlideLabelClick,
  slidesGrops,
  index,
) => {
  switch (element.label) {
    case 'header':
      return (
        <Header
          key={element._id}
          header={element}
          _renderChild={(child, index) =>
            renderChild(
              child,
              expandedCategories,
              expandedHeaders,
              selectedHeaders,
              selectHeaders,
              selectedSubheaders,
              selectSubheaders,
              selectedSlides,
              selectSlide,
              deselectSlide,
              themeId,
              onSlideLabelClick,
              slidesGrops,
              index,
            )
          }
          expandedHeaders={expandedHeaders}
          selectedHeaders={selectedHeaders}
          selectHeaders={selectHeaders}
          selectSlide={selectSlide}
          deselectSlide={deselectSlide}
        />
      );
    case 'sub-header':
      return (
        <Subheader
          key={element._id}
          subheader={element}
          _renderChild={(child, index) =>
            renderChild(
              child,
              expandedCategories,
              expandedHeaders,
              selectedHeaders,
              selectHeaders,
              selectedSubheaders,
              selectSubheaders,
              selectedSlides,
              selectSlide,
              deselectSlide,
              themeId,
              onSlideLabelClick,
              slidesGrops,
              index,
            )
          }
          selectedContentSlidesIds={selectedSlides}
          selectedSubheaders={selectedSubheaders}
          selectSubheaders={selectSubheaders}
          selectSlide={selectSlide}
          deselectSlide={deselectSlide}
        />
      );
    case 'slide':
      return (
        <Slide
          key={element._id}
          slide={element}
          selectedSlides={selectedSlides}
          selectSlide={selectSlide}
          deselectSlide={deselectSlide}
          selectedContentThemeId={themeId}
          onSlideLabelClick={onSlideLabelClick}
          slidesGrops={slidesGrops}
          index={index}
        />
      );
    default:
      return <></>;
  }
};

const Header = ({
  header,
  expandedHeaders,
  selectedHeaders,
  selectHeaders,
  _renderChild,
}) => {
  const { title, _id: headerId } = header;

  const [isVisible, setIsVisible] = useState(false);

  let idx = expandedHeaders.indexOf(headerId);
  let displayThis = title !== '[No Subheader Displayed]';
  let expanded = !displayThis || idx > -1;
  let selected = selectedHeaders.indexOf(headerId) > -1;

  useEffect(() => {
    setIsVisible(checkHasVisibleChildren(header.children));
  }, [header]);

  const handleClick = (event) => {
    // console.log('handleClick: ', {selected})
    selectHeaders([headerId], !selected);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <StyledHeader key={headerId}>
        {displayThis && (
          <>
            <HeaderTitle>{`${title}`}</HeaderTitle>
            <TextButton onClick={handleClick} type="button">
              {selected ? '[Deselect all slides]' : '[Select all slides]'}
            </TextButton>
          </>
        )}
        <HeaderChildren>
          {expanded && renderHeaderChildren(header.children, _renderChild)}
        </HeaderChildren>
      </StyledHeader>
    </>
  );
};

const Subheader = ({
  subheader,
  selectedContentSlidesIds,
  selectSubheaders,
  _renderChild,
}) => {
  const [selected, setSelected] = useState(false);
  const [selectedPartially, setSelectedPartially] = useState(false);

  const { title, numDescendantSlides: numSlides, _id: subheaderId } = subheader;

  const [expandedSubheaders, setExpandedSubheaders] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const idx = expandedSubheaders.indexOf(subheaderId);
  const expanded = idx > -1;

  const numChildColumns = 1;

  // handle checkbox state
  useEffect(() => {
    if (isVisible) {
      let numSelectedSlides = 0;

      if (selectedContentSlidesIds.length > 0 && numSlides > 0) {
        for (const child of subheader.children) {
          if (selectedContentSlidesIds.indexOf(child._id) > -1) {
            numSelectedSlides += 1;
          }
        }
      }

      if (numSelectedSlides === numSlides) {
        setSelected(true);
        setSelectedPartially(false);
        return;
      }
      if (numSelectedSlides > 0) {
        setSelected(false);
        setSelectedPartially(true);
        return;
      }
      setSelected(false);
      setSelectedPartially(false);
    }
  }, [isVisible, numSlides, selectedContentSlidesIds.length]);

  useEffect(() => {
    setIsVisible(checkHasVisibleChildren(subheader.children));
  }, [subheader]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <StyledSubheader key={subheaderId}>
        <Expand
          open={expanded}
          onClick={() => {
            expanded
              ? setExpandedSubheaders(
                  expandedSubheaders.filter((item) => item !== subheaderId),
                )
              : setExpandedSubheaders([...expandedSubheaders, subheaderId]);
          }}
        />
        <StyledSubheaderLabel>
          <CheckboxLegacy
            label={`${title} (${numSlides})`}
            value={selected}
            variant={'checkbox-secondary'}
            checkedPartially={selectedPartially}
            onChange={() => selectSubheaders([subheaderId], !selected)}
          />
          {(subheader.hasNewSlides || subheader.hasUpdatedSlides) && (
            <SlideStatusInfo
              status={subheader.hasNewSlides ? 'new' : 'updated'}
            />
          )}
        </StyledSubheaderLabel>
      </StyledSubheader>
      <SubheaderChildren>
        {expanded &&
          renderSubheaderChildren(
            subheader.children,
            _renderChild,
            numChildColumns,
          )}
      </SubheaderChildren>
    </>
  );
};

const Category = ({
  category,
  selectCategory,
  selected,
  expanded,
  _renderChild,
}) => {
  const { title, _id: categoryId } = category;
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = (event) => {
    selectCategory(categoryId, !selected);
  };

  useEffect(() => {
    setIsVisible(checkHasVisibleChildren(category.children));
  }, [category]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <StyledCategory>
        <CategoryTitle>{`${title}`}</CategoryTitle>
        <TextButton onClick={handleClick} type="button">
          {selected ? '[Deselect all slides]' : '[Select all slides]'}
        </TextButton>
        <CategoryChildren>
          {expanded && renderCategoryChildren(category.children, _renderChild)}
        </CategoryChildren>
      </StyledCategory>
    </>
  );
};

const renderHierarchy = (
  hierarchy,
  selectCategory,
  selectedCategories,
  expandedCategories,
  selectedHeaders,
  expandedHeaders,
  _renderChild,
  selectHeaders,
  selectSubheaders,
  selectedSlides,
  selectSlide,
  deselectSlide,
  noSlidesMessage,
) => {
  const hasVisibleChildren = checkHasVisibleChildren(hierarchy);

  if (!hierarchy || !hierarchy.length) {
    return <StyledErrorMessage>{noSlidesMessage}</StyledErrorMessage>;
  }

  if (!hasVisibleChildren) {
    return (
      <StyledErrorMessage>
        No content slides available under selected filters.
      </StyledErrorMessage>
    );
  }

  // assumes hierarchy is list of Category
  return hierarchy.map((category) => {
    let expanded = expandedCategories.indexOf(category._id) > -1;
    let selected = selectedCategories.indexOf(category._id) > -1;
    return (
      <Category
        key={category._id}
        category={category}
        expanded={expanded}
        selected={selected}
        _renderChild={_renderChild}
        expandedHeaders={expandedHeaders}
        selectedHeaders={selectedHeaders}
        selectCategory={selectCategory}
        selectedSlides={selectedSlides}
        selectSlide={selectSlide}
        deselectSlide={deselectSlide}
      />
    );
  });
};

// @todo rather than handleCheckBoxChange, we want a function to add/remove a set of slides to/from
// the selected set. we've used handleCheckBoxChange for individual slide select/deselect, but it is
// badly coupled with other concerns so it's unusable for adding/removing multiple slides in one operation
// selectSlides( ids ) takes a list of slide ids, and adds them to the selected slide list(s)
// deselectSlides( ids ) takes a list of slide ids, and remove them from the selected slide list(s)
const HierarchyView = ({
  libraryByTopicList,
  selectedSlides = [],
  onSlideLabelClick,
  onSelectSlides = () => {},
  onDeselectSlides = () => {},
  themeId,
  contentSlidesGroups,
  removeAllButtonText = 'Remove All Slides From Presentation',
  noSlidesMessage = 'No content slides available under selected theme.',
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [expandedHeaders, setExpandedHeaders] = useState([]);

  const [selectedSubheaders, setSelectedSubheaders] = useState([]);

  const selectCategory = (categoryId, select = true) => {
    if (select) {
      setSelectedCategories([...selectedCategories, categoryId]);
      selectSlide(getDescendantSlides(getNode(libraryByTopicList, categoryId)));
      selectHeadersOnly(
        getDescendantHeaders(getNode(libraryByTopicList, categoryId)),
      );
      selectSubheadersOnly(
        getDescendantSubheaders(getNode(libraryByTopicList, categoryId)),
      );
    } else {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== categoryId),
      );
      deselectSlide(
        getDescendantSlides(getNode(libraryByTopicList, categoryId)),
      );
      selectHeadersOnly(
        getDescendantHeaders(getNode(libraryByTopicList, categoryId)),
        false,
      );
      selectSubheadersOnly(
        getDescendantSubheaders(getNode(libraryByTopicList, categoryId)),
        false,
      );
    }
  };

  const selectHeaders = (headerIds, select = true) => {
    if (select) {
      setSelectedHeaders([...selectedHeaders, ...headerIds]);
      let slides = headerIds
        .map((h) => {
          let _slides = getDescendantSlides(getNode(libraryByTopicList, h));
          return _slides;
        })
        .flat();
      // console.log( 'selectHeaders: ', { slides })
      selectSlide(slides);
      let subheaders = headerIds
        .map((h) => getDescendantSubheaders(getNode(libraryByTopicList, h)))
        .flat();
      selectSubheadersOnly(subheaders);
    } else {
      setSelectedHeaders(
        selectedHeaders.filter((item) => headerIds.indexOf(item) == -1),
      );

      let slides = headerIds
        .map((sh) => getDescendantSlides(getNode(libraryByTopicList, sh)))
        .flat();
      deselectSlide(slides);

      let subheaders = headerIds
        .map((h) => getDescendantSubheaders(getNode(libraryByTopicList, h)))
        .flat();
      selectSubheadersOnly(subheaders, false);
    }
  };

  const selectSubheaders = (subheaderIds, select = true) => {
    // console.log('selectSubheaders: ', { subheaderIds, select } )
    if (select) {
      setSelectedSubheaders([...selectedSubheaders, ...subheaderIds]);
      let slides = subheaderIds
        .map((sh) => getDescendantSlides(getNode(libraryByTopicList, sh)))
        .flat();
      // console.log( 'selectSubheaders: ', { slides })
      selectSlide(slides);
    } else {
      setSelectedSubheaders(
        selectedSubheaders.filter((item) => subheaderIds.indexOf(item) == -1),
      );
      let slides = subheaderIds
        .map((sh) => getDescendantSlides(getNode(libraryByTopicList, sh)))
        .flat();
      deselectSlide(slides);
    }
  };

  const selectSubheadersOnly = (subheaderIds, select = true) => {
    if (select) {
      setSelectedSubheaders([...selectedSubheaders, ...subheaderIds]);
    } else {
      setSelectedSubheaders(
        selectedSubheaders.filter((item) => subheaderIds.indexOf(item) == -1),
      );
    }
  };

  const selectHeadersOnly = (ids, select = true) => {
    if (select) {
      setSelectedHeaders([...selectedSubheaders, ...ids]);
    } else {
      setSelectedHeaders(
        selectedSubheaders.filter((item) => ids.indexOf(item) == -1),
      );
    }
  };

  const union = (setA, setB) => {
    const _union = new Set(setA);
    for (let elem of setB) {
      _union.add(elem);
    }
    return _union;
  };

  const difference = (setA, setB) => {
    const _difference = new Set(setA);
    for (let elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  };

  const selectSlide = (slidesIds) => {
    // this will add a slidesIds > 1x to selected slides if it is already selected
    // e.g. select individual slide then select anceestor subheader or category
    const _selectedSlides = Array.from(
      union(new Set(selectedSlides), new Set(slidesIds)),
    );
    onSelectSlides(_selectedSlides);
  };

  const deselectSlide = (slidesIds) => {
    const _selectedSlides = Array.from(
      difference(new Set(selectedSlides), new Set(slidesIds)),
    );
    onDeselectSlides(_selectedSlides);
  };

  const handleRemoveAllSlides = () => {
    if (
      window.confirm(
        'Are you sure you want to remove all slides from your presentation?',
      )
    ) {
      onDeselectSlides([]);
      setSelectedCategories([]);
      setSelectedHeaders([]);
      setSelectedSubheaders([]);
    }
  };

  useEffect(() => {
    if (libraryByTopicList.length > 0) {
      setExpandedCategories(getCategories(libraryByTopicList));
      setExpandedHeaders(getHeaders(libraryByTopicList));
      addSlideCounts(libraryByTopicList);
    }
  }, [libraryByTopicList]);

  return (
    <>
      <Ribbon>
        <Button
          disabled={!selectedSlides.length > 0}
          onClick={handleRemoveAllSlides}
        >
          {removeAllButtonText}
        </Button>
      </Ribbon>
      <StyledHierarchyView>
        {renderHierarchy(
          // @todo it's no longer clear that renderHierarchy is useful - why aren't we
          // simply calling renderChild() (or invert the names etc...)?
          libraryByTopicList,
          selectCategory,
          selectedCategories,
          expandedCategories,
          selectedHeaders,
          expandedHeaders,
          // @todo original intent was to bind the utility methods in the closure, but we've allowed
          // this to become not DRY - resolve whether to pass as properties to components or to rely on
          // the closure...
          (child, index) =>
            renderChild(
              child,
              expandedCategories,
              expandedHeaders,
              selectedHeaders,
              selectHeaders,
              selectedSubheaders,
              selectSubheaders,
              selectedSlides,
              selectSlide,
              deselectSlide,
              themeId,
              onSlideLabelClick,
              contentSlidesGroups,
              index,
            ),
          selectHeaders,
          selectSubheaders,
          selectedSlides,
          selectSlide,
          deselectSlide,
          noSlidesMessage,
        )}
      </StyledHierarchyView>
    </>
  );
};

const StyledErrorMessage = styled.p`
  padding: 10px 20px;
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: rgb(223, 24, 41);
`;
const StyledHierarchyView = styled.div`
  //   margin-top: 106px;
  display: flex;
  flex-direction: column;
`;

const Ribbon = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
  gap: 1em;
`;

const StyledCategory = styled.div`
  margin-left: 1em;
  margin-top: 1em;

  > label {
    padding-left: 20px;
    margin-left: 1em;
  }
`;

const StyledColumn = styled.div`
  padding-right: 2.5em;

  @media (min-width: 1280px) {
    padding-right: 1.5em;
  }
`;

const CategoryTitle = styled.span`
  font-family: 'Maven Pro', sans-serif;
  color: #363636;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.9;
  padding-left: 0.5em;
`;

const CategoryChildrenContainer = styled.div`
  margin-top: 1em;
  padding-left: 0.5em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const CategoryChildrenColumn = styled(StyledColumn)``;

const CategoryChildren = styled.div`
  margin-left: 0.4em;
  margin-bottom: 1em;
`;

const StyledHeader = styled.div`
  // order: ${(props) => (props.order ? props.order : 1)};
  margin-top: 1em;

  > label {
    padding-left: 20px;
    margin-left: 1em;
  }
`;

const HeaderTitle = styled.span`
  font-family: 'Maven Pro', sans-serif;
  color: #636363;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.5;
`;

const HeaderChildren = styled.div`
  margin-top: 1em;
`;

const HeaderChildrenContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1.5em;
`;

const HeaderChildrenColumn = styled(StyledColumn)``;

const StyledSubheader = styled.div`
  position: relative;
  margin-top: 0.2em;
  max-width: 240px;
  display: flex;
  margin-bottom: 4px;

  > button {
    position: absolute;
    left: -16px;
    top: 0;
  }
`;

const StyledSubheaderLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SubheaderChildren = styled.div``;

const SubheaderChildrenContainer = styled.div`
  margin-top: 0.5em;
  margin-left: 20px;
  max-width: 220px;
  display: flex;
  justify-content: space-evenly;
`;

const SubheaderChildrenColumn = styled(StyledColumn)`
  padding-right: 0;
`;

const StyledSlide = styled.div`
  position: relative;
  color: #215eff;
  font-family: 'Maven Pro', sans-serif;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  display: flex;
  max-width: 240px;
  justify-content: space-between;
  align-items: flex-start;

  label > span {
    max-width: 190px;
  }

  .slide-content {
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }

  &.group-start,
  &.group-middle,
  &.group-end {
    .slide-content {
      padding: 0.4em 0px;
    }

    &:before {
      position: absolute;
      content: '';
      width: 110%;
      background-color: ${({ theme }) =>
        theme.groups.libraryGroupBackgroundColor};
      top: 0;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      right: 0;
      padding: 10px 0px;
    }
  }

  &.group-start {
    margin-top: 3px;

    .slide-content {
      padding-top: 7px;
    }

    &:before {
      padding-top: 12px;
      border-top: ${({ theme }) => theme.groups.libraryGroupBorder};
    }

    &.column-first-slide {
      margin-top: 0px;
    }
  }

  &.group-end {
    &:before {
      border-bottom: ${({ theme }) => theme.groups.libraryGroupBorder};
    }

    margin-bottom: 7px;
  }
`;

const OutlinedButton = styled.button`
  font-family: 'Maven Pro', sans-serif;
  cursor: pointer;
  padding: 4px 18px;
  border-radius: 4px;
  border: 1px solid rgb(99, 99, 99);
  color: rgb(54, 54, 54);
  font-size: 10px;
  font-weight: bold;
  opacity: 0.7;
  transition: all 0.5s ease 0s;
  background-color: transparent;
  text-transform: capitalize;

  &:hover {
    background: rgb(54, 54, 54);
    color: rgb(255, 255, 255);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const Button = ({ onClick, children, disabled, type = 'button' }) => {
  return (
    <OutlinedButton onClick={onClick} disabled={disabled} type={type}>
      {children}
    </OutlinedButton>
  );
};

const TextButton = styled.button`
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: #215eff;
  border: none;
  background-color: white;
  margin-left: 0.5em;
  vertical-align: center;
`;

const Expand = ({ open, onClick }) => {
  return open ? (
    <Minus onClick={onClick} data-test-id="deck-collapse-button" />
  ) : (
    <Plus onClick={onClick} data-test-id="deck-expand-button" />
  );
};

const Minus = styled.button`
  width: 10px;
  height: 10px;
  margin-top: 9px;
  border: none;
  display: inline-block;
  //position: absolute;
  left: -6px;
  top: 13px;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  outline: none;
  cursor: pointer;
  /* button un-checked style */

  :before {
    content: '';
    width: 12px;
    height: 2px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #41abc1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

const Plus = styled(Minus)`
  :after {
    content: '';
    width: 12px;
    height: 2px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #41abc1;
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
    -ms-transform: translate(-50%, -50%) rotate(270deg);
    transform: translate(-50%, -50%) rotate(270deg);
  }
`;

export default HierarchyView;
