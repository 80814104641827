import styled from 'styled-components';
import PropTypes from 'prop-types';

const FloatingButton = styled.div`
  position: absolute;
  ${({ top }) => (top ? `top: ${top}px;` : '')}
  ${({ left }) => (left ? `left: ${left}px;` : '')}
  ${({ right }) => (right ? `right: ${right}px;` : '')}
  ${({ bottom }) => (bottom ? `bottom: ${bottom}px;` : '')}
  cursor: pointer;
  z-index: 1;
  background-color: white;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
  }

  svg {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0 !important;
  }
`;

FloatingButton.propTypes = {
  isHidden: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
};

export default FloatingButton;
