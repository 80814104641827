import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';
import { buttonWidths } from '../../config/consts';

const StyledIconButton = styled.button`
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    fill: #727272;
    font-size: 14px;
  }

  & + & {
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
    border-color: #1890ff;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.${buttonWidths.md} {
    height: 50px;
    width: 50px;

    svg {
      font-size: 30px;
    }
  }
`;

const IconButton = React.forwardRef(
  (
    {
      children,
      disabled,
      onClick,
      tooltip,
      width,
      type = 'button',
      ...dataAttributes
    },
    ref,
  ) => {
    const handleClick = (event) => {
      event.stopPropagation();
      if (typeof onClick === 'function') {
        onClick(event);
      }
    };

    const renderIconButton = () => (
      <StyledIconButton
        {...dataAttributes}
        disabled={disabled}
        type={type}
        onClick={handleClick}
        className={width}
        ref={ref}
      >
        {children}
      </StyledIconButton>
    );

    if (tooltip) {
      return <Tooltip text={tooltip}>{renderIconButton()}</Tooltip>;
    }

    return renderIconButton();
  },
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  identifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltip: PropTypes.string,
};

export default IconButton;
