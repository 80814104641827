import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageHeader from '../PageHeader/PageHeader';
import ImageGalleryItem from './Components/ImageGalleryItem';

const StyledImageGallery = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-rows: 160px;
  justify-content: space-between;
  padding: 16px 16px 50px 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ImageGallery = ({
  images,
  imageUrlProperty = 'url',
  titleProperty = 'name',
  handleImageDelete,
}) => {
  const renderImages = () => {
    if (!images?.length) {
      return <p>No images</p>;
    }

    return images.map((image, index) => (
      <ImageGalleryItem
        key={image?._id}
        index={index}
        image={image}
        onDelete={handleImageDelete}
        url={image[imageUrlProperty]}
        alt={image[titleProperty]}
      />
    ));
  };

  return <StyledImageGallery>{renderImages()}</StyledImageGallery>;
};

PageHeader.propTypes = {
  images: PropTypes.array,
  imageUrlProperty: PropTypes.string,
  titleProperty: PropTypes.string,
  handleImageDelete: PropTypes.func,
};

export default ImageGallery;
