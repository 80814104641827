import styled from 'styled-components';
import { defaultBorderColor } from '../../config/theme';

const StyledDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${defaultBorderColor};
`


const Divider = () => {
  return <StyledDivider />
};

export default Divider;
