import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
import requestStatus from './requestStatus';
import reportsApi from '../api/reports.api';
import reportTypes from './reportTypes';
export const REPORT_FEATURE_KEY = 'report';
export const reportAdapter = createEntityAdapter();

export const fetchReport = createAsyncThunk(
  `${REPORT_FEATURE_KEY}/fetchStatus`,
  async ({ reportName, params, data }, { rejectWithValue }) => {
    try {
      switch (reportName) {
        case reportTypes.msmMicrositesDetail:
          return await reportsApi.getMicrositeDetails(params);
        case reportTypes.msmPageview:
          return await reportsApi.getPageview(params);
        case reportTypes.slideDownloadCounts:
          return await reportsApi.getSlideDownloadCounts(data);
        case reportTypes.msmAssetUsage:
          return await reportsApi.getMicrositeAssetUsage();
        case reportTypes.msmUnusedAsset:
          return await reportsApi.getMicrositeUnusedAsset();
        case reportTypes.slideUsage:
          return await reportsApi.getSlidesUsage(params);
        case reportTypes.presentationByUser:
          return await reportsApi.getPresentationsByUser(params);
        case reportTypes.presentationDetail:
          return await reportsApi.getPresentationsStats(params);
        case reportTypes.smDocumentsByUser:
          return await reportsApi.getSmDocumentsByUser(params);
        case reportTypes.smDocumentsAssetUsage:
          return await reportsApi.getSmDocumentsAssetUsage(params);
        case reportTypes.smTemplateUsage:
            return await reportsApi.getTemplateUsage(params);
        case reportTypes.smTemplatesUnusedAssets:
            return await reportsApi.getSmDocumentsUnusedAssets(params)
        default:
          console.warn(`Invalid report name: ${reportName}`);
      }
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);
export const initialReportState = reportAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  reports: {},
});
export const reportSlice = createSlice({
  name: REPORT_FEATURE_KEY,
  initialState: initialReportState,
  reducers: {
    add: reportAdapter.addOne,
    remove: reportAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReport.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchReport.fulfilled, (state, { meta, payload }) => {
        let reportData = payload.data;

        if (
          [reportTypes.msmAssetUsage, reportTypes.msmUnusedAsset].includes(
            meta.arg.reportName,
          )
        ) {
          reportData = {
            bio: payload.data.bio.map((row) => ({
              ...row,
              uuid: uuidv4(),
            })),
            video: payload.data.video.map((row) => ({
              ...row,
              uuid: uuidv4(),
            })),
            file: payload.data.file.map((row) => ({
              ...row,
              uuid: uuidv4(),
            })),
            url: payload.data.url.map((row) => ({
              ...row,
              uuid: uuidv4(),
            })),
          };
        }

        if (
          [reportTypes.msmMicrositesDetail, reportTypes.msmPageview].includes(
            meta.arg.reportName,
          )
        ) {
          reportData = payload.data.map((row) => ({
            ...row,
            uuid: uuidv4(),
          }));
        }

        if ([reportTypes.presentationByUser].includes(meta.arg.reportName)) {
          const flattenPresentationsByUserData = [];
          let calculatedFieldsByUserId = {};

          payload.data.forEach(({ presentations, ...userRow }) => {
            presentations.forEach((presentation) => {
              const isPresentationBuilt = presentation.status === 'Completed';

              const presentation_slides_count =
                presentation.slides.length +
                (presentation.includeCover ? 1 : 0);

              if (calculatedFieldsByUserId[userRow.id]) {
                calculatedFieldsByUserId[userRow.id]['presentations_built'] +=
                  isPresentationBuilt ? 1 : 0;
                calculatedFieldsByUserId[userRow.id]['presentations_all'] += 1;
                calculatedFieldsByUserId[userRow.id]['total_slides_count'] +=
                  presentation_slides_count;
              } else {
                calculatedFieldsByUserId[userRow.id] = {
                  presentations_built: isPresentationBuilt ? 1 : 0,
                  presentations_all: 1,
                  total_slides_count: presentation_slides_count,
                };
              }

              flattenPresentationsByUserData.push({
                ...userRow,
                ...presentation,
                id: `${userRow.id}-${presentation._id}`,
                user_id: userRow.id,
                presentation_id: presentation._id,
                presentation_status: presentation.status,
                presentation_slides_count: presentation_slides_count,
              });

              calculatedFieldsByUserId[userRow.id]['avg_slides_count'] =
                Math.round(
                  calculatedFieldsByUserId[userRow.id].total_slides_count /
                    presentations.length,
                );
            });

            reportData = flattenPresentationsByUserData.map(
              (presentationData) => {
                return {
                  ...presentationData,
                  ...calculatedFieldsByUserId[presentationData.user_id],
                };
              },
            );
          });
        }

        if ([reportTypes.slideUsage].includes(meta.arg.reportName)) {
          const flattenSlideUsageData = [];

          payload.data.forEach((slideRow) => {
            if (slideRow.presentations.length > 0) {
              slideRow.presentations.forEach((presentation) => {
                flattenSlideUsageData.push({
                  ...slideRow,
                  ...presentation,
                  id: `${presentation._id}-${slideRow.id}`,
                  theme_title: presentation.theme?.title,
                  presentation_status: presentation.status,
                  presentation_id: presentation._id,
                  slide_id: slideRow.id,
                  slide_status: slideRow.enabled ? 'Enabled' : 'Disabled',
                  slideUsed: true,
                  presentations_all: slideRow.all_presentations_count,
                  presentations_built: slideRow.built_presentations_count,
                });
              });
            } else {
              flattenSlideUsageData.push({
                ...slideRow,
                slide_id: slideRow.id,
                slide_status: slideRow.enabled ? 'Enabled' : 'Disabled',
                slideUsed: false,
                presentations_all: 0,
                presentations_built: 0,
              });
            }

            reportData = flattenSlideUsageData;
          });
        }

        if ([reportTypes.smDocumentsAssetUsage].includes(meta.arg.reportName)) {
          const mappedData = { topic: [] };
          if (payload?.data && Object.keys(payload?.data).length) {
            // topic map
            (payload?.data?.['topic'] || [])?.forEach((asset) => {
              const data = {
                id: asset._id,
                template_name: asset.template.name,
                block_name: asset.block.display_name,
                name: asset.title || asset.name,
              };
              if (asset.documents?.length) {
                asset.documents.forEach((assetDocument) => {
                  mappedData.topic.push({
                    ...data,
                    id: `${data.id}-${assetDocument._id}`,
                    document_created: assetDocument.created_at,
                    document_name: assetDocument.name,
                  });
                });
              } else {
                mappedData.topic.push(data);
              }
            });
          }
          reportData = mappedData;
        }

        state.reports[meta.arg.reportName] = {
          params: meta.arg.params,
          data: reportData,
        };
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchReport.rejected, (state, { meta, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error?.message;
        state.reports[meta.arg.reportName] = {
          params: meta.arg.params,
          data: [],
        };
      });
  },
});

export const reportReducer = reportSlice.reducer;
export const reportActions = reportSlice.actions;
const { selectAll, selectEntities } = reportAdapter.getSelectors();
export const getReportState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][REPORT_FEATURE_KEY];
export const selectAllReport = createSelector(getReportState, selectAll);
export const selectReportEntities = createSelector(
  getReportState,
  selectEntities,
);

export const selectReportByName = (reportName = []) =>
  createSelector(getReportState, ({ reports }) => reports[reportName]);

export const reportsSelectors = {
  loadingStatus: createSelector(
    getReportState,
    ({ loadingStatus }) => loadingStatus,
  ),

  selectReportByName: selectReportByName,
};
