import { getAxiosWithAuthorization } from '@clatter/platform';

const themesApi = {
  getThemesForContentRepository: ({contentRepositoryId}) =>
    getAxiosWithAuthorization({
      url: `${contentRepositoryId}/themes`,
      method: 'GET',
    }),
};

export default themesApi;
