import Joi from 'joi';

const userRoleSchema = Joi.object({
  user_id: Joi.number().required(),
  role_id: Joi.number().required(),
  role: Joi.object({
    id: Joi.number().required(),
    auth0_role_id: Joi.string(),
    name: Joi.string(),
    display_name: Joi.string(),
  }),
});

export const userSchema = Joi.object({
  id: Joi.number().required(),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  email: Joi.string().email({ tlds: false }).required(), // without this: { tlds: false } there are error in the browser!
  auth0_id: Joi.string().required(),
  active: Joi.boolean().required(),
  blocked: Joi.boolean().required(),
  email_verified: Joi.date().iso().allow(null),
  auth0_last_login: Joi.date().iso().allow(null),
  auth0_num_of_logins: Joi.number().integer().allow(null),
  createdAt: Joi.date().iso().required(),
  createdBy: Joi.string().allow(null),
  updatedAt: Joi.date().iso().required(),
  custom_attributes: Joi.array(),
  roles: Joi.array().items(userRoleSchema),
  content_repositories: Joi.array().items(Joi.string()),
  last_login: Joi.date().iso().allow(null),
  num_of_logins: Joi.number().allow(null),
});

export const userResponseSchema = Joi.object({
  data: Joi.array().items(userSchema),
  result: Joi.string().optional(),
});
