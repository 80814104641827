import axios from 'axios';
import auth0 from 'auth0-js';
import {
  setAuthCookie,
  deleteAuthCookie,
  deleteLocalStorageAuth,
  getLocalStorageAccessToken,
  setLocalStorageAccessAuth,
} from '@clatter/platform';

const webAuth = new auth0.WebAuth({
  domain: process.env.NX_AUTH0_DOMAIN,
  clientID: process.env.NX_AUTH0_CLIENT_ID,
  audience: process.env.NX_AUTH0_AUDIENCE,
  realm: process.env.NX_AUTH0_CONNECTION_NAME || 'Username-Password-Authentication',
  redirectUri: typeof window === 'object' ? window.location.origin : '/', // window is not defined in msm-next SSR
  scope: 'openid profile email',
  responseType: 'token id_token',
});

const refreshAccessToken = async () => {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      {
        audience: process.env.NX_AUTH0_AUDIENCE,
        scope: 'openid profile email',
        responseType: 'token id_token',
        clientID: process.env.NX_AUTH0_CLIENT_ID,
      },
      (error, authResult) => {
        if (!error && authResult && authResult.accessToken) {
          setLocalStorageAccessAuth(authResult);
          setAuthCookie(authResult);

          resolve(authResult.accessToken);
        } else {
          deleteLocalStorageAuth();
          deleteAuthCookie();

          reject(error || new Error('Failed to refresh access token'));
        }
      },
    );
  });
};

export const getAxiosClientWithRefreshToken = ({ baseURL, authHeaderName = 'Authorization' }) => {
  const client = axios.create({
    baseURL: baseURL,
  });

  client.interceptors.request.use(
    (config) => {
      const auth0Token = getLocalStorageAccessToken();

      if (auth0Token) {
        config.headers.common[authHeaderName] = `Bearer ${auth0Token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // check if response status is 401 and this request hasn't been retried yet (to avoid infinite loop)
      if (error.response && error.response.status === 401 && !originalRequest.alreadyRetried) {
        try {
          // grab a fresh access token
          const newToken = await refreshAccessToken();

          // retry the request with the new token
          originalRequest.headers[authHeaderName] = `Bearer ${newToken}`;

          error.config.alreadyRetried = true; // set the flag to avoid infinite loop in case of next 401

          return await client(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      // return any error if not related to 401
      return Promise.reject(error);
    },
  );

  return client;
};
