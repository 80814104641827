import { useEffect } from 'react';

// Usage for this effect is whenever we are trying to update state, because what it does
// it is ensuring that "setState" ( or any state update) would not be triggered
// when component is not mounted (which can lead to memory leaks)
export const useMountedEffect = (cb, dependencies = []) => {
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      cb();
    }

    return () => {
      mounted = false;
    };
  }, dependencies);
};
