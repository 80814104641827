import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { Divider, PageTitleBreadcrumbs } from '@clatter/ui';

const StyledTitle = styled.h1`
  text-transform: capitalize;
`;

const StyledText = styled.p`
  margin-bottom: 5px;
  white-space: pre-line;
`;

const StyledRibbon = styled(Grid)`
  position: relative;
  z-index: 1;
  min-height: 163px;
`;

const Ribbon = ({
  title,
  pageTitle,
  text = '',
  ribbonActions,
  disableDivider = false,
}) => {
  return (
    <>
      <StyledRibbon container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          { pageTitle && pageTitle }
          { title && <StyledTitle>{title}</StyledTitle> }
          { text && <StyledText>{text}</StyledText> }
        </Grid>
        {ribbonActions && (
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent="space-between"
            alignItems="stretch"
          >
            {ribbonActions}
          </Grid>
        )}
      </StyledRibbon>
      {!disableDivider && <Divider />}
    </>
  );
};

Ribbon.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ribbonActions: PropTypes.node,
};

export default Ribbon;
