import styled from 'styled-components';

const StyledDataGridPremium = styled.div.attrs((props) => ({
  showVerticalSidebar: props.showVerticalSidebar || false,
}))`
  .datagrid-custom-toolbar {
    padding: 16px 24px;
    border-bottom: 1px solid #e0e0e0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
  }

  .MuiDataGrid-root {
    font-family: 'Maven Pro', Arial, sans-serif;
    font-size: 1rem;
    border-radius: 0;

    .MuiDataGrid-virtualScroller {
      overflow-x: ${({ showVerticalSidebar }) =>
        showVerticalSidebar ? 'unset' : 'hidden'};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 0.85rem;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        border-radius: 0;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  .MuiDataGrid-colCellTitle {
    font-weight: 600;
  }

  .MuiFormGroup-options {
    align-items: center;
    padding-bottom: 8px;

    & > div {
      min-width: 100px;
      margin: 16px 16px 16px 0;
    }
  }

  .MuiTablePagination-root {
    p {
      margin-bottom: 0;
    }
  }
  .emptyGroup {
    button {
      visibility: hidden;
    }
  }
`;

export default StyledDataGridPremium;
