import { useState, useEffect, useCallback } from 'react';
import { downloadBlobFile, imageOrientationTypes } from '@clatter/platform';

export const useImageProperties = (imageUrl) => {
  const [imageOrientation, setImageOrientation] = useState('');

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setImageOrientation(image.naturalWidth > image.naturalHeight
        ? imageOrientationTypes.LANDSCAPE
        : imageOrientationTypes.PORTRAIT
      );
    };

    return () => {
      // Clean up the image load event listener if component unmounts
      image.onload = null;
    };
  }, [imageUrl]);

  const downloadImageFromUrl = useCallback(async (url, name) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const urlParts = url.split('/');
    const fileName = name || urlParts[urlParts.length - 1];

    downloadBlobFile(blob, fileName);
  }, []);

  return { imageOrientation, downloadImageFromUrl };
};
