import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { eventRequestTypes, useAuth, useEventTracking } from '@clatter/platform';

const TrackPageviewEvent = () => {
  const location = useLocation();
  const { trackEvent } = useEventTracking();
  const { activeUser } = useAuth();

  useEffect(() => {
    if (activeUser?.id) {
      // define a blacklist of path names that should not log the PAGEVISIT event
      const pathNamesBlacklist = [
        '/sso/callback',
      ];

      if (pathNamesBlacklist.includes(location.pathname)) {
        return;
      }

      trackEvent(eventRequestTypes.pagevisit);
    }
  }, [location.key, activeUser?.id]);

  return null;
};

export default TrackPageviewEvent;
