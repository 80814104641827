export const tertiaryColor = '#e6f0f7';
export const successColor = '#7CC522FF';
export const dangerColor = '#ff4d4d';

export const primaryColor = '#257789';
export const secondaryColor = '#ee8b4f';

export const textColor = '#454b49';

export const defaultBorderColor = '#d2d2d2';
export const defaultBorderRadius = '4px';

export const linkColor = '#454b49';
export const linkHoverColor = '#257789';

export const pageHeaderBackgroundColor = '#fff';
export const pageHeaderTextColor = '#454b49';
export const pageHeaderHeight = '64px';

export const inputFontSize = '14px';
export const inputHeight = '40px';
export const inputBorderColor = '#d2d2d2';
export const inputActiveBorderColor = '#257789';

const defaultTheme = {
  body: {
    backgroundColor: '#f4f7fc',
    fontFamily: 'Arial, sans-serif',
  },
  link: {
    color: '#454b49',
  },
  defaultBorderRadius: defaultBorderRadius,
  pageHeader: {
    height: '64px',
    color: '#454b49',
    backgroundColor: '#fff',
    borderBottom: '1px solid #333',
    logo: {
      height: '64px',
    },
    branding: {
      text: {
        fontSize: '24px',
        color: '#454b49',
      },
    },
    separator: {
      color: '#000',
      height: '32px',
    },
    nav: {
      color: '#454b49',
      hoverColor: '#257789',
    },
  },
  sectionHeader: {
    color: '#454b49',
  },
  palette: {
    white: '#ffffff',
    textColor: textColor,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    error: '#fe615a',
    errorDimmed: '#ffb3b0',
    grey: '#ececec',
    darkGrey: '#8d8d8d',
    linkHoverColor: '#257789',
    linkColor: '#454b49',
    textLinkColor: '#215EFFFF',
    warning: '#F44336',
    danger: dangerColor,
    success: successColor,
    hoverBackgroundColor: '#25778917',
    buttonTextColor: '#262626',
  },
  groups: {
    libraryGroupBackgroundColor: 'rgb(238, 247, 249)',
    sortGroupBackgroundColor: 'rgb(218, 246, 252)',
    libraryGroupBorder: '2px solid rgb(65, 171, 193)',
    sortGroupBorder: '2px solid white',
    sortListGroupBorder: '2px solid rgb(65, 171, 193)',
  },
  slideStatusInfo: {
    newBackgroundColor: '#7cc522',
    newTextColor: '#ffffff',
    updatedBackgroundColor: '#0080ff',
    updatedTextColor: '#ffffff',
  },
  sortTabSideTabsContent: {
    rowGap: '5px',
    padding: '10px 20px 10px 20px',
    height: '580px',
  },
  slideSortThumbnail: {
    fontSize: '12px',
    color: 'rgba(33, 94, 255, 0.8)',
    backgroundColor: '#fff',
    hoverShadow: 'rgb(0 0 0 / 14%) 4px 8px 20px 0px',
    addedBackgroundColor: 'rgba(124, 197, 34, 0.2)',
    slideOverBarColor: 'blue',
    slideOverBarWidth: '2px',
    activeBorder: '4px solid rgb(95,143,192)',
    deleteButton: {
      backgroundColor: '#fff',
      color: '#8d8d8d',
      hoverColor: '#ff4d4d',
      disabledColor: '#ececec',
    },
  },
  slideDividerThumbnail: {
    backgroundColor: '#fff',
    color: 'rgba(33, 94, 255, 0.8)',
    fontSize: '14px',
    border: '3px dashed rgba(151, 151, 151, 0.59)',
    borderRadius: '4px',
    draggingOpacity: '0.7',
  },
  slideListItem: {
    fontSize: '12px',
    indexFontSize: '12px',
    fontWeight: 'bold',
    padding: '10px 10px',
    slideOverBarColor: 'blue',
    slideOverBarWidth: '2px',
    draggingOpacity: '0.9',
    backgroundColor: 'rgb(238,247,249)',
    containerBackgroundColor: 'white',
    containerPadding: '5px',
    addedBackgroundColor: 'rgb(237, 252, 219)',
    activeBorder: '3px solid rgb(95,143,192)',
  },
  zoomControls: {
    color: '#8d8d8d',
    backgroundColor: '#8f8f8f',
    buttonColor: '#8d8d8d',
    buttonDisabledColor: '#bfbfbf',
    sliderHoverShadow: '0px 0px 0px 8px rgb(0 0 0 / 10%);',
    sliderActiveShadow: '0px 0px 0px 14px rgb(0 0 0 / 16%);',
  },
  viewControls: {
    color: '#bfbfbf',
    activeColor: '#636363',
  },
  landingPage: {
    headerColor: '#353535',
    textColor: '#636363',
    pageBox: {
      backgroundColor: '#fff',
      maxWidth: '1080px',
    },
  }
};

export const themes = {
  default: defaultTheme,
};
