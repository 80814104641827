import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import toolInfoApi from '../api/toolInfo.api';
import requestStatus from './requestStatus';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
export const TOOLINFO_FEATURE_KEY = 'toolInfo';
export const toolInfoAdapter = createEntityAdapter();

export const fetchToolInfo = createAsyncThunk(
  `${TOOLINFO_FEATURE_KEY}/fetchStatus`,
  toolInfoApi.getInfo,
);

export const initialToolInfoState = {
  data: undefined,
  loadingStatus: requestStatus.initial,
  error: null,
};

export const toolInfoSlice = createSlice({
  name: TOOLINFO_FEATURE_KEY,
  initialState: initialToolInfoState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchToolInfo.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchToolInfo.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchToolInfo.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      });
  },
});

export const toolInfoReducer = toolInfoSlice.reducer;

export const toolInfoActions = toolInfoSlice.actions;

export const getToolInfoState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][TOOLINFO_FEATURE_KEY];

export const toolInfoSelectors = {
  selectToolInfo: createSelector(getToolInfoState, (state) => state),
};
