export const reportTypes = {
  loginCounts: 'login-counts',
  slideDownloadCounts: 'slide-download-counts',
  presentationDetail: 'presentation-detail',
  presentationByUser: 'presentation-by-user',
  slideUsage: 'slide-usage',
  msmPageview: 'msm-pageview',
  msmAssetUsage: 'msm-asset-usage',
  msmUnusedAsset: 'msm-unused-asset',
  msmMicrositesDetail: 'microsites-detail',
  smDocumentsByUser: 'sm-documents-by-user',
  smDocumentsAssetUsage: 'sm-documents-asset-usage',
  smTemplateUsage: 'template-usage',
  smTemplatesUnusedAssets: 'templates-unused-asset'
};

export default reportTypes;
