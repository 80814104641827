import React from 'react';
import styled from 'styled-components';

const StyledTextareaControl = styled.textarea`
  border: 1px solid #ddd;
  font-family: 'Maven Pro', sans-serif;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  min-height: 120px;
  resize: vertical;

  white-space: pre-wrap;
  overflow-wrap: break-word;

  &:focus {
    border-color: #1890ff;
  }
`;

const TextareaControl = React.forwardRef(
  ({ maxLength, ...inputProps }, ref) => (
    <StyledTextareaControl ref={ref} maxLength={maxLength} {...inputProps} />
  ),
);

export default TextareaControl;
