import {
  Summarize as SummarizeIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import {
  generateReportsUrls,
  hasActiveFeatureFlag,
  featureFlagsMap,
} from '@clatter/platform';

export const getMenuItems = ({ featureFlags, activeTool, reports }) => {
  const menuItems = [];

  if (
    featureFlags &&
    Array.isArray(featureFlags) &&
    hasActiveFeatureFlag({
      flagName: featureFlagsMap.HOME.reports,
      featureFlags: featureFlags,
    })
  ) {
    menuItems.push({
      label: 'Reports',
      children: generateReportsUrls({
        reports: reports,
        featureFlags: featureFlags,
      }),
      icon: SummarizeIcon,
    });
  }

  if (
    activeTool
    && activeTool.help_url
    && hasActiveFeatureFlag({
      flagName: featureFlagsMap.HOME.help,
      featureFlags: featureFlags,
    })
  ) {
    menuItems.push({
      label: 'Support',
      url: activeTool.help_url,
      icon: HelpIcon,
      external: true,
    });
  }

  return menuItems;
};
