import React, { useRef, useState } from 'react';
import TextControl from './TextControl';
import styled from 'styled-components';
import IconButton2 from '../IconButton2/IconButton2';
import { Edit as EditIcon, Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import FormControl from './FormControl';
import { useMountedEffect } from '@clatter/platform';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    display: inline-block;
    min-width: 30vw;
  }

  &.element {
    button {
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: nowrap;
  }
`;

const allowedTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span'];
export const EditableTextElement = ({
  element,
  onTextChange,
  customValidator,
  label = 'Title',
  initialValue = '',
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState('');
  const [localError, setLocalError] = useState(null);

  const initialValueRef = useRef(null);
  const inputRef = useRef(null);
  const isValidTag = allowedTags.includes(element);

  const isConfirmDisabled = localError || editedValue === initialValueRef.current;

  //region METHODS
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleConfirmClick = () => {
    setIsEditing(false);
    onTextChange(editedValue);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedValue(initialValue);
  };

  const validateField = (e) => {
    if (customValidator && typeof customValidator === 'function') {
      const error = customValidator(e.target.value);
      if (error) {
        setLocalError(error);
        return;
      }
    }

    setLocalError(null);
    return null;
  }

  const handleInputChange = (e) => {
    validateField(e)
    setEditedValue(e.target.value?.trim());
  };
  //endregion

  //region EFFECTS
  useMountedEffect(() => {
    initialValueRef.current = initialValue;
    setEditedValue(initialValue?.trim());
  }, [initialValue]);

  useMountedEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);
  //endregion

  const renderEditableContent = () => (
    <FormControl label={label} error={{ message: localError }}>
      <StyledWrapper>
        <TextControl
          ref={inputRef}
          type="text"
          value={editedValue}
          hasError={localError}
          onBlur={validateField}
          onChange={handleInputChange}
          fullWidth
        />
        <div className="buttons">
          <IconButton2 onClick={handleConfirmClick} disabled={isConfirmDisabled}>
            <DoneIcon />
          </IconButton2>
          <IconButton2 onClick={handleCancelClick}>
            <CloseIcon />
          </IconButton2>
        </div>
      </StyledWrapper>
    </FormControl>
  );

  const renderElement = (value) => React.createElement(element, null, value);

  return (
    <>
      {isEditing ? (
        renderEditableContent()
      ) : (
        <StyledWrapper className="element">
          { children ? children : renderElement(editedValue)}
          <IconButton2 onClick={handleEditClick}>
            <EditIcon />
          </IconButton2>
        </StyledWrapper>
      )}
    </>
  );
};
