import React from 'react';
import styled from 'styled-components';
import { Close as CloseIcon } from '@mui/icons-material';

const StyledChip = styled.div`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  height: 30px;
  max-width: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 4px 0 1rem;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.darkGrey};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    color: ${({ theme }) => theme.palette.white};
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: auto 5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      color: ${({ theme }) => theme.palette.textColor};
      transition: all 0.2s ease-in;
    }
  }
`;

const Chip = ({ label, id, onClick }) => (
  <StyledChip key={id}>
    <span>{label}</span>
    <StyledButton onClick={() => onClick(id)}>
      <CloseIcon />
    </StyledButton>
  </StyledChip>
);

export default Chip;
