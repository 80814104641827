import React, { useState, useEffect } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Grid, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControl,
  FormMessage,
  FormHelperText,
  TextareaControl,
  TextTagsControl,
  errorMessages,
} from '@clatter/ui';
import { useForm } from 'react-hook-form';
import {
  BuildChoiceView,
  getAvailableDownloadOptions,
  isEmailValid,
} from '@clatter/platform';

const CircleButton = styled.button`
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 2px solid black;
    border-radius: 100%;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  svg {
    transition: all 0.2s ease-in-out;
    font-size: 1.7rem;
  }
  &:hover {
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.4);
    &:after {
      width: 23px;
      height: 23px;
      opacity: 1;
    }
    svg {
      transform: rotateZ(360deg);
      font-size: 1.3rem;
    }
  }
`;

const StyledCircleButton = styled(CircleButton)`
  position: absolute;
  right: -15px;
  top: -15px;
`;

const StyledContent = styled.div`
  padding: 2em 3em;
`;

const StyledTitle = styled.h2`
  text-transform: capitalize;
  font-size: 2rem;
`;

const StyledEmailInputContainer = styled.div`
  min-height: 150px;
`;

const StyledCheckboxContainer = styled.div`
  margin-bottom: 25px;
`;

const StyledInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 50vh;
`;

const StyledMessageContainer = styled.div`
  margin-top: 10px;
`;

// needed for custom close button positioning
const useStyles = makeStyles(() => ({
  paper: {
    overflowY: 'unset',
  },
}));

const emailIsAlreadyAdded = (emailsArray, emailToCheck) => {
  return !!emailsArray.some((email) => email === emailToCheck);
};

const ShareForm = ({ shareModalMessage, onSubmit }) => {
  const noteMaxLength = 500;
  const [noteRemainingCharacters, setNoteRemainingCharacters] =
    useState(noteMaxLength);
  const {
    register: shareFormRegister,
    handleSubmit: shareFormHandleSubmit,
    formState: { errors: shareFormErrors },
    watch: shareFormWatch,
    trigger: shareFormTrigger,
    setValue: shareFormSetValue,
    setError: shareFormSetError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      sendMailToSelf: false,
      email: '',
      emails: [],
      note: '',
    },
  });
  const noteWatch = shareFormWatch('note');
  const emailWatch = shareFormWatch('email');
  const selectedEmailsWatch = shareFormWatch('emails');

  const handleEmailInputChange = async (data) => {
    // trigger "email" field validation
    await shareFormTrigger('email');

    // if email is invalid do not add it to the emails list
    if (shareFormErrors.email?.type === errorMessages.INVALID.type) {
      return;
    }

    // display error message if email is already added to the list
    if (emailIsAlreadyAdded(selectedEmailsWatch, emailWatch)) {
      return shareFormSetError('email', {
        type: errorMessages.UNIQUE.type,
        message: errorMessages.UNIQUE.message,
      });
    }

    // if ok add email to selected emails
    if (data && Array.isArray(data)) {
      shareFormSetValue('emails', data);
    }

    // reset email field
    shareFormSetValue('email', '');
  };

  const handleSubmit = (data) => {
    if (!emailWatch.length > 0 && !selectedEmailsWatch.length > 0) {
      return shareFormSetError('email', {
        type: errorMessages.REQUIRED.type,
        message: errorMessages.REQUIRED.message,
      });
    }

    // handle input not confirmed by pressing "enter" key
    if (emailWatch.length > 0) {
      shareFormSetValue('email', '');
      data.emails.push(emailWatch);
    }

    onSubmit(data);
  };

  // note field characters count
  useEffect(() => {
    setNoteRemainingCharacters(noteMaxLength - noteWatch.length);
  }, [noteWatch]);

  return (
    <form key="share-form" onSubmit={shareFormHandleSubmit(handleSubmit)}>
      <StyledInputsContainer>
        <StyledEmailInputContainer>
          <FormControl
            label="Enter email address*"
            error={shareFormErrors.email}
          >
            <TextTagsControl
              inputValue={emailWatch}
              tags={selectedEmailsWatch}
              onTagsChange={handleEmailInputChange}
              {...shareFormRegister('email', {
                validate: {
                  [errorMessages.INVALID.type]: (v) => {
                    if (v.length > 0) {
                      return isEmailValid(v) || errorMessages.INVALID.message;
                    }
                  },
                  [errorMessages.UNIQUE.type]: (v) =>
                    !emailIsAlreadyAdded(selectedEmailsWatch, v) ||
                    errorMessages.UNIQUE.message,
                },
              })}
            />
            <FormHelperText>
              To send to multiple users, press ENTER after addresses.
            </FormHelperText>
          </FormControl>
        </StyledEmailInputContainer>

        <StyledCheckboxContainer>
          <Checkbox
            text="Send copy to myself"
            data-test-id="share-send-to-myself-checkbox"
            {...shareFormRegister('sendMailToSelf')}
          />
        </StyledCheckboxContainer>

        <FormControl label="Add a custom note" error={shareFormErrors.note}>
          <TextareaControl
            maxLength={noteMaxLength}
            data-test-id="share-note"
            {...shareFormRegister('note', {
              maxLength: noteMaxLength,
            })}
          />
          <FormHelperText>
            {noteRemainingCharacters} characters remaining
          </FormHelperText>
        </FormControl>
      </StyledInputsContainer>

      <Button type="submit" fullWidth testId="share-submit">
        Send
      </Button>

      {shareModalMessage?.text && (
        <StyledMessageContainer>
          <FormMessage message={shareModalMessage} />
        </StyledMessageContainer>
      )}
    </form>
  );
};

const SharePresentationDialog = ({
  opened,
  onClose,
  onSubmit,
  downloadType,
  presentation,
  shareModalMessage,
}) => {
  const classes = useStyles();
  const buildChoices = getAvailableDownloadOptions(presentation);

  const [selectedDownloadType, setSelectedDownloadType] =
    useState(downloadType);

  useEffect(() => {
    if (downloadType === null) {
      setSelectedDownloadType(buildChoices?.[0]?.value);
    } else {
      setSelectedDownloadType(downloadType);
    }
  }, [downloadType, opened]);

  const handleSubmit = React.useCallback(
    (values) => {
      onSubmit(values)({
        selectedDownloadType: selectedDownloadType,
        presentation: presentation,
      });
    },
    [selectedDownloadType, presentation?._id],
  );

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={opened}
      onClose={onClose}
      transitionDuration={10}
      maxWidth="md"
      fullWidth
    >
      <StyledCircleButton onClick={onClose}>
        <CloseIcon />
      </StyledCircleButton>

      <StyledContent>
        <StyledTitle>Share</StyledTitle>
        <Grid item container>
          <Grid item xs={12} md={6} pt={1}>
            <p>Send a copy to another user via email.</p>
            {downloadType === null && (
              <BuildChoiceView
                defaultChoice={buildChoices?.[0]?.value}
                onChange={setSelectedDownloadType}
                choices={buildChoices}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <ShareForm
              onSubmit={handleSubmit}
              downloadType={selectedDownloadType}
              shareModalMessage={shareModalMessage}
            />
          </Grid>
        </Grid>
      </StyledContent>
    </Dialog>
  );
};

SharePresentationDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SharePresentationDialog;
