import {
  createEntityAdapter,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';

export const CONTENT_SLIDE_DIALOG_FEATURE_KEY = 'contentSlideDialog';
export const contentSlideDialogAdapter = createEntityAdapter();

export const setContentSlideDialogPreviewMode = createAction(
  'SET_CONTENT_SLIDE_DIALOG_PREVIEW_MODE',
);

export const setContentSlideDialogModalOpened = createAction(
  'SET_CONTENT_SLIDE_DIALOG_OPENED',
);

export const contentSlideDialogState =
  contentSlideDialogAdapter.getInitialState({
    previewMode: false,
    opened: null, // this will hold id of the dialog (e.g 'slide-selector-dialog') to prevent opening multiple instances at once
  });

export const contentSlideDialogSlice = createSlice({
  name: CONTENT_SLIDE_DIALOG_FEATURE_KEY,
  initialState: contentSlideDialogState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setContentSlideDialogPreviewMode, (state, { payload }) => {
      state.previewMode = payload;
    });
    builder.addCase(setContentSlideDialogModalOpened, (state, { payload }) => {
      state.opened = payload;
    });
  },
});

export const contentSlideDialogReducer = contentSlideDialogSlice.reducer;

export const contentSlideDialogActions = contentSlideDialogSlice.actions;
