import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
} from 'react-device-detect';
import { trackEvent, useAuth, useGetActiveTool } from '@clatter/platform';

const useEventTracking = () => {
  const dispatch = useDispatch();
  const activeTool = useGetActiveTool();
  const { activeUser } = useAuth();

  const _createEvent = (eventName, eventProps = {}) => ({
    type: eventName,
    data: {
      app_version: process.env.NX_APP_VERSION || 'dev',
      browser_name: browserName,
      browser_version: browserVersion,
      current_url: window.location.href,
      screen_height: window.innerHeight,
      screen_width: window.innerWidth,
      os_name: osName,
      os_version: osVersion,
      tool: activeTool?.slug?.toUpperCase() || (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
      user_id: activeUser?.id,
      user_email: activeUser?.email,
      user_name: activeUser?.name,
      custom_attributes: activeUser?.custom_attributes,
      ...eventProps,
    },
  });

  const track = useCallback(
    (eventName, eventProps = {}) =>
      dispatch(trackEvent(_createEvent(eventName, eventProps))),
    [dispatch, activeUser?.id, activeTool],
  );

  return {
    trackEvent: track,
  };
};

export default useEventTracking;
