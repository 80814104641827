import styled from 'styled-components';

export const StyledUserIconCircle = styled.div`
  height: 40px;
  width: 40px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.primaryColor};
  border: 2px solid transparent;
  border-radius: 50%;
  user-select: none;
  transition: 0.5s all ease;
  cursor: pointer;
`;

export const StyledUserIconInitial = styled.span`
  line-height: unset;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  color: ${({ theme }) => theme.palette.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  transition: 0.5s all ease;
  width: 100%;
  height: 100%;
  padding-top: 3px;
  text-align: center;
`;
