import getAxiosApiV2WithAuthorization from '../services/axiosApiV2Factory';

const toolApi = {
  getConfiguration: () =>
    getAxiosApiV2WithAuthorization({
      url: `/configuration`,
      method: 'GET',
    }),
  addToolTemplate: ({ toolId, templateId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/tool/${toolId}/template/${templateId}`,
      method: 'PUT',
    }),
};

export default toolApi;
