import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import AdjustIcon from '@mui/icons-material/Adjust';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import classNames from 'classnames';
import { NoticesList, buttonVariants, useDocumentTitle,  } from '@clatter/ui';
import { useNotices, usePageTitleHeader } from '@clatter/platform';

const StyledStepper = styled.div`
  min-height: calc(100% - 64px);
  display: grid;
  grid-template-areas:
    'steps'
    'stepContent';
  grid-template-rows: 56px 1fr;

  .steps {
    grid-area: steps;
    display: flex;
    align-items: center;
    background-color: #636363;
    color: #fff;
    padding: 0 24px;

    .steps-item {
      line-height: 56px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      font-size: 14px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      > span {
        margin-left: 8px;
        text-transform: capitalize;
      }

      &--active {
        background-color: #808080;
        font-weight: 700;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -16px;
          border-width: 10px;
          border-style: solid;
          border-color: #0000 #0000 #0000 #808080;
          transform: translateY(-50%);
        }
      }
    }
  }

  .step-content {
    grid-area: stepContent;
    overflow-y: auto;
  }

  .step-form {
    display: block;
    height: 100%;
    grid-template-rows: 93px 1fr;
    padding: 16px 24px;
    max-width: 1250px;
    margin: 0 auto;

    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 24px 0;
      border-bottom: 1px solid #9797973d;

      .step-title {
        padding-right: 60px;

        h1 {
          font-size: 20px;
          margin: 0;
          color: #363636;
          opacity: 0.7;
          font-weight: 700;
          text-transform: capitalize;
        }

        @media (max-width: 768px) {
          padding-right: 0;
        }
      }

      .step-buttons {
        display: flex;

        > * + * {
          margin-left: 16px;
        }

        @media (max-width: 530px) {
          flex-direction: column;
          justify-content: center;
          row-gap: 10px;

          > * + * {
            margin: 0;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .no-border {
      border: none;
    }

    .step-body {
      padding: ${({ contentPadding }) => contentPadding ?? '24px 0 0'};
      min-height: 0;

      &.sticky-fix {
        height: 100px !important;
        margin-bottom: 120px;
      }
    }
  }
`;

const StyledHeaderDescription = styled.h4`
  white-space: pre-line;
`
const Stepper = ({
  getSteps,
  data = {},
  initialStepIndex = 0,
  shouldUnregister = false,
  externalValidation = true,
  pageTitlePrefix,
  contentPadding,
  routes,
}) => {
  const { addNotice } = useNotices();
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
  const [loading, setLoading] = useState(false);

  const resolver = () => (values) => {
    let errors = {};

    if (typeof steps[currentStepIndex]?.validate === 'function') {
      errors = {
        ...errors,
        ...steps[currentStepIndex].validate(values),
      };
    }

    return {
      values,
      errors,
    };
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister,
    defaultValues: data,
    resolver: externalValidation && resolver(),
  });

  const {
    clearErrors,
    control,
    formState: { errors: formErrors, isValid, touchedFields, dirtyFields },
    handleSubmit,
    reset,
    trigger,
    watch,
    getValues,
  } = formMethods;

  const isNextDisabled = !isValid || Object.keys(formErrors)?.length > 0;

  const formValues = watch();

  const steps = useMemo(
    () => getSteps(formValues, currentStepIndex) || [],
    [getSteps, formValues, currentStepIndex],
  );

  // set page title for GA4
  const getPageTitle = useMemo(
    () =>
      pageTitlePrefix
        ? `${pageTitlePrefix}:${steps[currentStepIndex]?.label || ''}`
        : '',
    [steps, currentStepIndex, pageTitlePrefix],
  );

  useDocumentTitle(getPageTitle);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const hasPrevStep = () => currentStepIndex !== 0;

  const hasNextStep = () => steps?.length - 1 > currentStepIndex;

  const handleSave =
    (nextStepIndex, ignoreValidation = false) =>
    async (formData) => {
      setLoading(true);
      return new Promise((resolve) => {
        (async () => {
          try {
            await steps[currentStepIndex]?.onSave?.(
              formData,
              ignoreValidation,
              {
                touchedFields,
                dirtyFields,
                isValid,
              },
            );
            !isNaN(nextStepIndex) && setCurrentStepIndex(Number(nextStepIndex));
            resolve(formData);
            if (ignoreValidation) {
              clearErrors();
              trigger();
            }
          } catch (error) {
            addNotice({
              message: error.message,
              title: 'Error',
              type: 'error',
            });
          }
          setLoading(false);
        })();
      });
    };

  const handlePrevClick = () => {
    handleSubmit(
      handleSave(currentStepIndex - 1),
      handleSave(currentStepIndex - 1, true),
    )();
  };

  const handleNextClick = () => {
    handleSubmit(handleSave(currentStepIndex + 1))();
  };

  const handleSkipClick = () =>
    setCurrentStepIndex(Number(currentStepIndex + 1));

  const handleStepClick = (event, isNextDisabled) => {
    const nextCurrentStepIndex = +event?.currentTarget?.dataset?.index;

    // next step is lower than current - we are going back to previous step
    if (nextCurrentStepIndex < currentStepIndex) {
      return handleSubmit(
        handleSave(nextCurrentStepIndex),
        handleSave(nextCurrentStepIndex, true),
      )();
    }

    if (
      currentStepIndex < nextCurrentStepIndex &&
      steps[nextCurrentStepIndex]?.validPreviousStepsRequired
    ) {
      if (isNextDisabled) {
        return;
      }

      let errors = {};
      for (let i = 0; i < nextCurrentStepIndex; i++) {
        if (typeof steps[i].validate === 'function') {
          errors = {
            ...errors,
            ...steps[i]?.validate(formValues),
          };
        }
      }

      return (
        !Object.keys(errors).length &&
        handleSubmit(handleSave(nextCurrentStepIndex))()
      );
    }

    return handleSubmit(handleSave(nextCurrentStepIndex))();
  };

  const renderSteps = () => (
    <div className="steps">
      {steps.map(({ label, icon: Icon }, index) => (
        <button
          className={classNames('steps-item', {
            'steps-item--active': index === currentStepIndex,
          })}
          data-index={index}
          key={label}
          onClick={(event) => handleStepClick(event, isNextDisabled)}
        >
          {Icon ? <Icon /> : <AdjustIcon />}
          <span>{label}</span>
        </button>
      ))}
    </div>
  );

  const renderStepPanel = () => {
    const StepPanel = steps[currentStepIndex]?.component;

    if (!StepPanel) {
      return null;
    }

    return <StepPanel {...(steps[currentStepIndex]?.componentProps || {})} />;
  };

  const currentDocumentName = getValues('presentation_name') || getValues('name');

  const renderStepHeader = ({ documentName }) => {
    const currentStep = steps[currentStepIndex];

    const { renderPageTitleHeader } = usePageTitleHeader({
      pageTitle: currentStep?.headerTitle || currentStep?.label,
      currentDocumentName: documentName,
      noMargin: true,
      customLinks: currentDocumentName ? [
        {
          underline: 'hover',
          color: 'inherit',
          url: '',
          onClick: () => handleStepClick({ currentTarget: { dataset: { index: 0 } } }),
          label: currentDocumentName,
        },
      ] : [],
      linksType: 'stepper',
      routes: routes,
    });

    return (
      <>
        <div>
          { renderPageTitleHeader() }
          {currentStep?.headerDescription && (
            <StyledHeaderDescription>{currentStep?.headerDescription}</StyledHeaderDescription>
          )}
        </div>

        <div className="step-buttons">
          {currentStep?.extraActions?.length &&
            currentStep?.extraActions.reduce((actions, action) => {
              if (action?.jsx) {
                const Component = action.jsx;
                actions.push(
                  <Component
                    {...action.props}
                    {...(action?.passFormState && {
                      formState: {
                        isValid,
                        formValues,
                        errors: formErrors,
                        touchedFields,
                      },
                    })}
                    key={action.key}
                  />,
                );
              } else {
                actions.push(
                  <Button
                    disabled={action.disabled}
                    key={action.key}
                    variant={action.variant ?? buttonVariants.clear}
                    icon={action.icon}
                    onClick={() => action.callback(formValues, handleSubmit(handleSave(0)))}
                  >
                    {action.label}
                  </Button>,
                );
              }

              return actions;
            }, [])}
          {hasPrevStep() && (
            <Button onClick={handlePrevClick} testId="prev-step">
              Prev
            </Button>
          )}
          {currentStep?.allowStepSkip && (
            <Button onClick={handleSkipClick} testId="skip-step">
              Skip
            </Button>
          )}
          {hasNextStep() && (
            <Button disabled={isNextDisabled} onClick={handleNextClick} testId="next-step">
              Next
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <StyledStepper contentPadding={contentPadding}>
      {loading && <Loader />}
      {renderSteps()}
      <div className="step-content">
        <DevTool control={control} placement="bottom-left" />
        <FormProvider {...formMethods}>
          {/* prevent reloading page when hitting "enter" on any input field included in the stepper */}
          <form onSubmit={(e) => e.preventDefault()} className="step-form">
            <NoticesList />
            <div className={classNames('step-header', { 'no-border': steps?.[currentStepIndex]?.noHeaderBorder })}>
              {renderStepHeader({ documentName: currentDocumentName })}
            </div>
            <div className="step-body">{renderStepPanel()}</div>
          </form>
        </FormProvider>
      </div>
    </StyledStepper>
  );
};

Stepper.propTypes = {
  getSteps: PropTypes.func.isRequired,
  externalValidation: PropTypes.bool,
  data: PropTypes.object,
  initialStepIndex: PropTypes.number,
};

export default Stepper;
