import React from 'react';
import styled from 'styled-components';
import { defaultBorderColor } from '@clatter/ui';

const StyledTable = styled.table`
  border-collapse: collapse;
  border: none;
  width: 100%;

  tr {
    td,
    th {
      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }

  thead {
    tr {
      th {
        line-height: 24px;
        padding: 8px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${defaultBorderColor};

      td {
        padding: 4px 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.sticky-header {
    thead {
      tr {
        th {
          background-color: #fff;
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }
`;

const Table = ({ children, stickyHeader }) => (
  <StyledTable className={`${stickyHeader ? 'sticky-header' : ''}`}>
    {children}
  </StyledTable>
);

export default Table;
