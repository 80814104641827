import React, { useEffect, useRef, forwardRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateRangePicker } from 'react-date-range';
import { get } from 'lodash';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Button,
  Stack,
} from '@mui/material';
import {
  format,
  endOfMonth,
  startOfDay,
  endOfDay,
  startOfMonth,
  startOfQuarter,
  endOfQuarter,
  subDays,
  subMonths,
  subYears,
  addYears,
  endOfYear,
  startOfYear,
  isEqual,
  min as minDate,
  parseISO,
  sub,
} from 'date-fns';
import { FormControl, SelectControl } from '../Forms/index';
import { formatDate } from '@clatter/platform';
import { Typography } from '@clatter/ui';

const StyledDateRangeText = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 4px;
`;

const StyledDateRangePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // hide left-hand site calendar range selector
  > .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    display: none !important;
  }
`;

const renderRange = (range) => {
  const formattedStartDate = formatDate(range.startDate);
  const formattedEndDate = formatDate(range.endDate);

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const RenderDateRange = ({ selectedPeriod }) => {
  if (!selectedPeriod) {
    return null;
  }

  return (
    <StyledDateRangeText>
      <Typography variant="body2">Showing data for:</Typography>

      <Typography variant="body2" data-test-id="selected-date-range-text">
        {renderRange({
          startDate: selectedPeriod.range.startDate,
          endDate: selectedPeriod.range.endDate,
        })}
      </Typography>
    </StyledDateRangeText>
  );
};

export const defaultGranularityOptions = [
  { value: 'weekly', label: 'Week' },
  { value: 'monthly', label: 'Month' },
];

export const baseDateRangeOptions = [
  {
    value: 'past-30-days',
    label: 'Past 30 Days',
    range: {
      startDate: format(
        startOfDay(subDays(new Date(), 30)),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'last-90-days',
    label: 'Last 90 Days',
    range: {
      startDate: format(
        startOfDay(subDays(new Date(), 90)),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'last-365-days',
    label: 'Last 365 Days',
    range: {
      startDate: format(
        startOfDay(subDays(new Date(), 365)),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'today',
    label: 'Today',
    range: {
      startDate: format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'this-month',
    label: 'This Month',
    range: {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'this-quarter',
    label: 'This Quarter',
    range: {
      startDate: format(startOfQuarter(new Date()), 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(endOfQuarter(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  {
    value: 'year',
    label: 'This Year',
    range: {
      startDate: format(startOfYear(new Date()), 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(endOfYear(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    },
  },
];

const previousDateRangeOptions = [
  {
    value: 'previous-month',
    label: 'Previous Month',
    range: {
      startDate: format(
        startOfMonth(subMonths(new Date(), 1)),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(
        subDays(startOfMonth(new Date()), 1),
        'yyyy-MM-dd HH:mm:ss',
      ),
    },
  },
  {
    value: 'previous-quarter',
    label: 'Previous Quarter',
    range: {
      startDate: format(
        sub(startOfQuarter(new Date()), { months: 3 }),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(
        sub(endOfQuarter(new Date()), { months: 3 }),
        'yyyy-MM-dd HH:mm:ss',
      ),
    },
  },
  {
    value: 'previous-year',
    label: 'Previous Year',
    range: {
      startDate: format(
        sub(startOfYear(new Date()), { years: 1 }),
        'yyyy-MM-dd HH:mm:ss',
      ),
      endDate: format(
        sub(endOfYear(new Date()), { years: 1 }),
        'yyyy-MM-dd HH:mm:ss',
      ),
    },
  },
];

const defaultDateRangeOptions = [
  ...baseDateRangeOptions,
  ...previousDateRangeOptions,
];

const StyledDateAndGranularity = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 10px;
`;

export const dateRangeOptionCustom = {
  value: 'custom',
  range: {
    startDate: null,
    endDate: null,
  },
  label: 'Custom',
};

const getInitialRanges = (customDateRangeValue) =>
  customDateRangeValue
    ? {
        startDate: customDateRangeValue.startDate,
        endDate: customDateRangeValue.endDate,
        key: 'selection',
      }
    : {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection',
      };

const formatDateRange = (dateRange, options) => {
  if (options.hasOwnProperty('maxDate')) {
    return {
      ...dateRange,
      range: {
        ...dateRange.range,
        endDate: format(
          minDate([parseISO(dateRange.range.endDate), options.maxDate]),
          'yyyy-MM-dd HH:mm:ss',
        ),
      },
    };
  }

  return dateRange;
};

const DateAndGranularityPicker = forwardRef(
  (
    {
      disabled,
      onBlur,
      onChange,
      labelMinWidth,
      alignLeft = false,
      showGranularity = true,
      showCustomDateRange = true,
      customSelectedDateRange,
      customDateRangeValue,
      customDateRangeOptions = {},
      defaultDateRangeValue = 'past-30-days',
      persistedDateRange,
      defaultGranularityValue = 'weekly',
      dateRangeOptions = defaultDateRangeOptions,
      granularityOptions = defaultGranularityOptions,
    },
    ref,
  ) => {
    const selectedDateRange = useRef();
    const selectedGranularity = useRef();
    const [customRanges, setCustomRanges] = useState(
      getInitialRanges(customDateRangeValue),
    );
    const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);
    const filterRef = useRef();

    const dropdownDateRangeOptions = [...dateRangeOptions];
    if (showCustomDateRange) {
      dropdownDateRangeOptions.push(dateRangeOptionCustom);
    }

    const isResetDisabled = useMemo(
      () =>
        isEqual(
          new Date(customRanges?.startDate),
          new Date(getInitialRanges(customDateRangeValue)?.startDate),
        ) &&
        isEqual(
          new Date(customRanges?.endDate),
          new Date(getInitialRanges(customDateRangeValue)?.endDate),
        ),
      [customRanges?.startDate, customRanges?.endDate],
    );

    useEffect(() => {
      const selectedDate =  dateRangeOptions.find(
        (option) => option.value === defaultDateRangeValue,
      ) || dateRangeOptions[0];

      // If there is custom date range options
      // provided format date accordingly
      selectedDateRange.current = formatDateRange(
        selectedDate,
        customDateRangeOptions,
      );

      selectedGranularity.current =
        granularityOptions.find(
          (option) => option.value === defaultGranularityValue,
        ) || granularityOptions[0];
      handleChange({});
    }, []);

    const handleChange = (changedField) =>
      onChange({
        granularity: selectedGranularity.current,
        dateRange: selectedDateRange.current,
        ...changedField,
      });

    const closeCustomDateRange = (event) => {
      if (filterRef.current && filterRef.current.contains(event.target)) {
        return;
      }
      setIsCustomRangeOpen(false);
    };
    const handleRangeChange = ({ selection }) => setCustomRanges(selection);

    const next5Years = endOfYear(addYears(new Date(), 5));
    const last10Years = endOfYear(subYears(new Date(), 10));

    return (
      <StyledDateAndGranularity ref={ref}>
        {!alignLeft && <Typography variant="body2">Date Range:</Typography>}
        <div ref={filterRef}>
          <FormControl
            label={alignLeft ? 'Date Range:' : ''}
            labelMinWidth={labelMinWidth}
            alignLeft
          >
            <SelectControl
              isDisabled={disabled}
              onBlur={onBlur}
              menuPosition="absolute"
              inputId="select-date-range"
              onChange={(option) => {
                if (option.value === 'custom') {
                  // set select input label without triggering a request
                  selectedDateRange.current = {
                    label: 'Custom',
                    value: 'custom',
                    range: selectedDateRange?.current?.range,
                  };
                  return setIsCustomRangeOpen(true);
                }

                const formattedDateRange = formatDateRange(
                  option,
                  customDateRangeOptions,
                );

                selectedDateRange.current = formattedDateRange;
                handleChange({ dateRange: formattedDateRange });
              }}
              options={dropdownDateRangeOptions}
              value={selectedDateRange.current}
            />
          </FormControl>
        </div>
        {showGranularity && (
          <>
            <div>Group By:</div>
            <div>
              <SelectControl
                isDisabled={disabled}
                onBlur={onBlur}
                menuPosition="absolute"
                inputId="select-granularity"
                onChange={(option) => {
                  selectedGranularity.current = option;
                  handleChange({ granularity: option });
                }}
                options={granularityOptions}
                value={selectedGranularity.current}
              />
            </div>
          </>
        )}

        <RenderDateRange
          selectedPeriod={customSelectedDateRange || selectedDateRange.current}
        />

        <Popper
          open={isCustomRangeOpen}
          anchorEl={get(filterRef, 'current', null)}
          role={undefined}
          placement="bottom-end"
          transition
          style={{ zIndex: 1200 }}
          className="dateAndGranularityPickerModal"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                style={{ padding: 20, border: '1px solid rgb(170, 170, 170)' }}
              >
                <ClickAwayListener onClickAway={closeCustomDateRange}>
                  <StyledDateRangePickerWrapper>
                    <DateRangePicker
                      ranges={[customRanges]}
                      minDate={get(
                        customDateRangeOptions,
                        'minDate',
                        last10Years,
                      )}
                      maxDate={get(
                        customDateRangeOptions,
                        'maxDate',
                        next5Years,
                      )}
                      onChange={handleRangeChange}
                      staticRanges={[]}
                      inputRanges={[]}
                    />
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="end"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        data-test-id="date-range-picker-reset-button"
                        onClick={() => {
                          setCustomRanges(
                            getInitialRanges(customDateRangeValue),
                          );
                        }}
                        disabled={isResetDisabled}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        data-test-id="date-range-picker-submit-button"
                        onClick={(event) => {
                          const customValue = {
                            value: 'custom',
                            label: 'Custom',
                            range: {
                              startDate: format(
                                startOfDay(customRanges.startDate),
                                'yyyy-MM-dd HH:mm:ss',
                              ),
                              endDate: format(
                                endOfDay(customRanges.endDate),
                                'yyyy-MM-dd HH:mm:ss',
                              ),
                            },
                          };
                          selectedDateRange.current = customValue;
                          setCustomRanges(customRanges);
                          closeCustomDateRange(event);
                          handleChange({
                            dateRange: customValue,
                          });
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </StyledDateRangePickerWrapper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </StyledDateAndGranularity>
    );
  },
);

DateAndGranularityPicker.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showGranularity: PropTypes.bool,
  defaultDateRangeValue: PropTypes.string,
  defaultGranularityValue: PropTypes.string,
  showCustomDateRange: PropTypes.bool,
  customDateRangeValue: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      range: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  granularityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default DateAndGranularityPicker;
