import { css } from 'styled-components';

export const sidebarNavLinkBaseStyles = css`
  border-left: 2px solid transparent;
  color: rgba(54, 54, 54, 0.54) !important;
  display: flex;
  padding: 0 16px 0 32px;
  height: 40px;
  align-items: center;

  svg {
    margin-right: 12px;
  }

  span {
    font-size: 12px;
    font-weight: 700;
  }

  &:hover,
  &.active {
    background-color: rgba(37, 119, 137, 0.09);
    border-left-color: ${({ theme }) => theme.palette.primaryColor};
  }
`;
