import getAxiosApiV2WithAuthorization from '../services/axiosApiV2Factory';

const featureFlagApi = {
  fetchFeatureFlag: () =>
    getAxiosApiV2WithAuthorization({
      url: '/feature-flag',
      method: 'get',
    }),
  updateFeatureFlag: ({ id, active }) =>
    getAxiosApiV2WithAuthorization({
      url: `/feature-flag/${id}`,
      method: 'patch',
      data: {
        active: active,
      },
    }),
};

export default featureFlagApi;
