import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const starterDecksApi = {
  fetchStarterDecks: ({ params = {} }) => {
    return getAxiosApiV2WithAuthorization({
      url: '/decks',
      method: 'get',
      params: { ...params },
    });
  },
  createStarterDeck: ({ payload } = {}) => {
    return getAxiosApiV2WithAuthorization({
      url: '/decks',
      method: 'post',
      data: payload,
    });
  },
  updateStarterDeck: ({ starterDeckId, payload } = {}) => {
    return getAxiosApiV2WithAuthorization({
      url: `/decks/${starterDeckId}`,
      method: 'put',
      data: payload,
    });
  },
  reorderStarterDecks: ({ payload } = {}) => {
    return getAxiosApiV2WithAuthorization({
      url: `/decks/reorder`,
      method: 'post',
      data: payload,
    });
  },
  deleteStarterDeck: (starterDeckId) => {
    return getAxiosApiV2WithAuthorization({
      url: `/decks/${starterDeckId}`,
      method: 'delete',
    });
  },
};

export default starterDecksApi;
