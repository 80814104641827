import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loader, PageHeader, useDocumentTitle } from '@clatter/ui';
import { platformSelectors, useGetActiveTool, requestStatus } from '@clatter/platform';
import routes, { documentTitleMap } from '../../routes';
import { getMenuItems } from '../../helpers/menuItems';

const StyledIFrame = styled.iframe`
  border: none;
  width: 100%;
  height: calc(100vh - 64px);
`;

const HomePageView = () => {
  useDocumentTitle(documentTitleMap.index)
  const activeTool = useGetActiveTool();
  const [showLoader, setShowLoader] = useState(true);
  const toolsList = useSelector(platformSelectors.tools.selectAllTools);
  const toolInfo = useSelector(platformSelectors.toolInfo.selectToolInfo);
  const featureFlags = useSelector(platformSelectors.featureFlag.selectAllFeatureFlags);
  const { landingPageUrl, loadingStatus: selectSystemProfileLoadingStatus } = useSelector(
    platformSelectors.systemProfile.selectSystemProfile,
  );
  const menuItemsList = getMenuItems({
    featureFlags: featureFlags,
    activeTool: activeTool,
    reports: [],
  });

  const hideLoader = () => setShowLoader(false);

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const redirectToValue = url.searchParams.get('redirectTo');

  useEffect(() => {
    if (redirectToValue) {
      window.location.replace(redirectToValue);
    }
  }, [redirectToValue]);

  useEffect(() => {
    if (!landingPageUrl && selectSystemProfileLoadingStatus === requestStatus.fulfilled && toolsList.length > 0) {
      // historically, we were using PM (with a slug of pg) as "always enabled default tool"
      // yet, it's not the case now (system can be provisioned without PM tool enabled)
      //
      // let's check if PM is enabled or not and if not, then simply grab 1st enabled tool from the list
      const defaultTool = toolsList.find((tool) => tool.slug === 'pg');

      return window.location.replace(defaultTool ? defaultTool.url : toolsList[0].url);
    }
  }, [landingPageUrl, selectSystemProfileLoadingStatus, toolsList.length]);

  return (
    <>
      {showLoader && <Loader />}
      <PageHeader
        logoUrl={toolInfo?.data?.companyLogo?.location}
        homeUrl={routes.index}
        menuItems={menuItemsList}
        toolItems={toolsList}
      />

      <StyledIFrame
        onLoad={hideLoader}
        allow="clipboard-read; clipboard-write"
        src={landingPageUrl}
      />
    </>
  );
};

export default HomePageView;
