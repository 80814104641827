import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { rolesApi } from '../api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';
import {
  getFormDataForAccessRole,
  userFriendlyRolesMap,
  toolsOrder,
} from '../helpers/roles';

const mapApiToStore = (systemAccessRoles) => {
  const normalized = { ...systemAccessRoles };
  Object.keys(normalized).forEach((key) => {
    normalized[key] = normalized[key].map((role) => ({
      ...role,
      roleOwner: role.is_admin ? 'admin' : 'user',
    }));
  });
  return normalized;
};

const generateAppRoles = (systemAccessRoles) => {
  const transfromedRoles = Object.entries(systemAccessRoles).reduce(
    (acc, curr) => {
      const [key, value] = curr;
      const currentRoles = [];

      value.forEach((toolRole) => {
        currentRoles.push({
          id: toolRole.id,
          name: toolRole.name,
          label: userFriendlyRolesMap[toolRole.name] || toolRole.name,
          enableForm: getFormDataForAccessRole(toolRole.name),
        });
      });

      return { ...acc, [key]: currentRoles.reverse() };
    },
    {},
  );

  return toolsOrder
    .map((value) => transfromedRoles[value])
    .flat()
    .filter((value) => value);
};

export const ROLES_FEATURE_KEY = 'roles';
export const rolesAdapter = createEntityAdapter({
  selectId: (row) => row.id,
});

export const fetchRoles = createAsyncThunk(
  `${ROLES_FEATURE_KEY}/fetchRoles`,
  async () => await rolesApi.fetchRoles(),
);

export const initialRolesState = rolesAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  systemAccessRoles: [],
  appRoles: [],
  error: null,
});

export const rolesSlice = createSlice({
  name: ROLES_FEATURE_KEY,
  initialState: initialRolesState,
  reducers: {
    add: rolesAdapter.addOne,
    remove: rolesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.systemAccessRoles = mapApiToStore(payload.data);
        state.appRoles = generateAppRoles(payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchRoles.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

export const rolesReducer = rolesSlice.reducer;
export const rolesActions = rolesSlice.actions;

const { selectAll, selectEntities } = rolesAdapter.getSelectors();

export const getRolesState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][ROLES_FEATURE_KEY];

export const rolesSelectors = {
  loadingStatus: createSelector(
    getRolesState,
    ({ loadingStatus }) => loadingStatus,
  ),
  selectAllThemes: createSelector(getRolesState, selectAll),
  selectThemesEntities: createSelector(getRolesState, selectEntities),
};
