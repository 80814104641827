import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

const StyledSelectControl = styled(Select)`
  border: none;
  min-width: 200px;

  .react-select__menu-portal {
    z-index: 2;
  }

  .react-select__control {
    border-color: #ddd;
    min-height: 40px;

    &:hover,
    &--is-focused,
    &--menu-is-open {
      border-color: #1890ff;
      box-shadow: none;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option {
    &.react-select__option--is-focused:not(.react-select__option--is-selected) {
      background-color: #f4f4f4;
    }

    &.react-select__option--is-selected {
      color: #fff;
      background-color: #1890ff;
    }
  }
`;

// override default menu component to add data-test-id for cypress tests
const Menu = (props) => {
  return (
    <components.Menu {...props}>
      <div data-test-id="react-select-menu-list">{props.children}</div>
    </components.Menu>
  );
};

// override default menu component to add data-test-id for cypress tests
const SelectContainer = (props) => {
  const { selectProps } = props;

  return (
    <components.SelectContainer {...props}>
      <div data-test-id={selectProps?.selectDataTestId || 'react-select'}>
        {props.children}
      </div>
    </components.SelectContainer>
  );
};

const SelectControl = ({ menuPosition = 'fixed', ...props }) => (
  <StyledSelectControl
    key={`${props.id || ''}_${props.value ? 'hasValue':'empty'}`}
    menuPosition={menuPosition}
    classNamePrefix="react-select"
    components={{
      Menu,
      SelectContainer,
    }}
    {...props}
  />
);

export default SelectControl;
