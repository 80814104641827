import { formatDateTime, useDeepCompareMemo } from '@clatter/platform';

const commonColumns = [
  {
    headerName: 'E-mail',
    field: 'email',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'User Group',
    field: 'user_group',
    sortable: true,
    flex: 1,
    renderCell: ({ value }) => value,
  },
  {
    field: 'createdAt',
    headerName: 'Date added',
    renderCell: (item) => formatDateTime(item?.row?.createdAt),
    align: 'left',
    flex: 1,
    sortable: true,
    type: 'date',
  },
  {
    headerName: 'Last Login',
    field: 'last_login',
    sortable: true,
    flex: 1,
    renderCell: ({ value }) => (value ? formatDateTime(value) : null),
  },
];

export const useTableData = ({ usersReportData, emailDefaultValue = null, usersExtraColumns = [], usersByEmailMap }) => {
  const usersRows = useDeepCompareMemo(
    () =>
      (usersReportData || []).map((row, index) => {
        // let's get common who columns data
        let userData = {
          id: index + 1,
          email: row._id.user_email || emailDefaultValue,
          user_group: row?.user_custom_attributes?.user_group?.[0] || row?.user_custom_attributes?.[0]?.user_group,
          last_login: row.last_login,
          // augment "usersReportData" by data from users slice
          createdAt: usersByEmailMap ? usersByEmailMap?.[row._id.user_email]?.[0]?.createdAt : null,
        };

        // get extra columns fields data
        for (let extraColumn of usersExtraColumns) {
          const { field } = extraColumn || {};
          if (!field) {
            return;
          }
          userData[field] = row?.[field];
        }
        return userData;
      }),
    [usersReportData, usersByEmailMap, usersExtraColumns],
  );

  const usersColumns = useDeepCompareMemo(
    () => [...commonColumns, ...(usersExtraColumns || [])],
    [commonColumns, usersExtraColumns],
  );

  return {
    usersRows,
    usersColumns,
  };
};

