import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { platformActions, platformSelectors, useGetActiveTool } from '@clatter/platform';
import { useLocation } from 'react-router-dom';

export const useRoutesState = (customKey) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const toolSlug = useGetActiveTool()?.slug;
  const routeKey = customKey || location?.pathname;

  const routeState = useSelector(platformSelectors.routesState.selectRouteStateByKey({ toolSlug, routeKey }));

  const getRouteStateProperty = useCallback((propName) => routeState?.[propName]);

  const setRouteStateProperty = useCallback(async (propData) => {
    await dispatch(
      platformActions.routesState.set({
        routeKey: routeKey,
        data: propData,
      }),
    );
  }, []);
  return {
    getRouteStateProperty,
    setRouteStateProperty,
  };
};
