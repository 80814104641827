import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Grid, Stack } from '@mui/material';
import classNames from 'classnames';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import {
  downloadFromUrl,
  logDownloadContentSlide,
  ThumbnailTooltip,
  useGetActiveTool,
  useDeepCompareMemo,
  getSlideThumbnailByTheme,
} from '@clatter/platform';
import { Divider, IconButton } from '@clatter/ui';

const StyledContainer = styled(Grid)`
  position: relative;
  margin: auto;

  .select-button {
    border: none;

    svg {
      font-size: 20px;
    }

    &.selected {
      border: 1px solid ${({ theme }) => theme.palette.errorDimmed};
      border-radius: 3px;

      svg {
        fill: red;
        font-size: 16px;
      }
    }
  }

  .download-button {
    border: none;

    svg {
      font-size: 24px;
    }
  }
`;

const StyledButtonWrapper = styled.div`
  cursor: pointer;
`;

const StyledGroupChip = styled.span`
  padding: 2px 10px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: rgb(124, 197, 34);
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 0.8em;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.h5`
  margin: 0 0 5px 0;
  display: inline-block;
  color: ${({ theme }) => theme.palette.textLinkColor};
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
`;

const StyledSlidePath = styled.p`
  font-size: 10px;
  margin-bottom: 0;
  color: rgb(54, 54, 54);
`;

const SearchResultItem = ({
  slide,
  showDivider,
  onSlideLabelClick,
  onSelectSlides,
  onDeselectSlides,
  selectedContentSlidesIds,
  selectedContentThemeId,
  hideDownloadButtons,
  contentSlidesGroups,
}) => {
  const { _id, title, path, slideListByThemes, isRequired } = slide;
  const dispatch = useDispatch();
  const activeTool = useGetActiveTool()?.slug;

  const isSelected = useMemo(
    () => selectedContentSlidesIds.includes(_id),
    [selectedContentSlidesIds],
  );

  const thumbnailUrl = useDeepCompareMemo(
    () => getSlideThumbnailByTheme({ slide: slide, themeId: selectedContentThemeId }),
    [slide, selectedContentThemeId]
  );

  const handleSelectSlide = () => {
    const isInGroup = !!slide.group?._id;

    let updated = [...selectedContentSlidesIds];

    if (isInGroup) {
      const groupSlidesIds = contentSlidesGroups[slide.group?._id] ?? [];

      if (
        isSelected &&
        window.confirm(
          'Removing this slide will remove all the slides belonging to this group. Do you still want to continue?',
        )
      ) {
        for (const slideId of groupSlidesIds) {
          updated = updated.filter((itemId) => itemId !== slideId);
        }
        onDeselectSlides(updated);
        return;
      }

      if (
        !isSelected &&
        window.confirm(
          'Adding this slide will add all the slides belonging to this group. Do you still want to continue?',
        )
      ) {
        onSelectSlides([...updated, ...groupSlidesIds]);
        return;
      }

      return;
    }

    if (isSelected) {
      return onDeselectSlides(updated.filter((slideId) => slideId !== _id));
    }
    onSelectSlides([...selectedContentSlidesIds, _id]);
  };

  const handleDownloadSlide = () => {
    const downloadData = slideListByThemes.find(
      (item) => item.themeId === selectedContentThemeId,
    );
    if (downloadData) {
      const data = {
        id: _id,
        type: 'contentSlide',
        themeId: selectedContentThemeId,
        tool: activeTool?.toUpperCase(),
      };
      downloadFromUrl(downloadData.fileLocation.url);
      dispatch(logDownloadContentSlide(data));
    }
  };

  const buttonTooltipText = () => {
    if (isRequired) {
      return 'Slide is required';
    }
    if (isSelected) {
      return 'Remove from presentation';
    }
    return 'Add to presentation';
  };

  return (
    <>
      <StyledContainer
        container
        alignItems="center"
        spacing={0}
        data-testid="search-result-item"
      >
        <Grid item xs={12} md={10}>
          <ThumbnailTooltip url={thumbnailUrl} alt={slide.title}>
            <StyledTitle
              data-testid="slide-title"
              onClick={() => onSlideLabelClick(slide)}
            >
              {title}
            </StyledTitle>
          </ThumbnailTooltip>
          <StyledDescriptionContainer>
            {slide.group && (
              <StyledGroupChip>{slide.group.title}</StyledGroupChip>
            )}
            <StyledSlidePath data-testid="slide_path">{path}</StyledSlidePath>
          </StyledDescriptionContainer>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            pr={5}
            mt={{ xs: 2, md: 0 }}
            spacing={{ xs: 2, md: 3 }}
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          >
            <StyledButtonWrapper>
              <IconButton
                disabled={isRequired}
                data-testid={
                  isSelected && !isRequired
                    ? 'btn-deselect-slide'
                    : 'btn-select-slide'
                }
                onClick={handleSelectSlide}
                className={classNames('select-button', {
                  selected: isSelected,
                })}
                tooltip={buttonTooltipText()}
              >
                {isSelected && !isRequired ? <CloseIcon /> : <AddIcon />}
              </IconButton>
            </StyledButtonWrapper>

            {!hideDownloadButtons && (
              <StyledButtonWrapper>
                <IconButton
                  className="download-button"
                  data-testid={'btn_download_slide'}
                  onClick={handleDownloadSlide}
                  tooltip="Download"
                >
                  <DownloadIcon />
                </IconButton>
              </StyledButtonWrapper>
            )}
          </Stack>
        </Grid>
      </StyledContainer>

      {showDivider && <Divider />}
    </>
  );
};

export default SearchResultItem;
