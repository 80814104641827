import TagManager from 'react-gtm-module';
import { favicons } from '@clatter/platform';
import { cloneDeep } from 'lodash';
import * as Sentry from '@sentry/react';

export * from './images';
export * from './user';
export * from './files';
export * from './formatters';
export * from './cookies';
export * from './localStorage';
export * from './filters';
export * from './graph';
export * from './date';
export * from './slides';
export * from './presentation';
export * from './validators';
export * from './featureFlags';
export * from './reports';
export * from './roles';
export * from './sorters';
export * from './tools';
export * from './consts';
export * from './pages';
export * from './assetUsages';
export * from './metrics';
export * from './dataGrid';
export * from './topics';
export * from  './notices';
export * from  './error';
export * from './strapi.helpers';

export const isLocalhost = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return window.location.hostname === 'localhost';
};

export const updateFavicon = () => {
  if (favicons[process.env.NX_CLIENT]) {
    const linkElement = document.querySelector('link[rel=icon]');
    linkElement.href = favicons[process.env.NX_CLIENT];
  }
};

export const initTagManager = () => {
  const gtmId = process.env.NX_GTM_ID;

  if (!gtmId) {
    return false;
  }

  return TagManager.initialize({
    gtmId: gtmId,
  });
};

export const initSentry = () => {
  if (!process.env.NX_SENTRY_DSN) {
    return false;
  }

  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https:yourserver.io/api/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      const url = window?.location?.href || '';

      // let's check if the URL includes "localhost", if the event is from localhost, return null to ignore it
      if (url.includes('localhost')) {
        return null;
      }

      return event;
    },
  });
};

export const arrayMove = (array, from, to) => {
  const clonedArray = cloneDeep(array);
  const startIndex = from < 0 ? clonedArray.length + from : from;

  if (startIndex >= 0 && startIndex < clonedArray.length) {
    const endIndex = to < 0 ? clonedArray.length + to : to;

    const [item] = clonedArray.splice(from, 1);
    clonedArray.splice(endIndex, 0, item);
  }

  return clonedArray;
};
