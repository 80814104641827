import { useContext } from 'react';
import { AuthContext } from '@clatter/platform';

const useAuth0 = () => {
  const {
    isAuthenticated,
    isLoading,
    error,
    changePassword,
    checkSession,
    user,
    setImpersonatedUser,
    impersonate,
    login,
    loginWithAccessToken,
    loginWithSSO,
    logout,
  } = useContext(AuthContext);

  return {
    activeUser: impersonate || user,
    isAuthenticated: isAuthenticated,
    isLoading: isLoading,
    error: error,
    checkSession: checkSession,
    changePassword: changePassword,
    user: user,
    setImpersonatedUser,
    impersonate,
    login: login,
    loginWithAccessToken: loginWithAccessToken,
    loginWithSSO: loginWithSSO,
    logout: logout,
  };
};

export default useAuth0;
