import {
  API_UNAVAILABLE_STATUS_CODES,
  UNAUTHORIZED_STATUS_CODE,
} from './consts';

export const isApiUnavailableError = (err) =>
  err && err?.message && API_UNAVAILABLE_STATUS_CODES.includes(+err.message);

export const errorHandlingInterceptor = (error) => {
  if (error?.response?.status === UNAUTHORIZED_STATUS_CODE) {
    window.location = '/logout';
    return console.info('API returned 401, redirecting to /logout');
  }

  return Promise.reject(error);
};
