import {
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';
import presentationApi from '../api/presentation.api';

export const PRESENTATION_FEATURE_KEY = 'presentation';
export const presentationAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const setPresentationBuildStatus = createAction(
  'SET_PRESENTATION_BUILD_STATUS_SLIDES_IDS',
);

export const fetchUserPresentations = createAsyncThunk(
  `${PRESENTATION_FEATURE_KEY}/fetchUserPresentations`,
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await presentationApi.fetchUserPresentations({ userId });
      return Promise.resolve(response.data);
    } catch (error) {
      return rejectWithValue({
        ...error.response.data.error,
        status: error.response.status,
        error: true,
      });
    }
  },
);

export const createPresentation = createAsyncThunk(
  `${PRESENTATION_FEATURE_KEY}/createPresentation`,
  async ({ userId, presentationData }, { rejectWithValue }) => {
    try {
      const response = await presentationApi.createPresentation({
        userId,
        presentationData,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return rejectWithValue({
        ...error.response.data.error,
        status: error.response.status,
        error: true,
      });
    }
  },
);

export const updatePresentation = createAsyncThunk(
  `${PRESENTATION_FEATURE_KEY}/updatePresentation`,
  async ({ userId, presentationId, presentationData }, { rejectWithValue }) => {
    try {
      const response = await presentationApi.updatePresentation({
        userId,
        presentationId,
        presentationData,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      console.debug('=====>>', error.response.data.error )

      return rejectWithValue({
        ...error.response.data.error,
        status: error.response.status,
        error: true,
      });
    }
  },
);

export const deletePresentation = createAsyncThunk(
  `${PRESENTATION_FEATURE_KEY}/deletePresentation`,
  async ({ userId, presentationId }, { rejectWithValue }) => {
    try {
      return await presentationApi.deletePresentation({
        userId,
        presentationId,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const fetchUserPresentation = createAsyncThunk(
  `${PRESENTATION_FEATURE_KEY}/fetchPresentation`,
  async ({ presentationId, userId }, { rejectWithValue }) => {
    try {
      return await presentationApi.getPresentation({
        userId,
        presentationId,
        contentType: 'application/json',
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const initialPresentationState = presentationAdapter.getInitialState({
  current: null,
  loadingStatus: requestStatus.initial,
  buildId: null,
  isBuilding: false,
  error: null,
});

// normalize entity, getUserPresentations / getUserPresentation has different data format
// omit contentRepository property
const mapAiToStore = ({
  contentRepository,
  slides,
  createdBy,
  updatedBy,
  ...presentationData
}) => ({
  ...presentationData,
  contentRepositoryId:
    typeof contentRepository === 'string'
      ? contentRepository
      : contentRepository?._id,
  createdBy: typeof createdBy === 'string' ? createdBy : createdBy?._id,
  updatedBy: typeof updatedBy === 'string' ? updatedBy : updatedBy?._id,
  slides: slides.map((slide) => ({
    ...slide,
    slideId:
      typeof slide.slideId === 'string' ? slide.slideId : slide.slideId?._id,
  })),
});

export const presentationSlice = createSlice({
  name: PRESENTATION_FEATURE_KEY,
  initialState: initialPresentationState,
  reducers: {
    add: presentationAdapter.addOne,
    remove: presentationAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPresentationBuildStatus, (state, payload) => {
        state.isBuilding = payload;
      })

      .addCase(fetchUserPresentations.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchUserPresentations.fulfilled, (state, { payload }) => {
        presentationAdapter.setAll(state, payload.map(mapAiToStore));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchUserPresentations.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(createPresentation.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(createPresentation.fulfilled, (state, { payload }) => {
        state.current = payload;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createPresentation.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(updatePresentation.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updatePresentation.fulfilled, (state, { payload }) => {
        state.current = payload;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updatePresentation.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(deletePresentation.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deletePresentation.fulfilled, (state, { meta }) => {
        presentationAdapter.removeOne(state, meta.arg.presentationId);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deletePresentation.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })

      .addCase(fetchUserPresentation.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchUserPresentation.fulfilled, (state, { payload }) => {
        presentationAdapter.upsertOne(state, mapAiToStore(payload.data));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchUserPresentation.rejected, (state, { error, payload}) => {
        state.loadingStatus = requestStatus.error;
        state.error = payload?.error?.message || error.message;
      });
  },
});

export const presentationReducer = presentationSlice.reducer;
export const presentationActions = presentationSlice.actions;

const { selectAll, selectEntities, selectById } =
  presentationAdapter.getSelectors();

export const getPresentationState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][PRESENTATION_FEATURE_KEY];

export const selectPresentationById = (presentationId) =>
  createSelector(getPresentationState, (state) =>
    selectById(state, presentationId),
  );

export const presentationConfigSelectors = {
  selectAllPresentations: createSelector(getPresentationState, selectAll),
  selectPresentationsEntities: createSelector(
    getPresentationState,
    selectEntities,
  ),
  selectLoadingStatus: createSelector(
    getPresentationState,
    (state) => state.loadingStatus,
  ),
  selectPresentationById: selectPresentationById,
};
