export const mapUserStoreToPatch = (user) => {
  const data = {
    email_access: false,
    first_name: user.first_name.trim(),
    last_name: user.last_name.trim(),
    active: user.active,
    blocked: user.blocked,
    content_repositories: user.content_repositories?.map(({ _id }) => _id),
    roles: user.roles.map(({ id }) => id),
    custom_attributes: user.customAttributes,
  };
  // auth0 does not allow to update password and email on the one request
  if (user.email) {
    data.email = user.email;
  }

  if (user?.password?.trim()) {
    data.password = user.password.trim();
  }
  return data;
};

export const mapUserStoreToCreate = (user) => ({
  email: user.email,
  email_access: user.email_access,
  first_name: user.first_name?.trim(),
  last_name: user.last_name?.trim(),
  password: user.password,
  content_repositories: user.content_repositories?.map(({ _id }) => _id),
  roles: user.roles.map(({ id }) => id),
  custom_attributes: user.customAttributes,
});
