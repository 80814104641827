import { useEffect, useRef } from 'react';

export const useEffectOnce = (effect) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      effect();
    }
  }, [mounted?.current]);
};
