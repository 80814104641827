import { getAxiosApiV2WithAuthorization } from '../services';

const rolesApi = {
  fetchRoles: () =>
    getAxiosApiV2WithAuthorization({
      url: `/role`,
      method: 'get',
    }),
};

export default rolesApi;
