import { getAxiosWithAuthorization } from '@clatter/platform';

const presentationApi = {
  createPresentation: ({ userId, presentationData }) => {
    return getAxiosWithAuthorization({
      url: `/${userId}/presentations`,
      method: 'POST',
      data: presentationData,
    });
  },

  // empty data object is required so axios would not remove content-type header in the case of GET request
  getPresentation: ({ userId, presentationId, contentType }) => {
    return getAxiosWithAuthorization({
      url: `/${userId}/presentations/${presentationId}`,
      method: 'GET',
      headers: {
        'Content-Type': contentType ?? 'application/ppt',
      },
      data: {},
    });
  },

  updatePresentation: ({ userId, presentationId, presentationData }) => {
    return getAxiosWithAuthorization({
      url: `/${userId}/presentations/${presentationId}`,
      method: 'PATCH',
      data: presentationData,
    });
  },

  getBuildStatus: ({ buildId }) => {
    return getAxiosWithAuthorization({
      url: `/build-requests/${buildId}`,
      method: 'GET',
    });
  },

  fetchUserPresentations: ({ userId }) => {
    return getAxiosWithAuthorization({
      url: `/${userId}/presentations`,
      method: 'GET',
    });
  },

  deletePresentation: ({ userId, presentationId }) => {
    return getAxiosWithAuthorization({
      url: `${userId}/presentations/${presentationId}`,
      method: 'DELETE',
    });
  },

  sharePresentation: ({ data }) => {
    return getAxiosWithAuthorization({
      url: `/emails`,
      method: 'POST',
      data: data,
    });
  },

  clonePresentation: ({ userId, presentationId }) =>
    getAxiosWithAuthorization({
      url: `${userId}/presentations/${presentationId}`,
      method: 'POST',
    }),
};

export default presentationApi;
