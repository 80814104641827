import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appGlobalStyles, AppThemeProvider, SsoCallbackPage, TrackPageviewEvent } from '@clatter/ui';
import {
  Analytics,
  fetchToolsConfig,
  fetchToolInfo,
  useAuth,
  fetchFeatureFlag,
  fetchSystemProfile,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import { LogoutPageView, sharedRoutes } from '@clatter/platform';
import routes from './routes';
import LandingPageView from './pages/LandingPage/LandingPageView';
import HomePageView from './pages/Home/HomePageView';

const App = () => {
  const GlobalStyle = createGlobalStyle`
    ${appGlobalStyles};

    // Apply global scrollbar styles if there is no .landing-page
    body:not(.landing-page) ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  `;

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <Router>
        <TrackPageviewEvent />
        <Analytics />

        <Switch>
          <Route
            exact
            path={routes.index}
            component={isAuthenticated ? HomePageView : LandingPageView}
          />
          <Route exact path={sharedRoutes.ssoCallback} component={SsoCallbackPage} />
          <Route exact path={routes.logout} component={LogoutPageView} />
          <Route render={() => <Redirect to={routes.index} />} />
        </Switch>
      </Router>
    </AppThemeProvider>
  );
};
export default App;
