import { useEffect, useState } from 'react';

const useSortModel = (dataTableName, defaultSortField) => {
  const [sortSettings, setSortSettings] = useState({});

  useEffect(() => {
    // get last sorted field for a given
    // data table from the local storage
    const savedSortSettings = JSON.parse(
      window.localStorage.getItem('sortSettings') || null,
    ) || {};

    // set last sorted field
    setSortSettings(
      savedSortSettings?.[dataTableName]
        ? savedSortSettings
        : {
            ...savedSortSettings,
            [dataTableName]: {
              field: defaultSortField?.field || 'created_at',
              sort: defaultSortField?.sort || 'desc',
            },
          },
    );
  }, [dataTableName]);

  const handleSortModelChange = ([nextSortModel]) => {
    const nextSortSettings = {
      ...sortSettings,
    };

    if (nextSortModel) {
      nextSortSettings[dataTableName] = nextSortModel;
    } else {
      delete nextSortSettings[dataTableName];
    }

    window.localStorage.setItem(
      'sortSettings',
      JSON.stringify(nextSortSettings),
    );
    setSortSettings(nextSortSettings);
  };

  return {
    sortModel: sortSettings[dataTableName],
    handleSortModelChange,
  };
};

export default useSortModel;
