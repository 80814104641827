import { getAxiosWithAuthorization } from '@clatter/platform';

const contentCategoryApi = {
  getContentCategories: ({ contentRepositoryId, excludeSlides = false }) =>
    getAxiosWithAuthorization({
      url: `/content-repositories/${contentRepositoryId}/content-categories${excludeSlides ? '?excludeContentSlides=true' : ''}`,
      method: 'GET',
    }),
};

export default contentCategoryApi;
