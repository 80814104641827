import { AxiosRequestConfig } from 'axios';
import { errorHandlingInterceptor } from '../helpers/error';
import { getAxiosClientWithRefreshToken } from './axiosWithRefreshToken';

const client = getAxiosClientWithRefreshToken({ baseURL: `https://${process.env.NX_AUTH0_DOMAIN}/api/v2` });

client.interceptors.response.use(
  (response) => response,
  errorHandlingInterceptor,
);

/**
 * Axios instance that uses auth0 access token
 *
 * @param {AxiosRequestConfig} requestConfigOptions
 * @returns {Promise}
 */
const getAuth0AxiosApiWithAuthorization = (requestConfigOptions) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return client(requestConfigOptions).then(onSuccess);
};

export default getAuth0AxiosApiWithAuthorization;
