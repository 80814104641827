import React, { useEffect } from 'react';
import { Loader } from '@clatter/ui';
import {
  useAuth,
  ssoApi,
  useGetActiveTool,
  isLocalhost,
  trackEvent,
  eventRequestTypes,
} from '@clatter/platform';
import { useDispatch } from 'react-redux';
import { getAuth0UserId } from 'libs/platform/src/context/auth/AuthReducer';

const SsoCallbackPageView = () => {
  const { checkSession, logout } = useAuth();
  const reduxDispatch = useDispatch();
  const activeTool = useGetActiveTool();

  const trackSSOLogin = (authResult) => {
    if (isLocalhost()) {
      return false;
    }

    return reduxDispatch(
      trackEvent({
        type: eventRequestTypes.userLogin,
        data: {
          tool: activeTool?.slug?.toUpperCase()
            || (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
          user_id: getAuth0UserId(authResult.idTokenPayload.sub),
          user_name: authResult.idTokenPayload.name,
          user_email: authResult.idTokenPayload.email,
          connection: process.env.NX_SSO_AUTH0_CONNECTION_NAME
        },
      }),
    );
  }

  useEffect(() => {
    // let's start by getting currently logged-in user
    checkSession(async (error, authResult) => {
      // check if something went wrong
      if (error) {
        // @todo: log error to Sentry?
        console.error('error occurred during SSO callback @ checkSession');

        return logout({
          returnTo: `${window.location.origin}?sso_error`,
        });
      }

      // check if provided profile includes "auth0|" in user_id
      // if not, we need to call our api v2 to link user's accounts
      if (!authResult.idTokenPayload.sub.includes('auth0|')) {
        // call api v2 to link user's accounts
        try {
          await ssoApi.callback({
            email: authResult.idTokenPayload.email,
            sso_user_id: authResult.idTokenPayload.sub,
          });
        } catch (error) {
          // track unauthorized user access attempt
          reduxDispatch(
            trackEvent({
              type: eventRequestTypes.unauthorizedUserAccessAttempt,
              data: {
                tool: activeTool?.slug?.toUpperCase()
                  || (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
                user_id: getAuth0UserId(authResult.idTokenPayload.sub),
                user_name: authResult.idTokenPayload.name,
                user_email: authResult.idTokenPayload.email,
                connection: process.env.NX_SSO_AUTH0_CONNECTION_NAME,
              },
            }),
          );

          return logout({
            returnTo: `${window.location.origin}?sso_error`,
          });
        }

        // call Auth0 to refresh token on their end
        await checkSession();
        await trackSSOLogin(authResult);

        // finally, refresh our page to use new token
        return window.location = location.origin;
      }

      await trackSSOLogin(authResult);

      // finally, refresh our page to use new token
      return window.location = location.origin;
    });
  }, []);

  return <Loader />;
};

export default SsoCallbackPageView;
