import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabsContent, Typography } from '@clatter/ui';
import {
  ContentSlidesDialog,
  featureFlagsMap,
  getSlides,
  platformSelectors,
  updateFilteredSlides,
  useDeepCompareMemo,
  useHasActiveFeatureFlag,
  contentSlideDialogIdsMap,
  setContentSlideDialogModalOpened,
  useMountedEffect,
  useDeepCompareEffect,
  useContentSlideSearch,
} from '@clatter/platform';
import HierarchyView from './components/HierarchyView';
import SearchInput from './components/SearchInput';
import { CardContent } from '@mui/material';
import SearchResultItem from './components/SearchResultItem';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

const StyledHierarchyViewContainer = styled.div`
  padding: 0 15px;
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  width: 50%;
`;

const SlideSelector = ({
  onSlidesChange,
  onTabChange,
  themeId,
  tabs,
  renderExtraTabs,
  removeAllButtonText,
  error,
  noSlidesMessage,
  contentSlideDialogData = {},
  starterDeckSlidesIds = [],
  selectedContentSlidesIds = [],
  showOnlyStarterSlides = false,
  hideDownloadButtonsFF = featureFlagsMap.PG
    .hideDownloadSlideDownloadDeckButtons,
}) => {
  const dispatch = useDispatch();
  const { filteredByQuery, searchSortOrder } = useContentSlideSearch();
  const [activeTab, setActiveTab] = useState(0);
  const [filteredSlidesIds, setFilteredSlidesIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredContentSlides = useSelector(
    platformSelectors.contentSlides.selectContentSlidesByFilters,
  );

  const contentSlidesGroups = useSelector(
    ({ platform }) => platform.contentSlides.groups,
  );

  const hideDownloadButtons = useHasActiveFeatureFlag(hideDownloadButtonsFF);

  const contentSlidesByIds = useSelector((state) =>
    platformSelectors.contentSlides.selectContentSlidesByIds(
      state,
      showOnlyStarterSlides ? starterDeckSlidesIds : filteredSlidesIds,
    ),
  );

  const totalSlidesCount = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.slides.length,
  );

  let slidesHierarchy = showOnlyStarterSlides
    ? cloneDeep(filteredContentSlides)
    : filteredContentSlides;

  if (showOnlyStarterSlides) {
    // this method is directly modifying "slidesHierarchy" prop
    updateFilteredSlides(slidesHierarchy, contentSlidesByIds);
  }

  const visibleSlidesCount = useDeepCompareMemo(() => {
    return getSlides(slidesHierarchy).filter((s) => !s.hidden).length;
  }, [slidesHierarchy]);

  // if "showOnlyStarterSlides" is true and user is on "search" tab
  // we need to hide it so we'll go back to "view by topic" tab.
  useMountedEffect(() => {
    if (showOnlyStarterSlides && activeTab !== 0) {
      setActiveTab(0);
    }
  }, [activeTab, showOnlyStarterSlides]);

  useMountedEffect(() => {
    if (typeof onTabChange === 'function') {
      onTabChange(activeTab);
    }
    setSearchQuery('');
  }, [activeTab]);

  useDeepCompareEffect(() => {
    let mounted = true

    if(mounted) {
      if (searchQuery && searchSortOrder) {
        const filteredIds = filteredByQuery(filteredContentSlides, searchQuery, searchSortOrder);
        setFilteredSlidesIds(filteredIds);
      } else {
        setFilteredSlidesIds([]);
      }
    }

    return () => {
      mounted = false;
    }

  }, [searchQuery, searchSortOrder]);

  //region Content Slide Dialog
  // In order to properly update dialog
  // this state need to be kept outside "ContentSlidesDialog"
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const contentSlidesHierarchyIds = useSelector(
    platformSelectors.contentSlides.selectContentSlidesHierarchyIds,
  );

  const handleOpenModal = (slideData) => {
    const index = contentSlidesHierarchyIds.findIndex(
      (slideId) => slideId === slideData?._id,
    );
    if (index !== -1) {
      setCurrentSlideIndex(index);
      dispatch(
        setContentSlideDialogModalOpened(
          contentSlideDialogIdsMap.SLIDE_SELECTOR,
        ),
      );
    }
  };
  //endregion

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  return (
    <>
      <ContentSlidesDialog
        dialogId={contentSlideDialogIdsMap.SLIDE_SELECTOR}
        coverId={contentSlideDialogData.coverId}
        includeCover={contentSlideDialogData.includeCover}
        selectedContentThemeId={contentSlideDialogData.themeId}
        selectedContentRepositoryId={
          contentSlideDialogData.selectedContentRepositoryId
        }
        selectedContentSlidesIds={contentSlideDialogData.selectedSlidesIds}
        hideDownloadButtonsFF={hideDownloadButtonsFF}
        selectedSlidesData={contentSlideDialogData.selectedSlidesData}
        onChange={(data) => {
          onSlidesChange(data?.map((slideData) => slideData?.id));
        }}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />

      <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange}>
        <TabsContent testId="view-by-topic-tab-content">
          {error && (
            <Typography
              variant="body1"
              style={{ textAlign: 'center', color: '#f44336' }}
            >
              {error}
            </Typography>
          )}
          <CenteredText>{`showing ${visibleSlidesCount} of ${totalSlidesCount} slides`}</CenteredText>
          <StyledHierarchyViewContainer>
            <HierarchyView
              onSlideLabelClick={handleOpenModal}
              selectedSlides={selectedContentSlidesIds}
              libraryByTopicList={slidesHierarchy}
              onSelectSlides={onSlidesChange}
              onDeselectSlides={onSlidesChange}
              themeId={themeId}
              contentSlidesGroups={contentSlidesGroups}
              removeAllButtonText={removeAllButtonText}
              noSlidesMessage={noSlidesMessage}
            />
          </StyledHierarchyViewContainer>
        </TabsContent>

        {tabs[1] && (
          <TabsContent testId="view-by-search-tab-content">
            <CardContent>
              <SearchInput onSearch={setSearchQuery} />

              {searchQuery && !contentSlidesByIds.length > 0 && (
                <p>Sorry, no slides found for given query...</p>
              )}

              {contentSlidesByIds.map((slide, index) => (
                <SearchResultItem
                  key={slide._id}
                  slide={slide}
                  showDivider={index !== contentSlidesByIds.length - 1}
                  onSelectSlides={onSlidesChange}
                  onDeselectSlides={onSlidesChange}
                  selectedContentSlidesIds={selectedContentSlidesIds}
                  selectedContentThemeId={themeId}
                  onSlideLabelClick={handleOpenModal}
                  hideDownloadButtons={hideDownloadButtons}
                  contentSlidesGroups={contentSlidesGroups}
                />
              ))}
            </CardContent>
          </TabsContent>
        )}

        {renderExtraTabs &&
          renderExtraTabs({
            selectedContentSlidesIds,
          })}
      </Tabs>
    </>
  );
};

SlideSelector.propTypes = {
  onDeselectSlides: PropTypes.func,
  onSelectSlides: PropTypes.func,
};

export default SlideSelector;
