import styled, { css } from 'styled-components';

const StyledCheckbox = styled.label`
  ${({ withLabel }) =>
    withLabel &&
    css`
      display: flex;
      align-items: start;
    `}
  min-height: 24px;
  line-height: 24px;

  .checkbox-text {
    margin-left: 8px;
  }

  input {
    margin-top: 8px;
    &:hover {
      cursor: pointer;
    }
  }

  :hover {
    cursor: pointer;
  }
`;

export default StyledCheckbox;
