import styled from 'styled-components';

const Label = styled.label`
  line-height: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export default Label;
