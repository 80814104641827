import { getAxiosApiV2WithAuthorization } from '../services';

const eventsApi = {
  track: (data) =>
    getAxiosApiV2WithAuthorization({
      url: '/events',
      method: 'post',
      data: data,
    }),
  trackPublic: (data) =>
    getAxiosApiV2WithAuthorization({
      url: '/events-public',
      method: 'post',
      data: data,
    }),
  getEvents: (params) =>
    getAxiosApiV2WithAuthorization({
      url: `/events`,
      params: params,
    }),
};

export default eventsApi;
