import { dfs, systemConfigurationApi, useEffectOnce } from '@clatter/platform';
import { useCallback, useState } from 'react';

// fallback if there wouldn't be deployed
// new model on the env...
const mapSearchKeyToProperty = {
  Title: 'title',
  Body: 'body',
  'Speaker Notes': 'speakerNotes',
  'Meta Description': 'metaDescription',
};

const defaultSearchSortOrder = ['title', 'body', 'metaDescription', 'speakerNotes'];

const mapSearchPriorityToSortOrder = (searchPriorityPayload) =>
  (searchPriorityPayload || [])
    .sort((a, b) => a.order - b.order)
    .map((item) => item?.searchField || mapSearchKeyToProperty[item?.searchKey]);

export const useContentSlideSearch = () => {
  const [searchSortOrder, setSearchSortOrder] = useState(defaultSearchSortOrder);

  const filteredByQuery = useCallback((hierarchy, searchQuery, searchOrder = defaultSearchSortOrder) => {
    // dynamically generate results map based on 'slideSearchableFields'
    // this map will hold results for each searchable field
    const resultCategories = {};
    searchOrder.forEach((property) => {
      resultCategories[property] = [];
    });

    const queryRegex = new RegExp(`\\b${searchQuery}\\b`, 'im');

    const passFilter = (item) => {
      if (item.label === 'slide' && !item.hidden) {
        searchOrder.forEach((property) => {
          if(property === 'metaDescription') {
            if (queryRegex.test(item?.description) || queryRegex.test(item?.author)) {
              resultCategories[property].push(item._id);
            }
          } else {
            if (queryRegex.test(item?.[property])) {
              resultCategories[property].push(item._id);
            }
          }
        });
      }
    };

    // perform filter on the hierarchy tree
    hierarchy.forEach((t) => {
      dfs(t, passFilter);
    });

    // order results by provided field order
    const orderedResults = searchOrder.flatMap((property) => resultCategories[property]);

    // remove duplicates
    return [...new Set(orderedResults)];
  }, []);

  // fetch search priorities
  useEffectOnce(() => {
    (async () => {
      let mounted = true;

      if (mounted) {
        try {
          const response = await systemConfigurationApi.getSearchPriorities();
          const mappedResponse = mapSearchPriorityToSortOrder(response?.data || []);
          setSearchSortOrder(mappedResponse || defaultSearchSortOrder);
        } catch (err) {
          console.error('Error fetching search priorities');
        }
      }

      return () => {
        mounted = false;
      };
    })();
  });

  return {
    filteredByQuery,
    searchSortOrder,
  };
};
