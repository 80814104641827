import {
  TOOL_ADMIN_SLUG,
  TOOL_PGA_SLUG,
  TOOL_SL_SLUG,
  TOOL_MSM_SLUG,
  TOOL_OE_SLUG,
  TOOL_OEKIT_SLUG,
  TOOL_CM_SLUG,
  TOOL_PG_SLUG,
  TOOL_SM_SLUG,
  TOOL_QC_SLUG,
} from './tools';

export const userRolesMap = {
  systemAdmin: 'System-Admin',
  clatterAdmin: 'Clatter-Admin',
  pgSystem: 'PG-System-Admin',
  pgUsers: 'PG-Users',
  slAdmin: 'SL-Admin',
  slUser: 'SL-User',
  smAdmin: 'Summary Maker Admin',
  smUser: 'Summary Maker',
  qcAdmin: 'QC-Admin',
  qcUser: 'QC-User',
  oeAdmin: 'OE-Admin',
  oeUser: 'OE-User',
  oekitAdmin: 'OEKIT-Admin',
  oekitUser: 'OEKIT-User',
  cmAdmin: 'CM-Admin',
  cmUser: 'CM-User',
  msmAdmin: 'MSM-Admin',
  msmContentAdmin: 'MSM-ContentAdmin',
  msmUser: 'MSM-User',
};

export const userFriendlyRolesMap = {
  [userRolesMap.clatterAdmin]: 'Clatter - System Admin',
  [userRolesMap.pgSystem]: 'Presentation Maker - Admin',
  [userRolesMap.pgUsers]: 'Presentation Maker - User',
  [userRolesMap.slAdmin]: 'Slide Library - Admin',
  [userRolesMap.slUser]: 'Slide Library - User',
  [userRolesMap.smAdmin]: 'Summary Maker - Admin',
  [userRolesMap.smUser]: 'Summary Maker - User',
  [userRolesMap.qcAdmin]: 'Quick Converter - Admin',
  [userRolesMap.qcUser]: 'Quick Converter - User',
  [userRolesMap.oeAdmin]: 'Open Enrollment - Admin',
  [userRolesMap.oeUser]: 'Open Enrollment - User',
  [userRolesMap.oekitAdmin]: 'OEKit - Admin',
  [userRolesMap.oekitUser]: 'OEKit - User',
  [userRolesMap.cmAdmin]: 'Collateral Maker - Admin',
  [userRolesMap.cmUser]: 'Collateral Maker - User',
  [userRolesMap.msmAdmin]: 'Microsite Maker - Admin',
  [userRolesMap.msmContentAdmin]: 'Microsite Maker - Content Admin',
  [userRolesMap.msmUser]: 'Microsite Maker - User',
};

export const rolesFormsMap = {
  pgUser: 'pg_user_form',
  slUser: 'sl_user_form',
};

export const toolsOrder = [
  TOOL_ADMIN_SLUG,
  TOOL_PG_SLUG,
  TOOL_PGA_SLUG,
  TOOL_SL_SLUG,
  TOOL_SM_SLUG,
  TOOL_QC_SLUG,
  TOOL_OE_SLUG,
  TOOL_OEKIT_SLUG,
  TOOL_CM_SLUG,
  TOOL_MSM_SLUG,
];

export const getFormDataForAccessRole = (roleName) => {
  switch (roleName) {
    case userRolesMap.pgUsers:
      return {
        label: userFriendlyRolesMap[userRolesMap.pgUsers],
        formName: rolesFormsMap.pgUser,
      };
    case userRolesMap.slUser:
      return {
        label: userFriendlyRolesMap[userRolesMap.slUser],
        formName: rolesFormsMap.slUser,
      };
    default:
      return false;
  }
};

export const getUserRoles = ({ userApiRoles, appRoles }) =>
  (userApiRoles || []).reduce((acc, apiRole) => {
    appRoles.forEach((tool) => {
      if (tool.id === apiRole.id) {
        acc.push(tool);
      }
    });

    return acc;
  }, []);
