import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loader, Notice } from '@clatter/ui';
import { cookieService, platformSelectors } from '@clatter/platform';
import { LoginScreens } from './components/LoginScreens';
import routes from '../../routes';

const StyledLandingPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .landing-page-box {
    background-color: ${({ theme }) => theme?.landingPage?.pageBox?.backgroundColor};
    height: 100vh;
    max-height: 650px;
    overflow: auto;

    .image {
      min-height: 150px;
      height: 100%;
      grid-area: image;
      background-repeat: no-repeat;
      background-size: 160%;
    }

    .details {
      grid-area: details;
      height: 100%;
      padding: 3em 1em;
      display: flex;
      flex-direction: column;
      gap: 3vh;

      h1 {
        font-size: 1.4em;
        font-weight: 700;
        color: ${({ theme }) => theme?.landingPage?.headerColor};
        padding: 2vh 0;
        max-width: 350px;
        margin-bottom: 1em;
      }

      p {
        padding: 16px 0;
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme?.landingPage?.textColor};
        margin: 0 !important;
      }

      a {
        color: ${({ theme }) => theme?.palette?.textLinkColor};
        text-decoration: underline;

        &:hover {
          opacity: 0.6;
        }
      }

      .logo {
        width: auto;
        max-width: 90%;
        height: auto;
        margin: 0 !important;
      }
    }
  }

  @media (min-width: 310px) {
    .landing-page-box {
      .image {
        min-height: 200px;
        background-size: 120%;
      }

      .details {
        padding: 3em 2em;
      }
    }
  }

  @media (min-width: 400px) {
    .landing-page-box {
      .image {
        min-height: 200px;
        background-size: cover;
      }

      .details {
        .logo {
          max-width: 80%;
          margin-bottom: 1em !important;
        }
      }
    }
  }

  @media (min-width: 760px) {
    .landing-page-box {
      display: grid;
      grid-template-areas: 'image details';
      grid-template-columns: 5fr 4fr;

      .image {
        background-position: -60px 0px;
      }

      .details {
        padding: 2.5em 3em 3em;

        .logo {
          max-width: 90%;
        }

        .form-container {
          min-height: auto;
          max-width: 87%;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .landing-page-box {
      width: 100%;
      max-width: ${({ theme }) => theme?.landingPage?.pageBox?.maxWidth};

      .image {
        background-position: 0px 0px;
      }

      .details {
        .logo {
          max-width: 320px;
          margin-bottom: 1em !important;
        }

        h1 {
          font-size: 1.8em;
          margin-bottom: 0;
        }
      }
    }
  }
`;

const LandingPageView = () => {
  const toolInfo = useSelector(platformSelectors.toolInfo.selectToolInfo);
  const logoUrl = toolInfo?.data?.companyLogo?.location;

  // if auth0 cookie is set, disable LandingPage
  if (cookieService.get('auth_token')) {
    return <Loader />;
  }

  return (
    <StyledLandingPage className="landing-page">
      {navigator.cookieEnabled ? (
        <div className="landing-page-box">
          <div
            className="image"
            style={{
              backgroundImage: `url(${toolInfo?.data?.companyHeroImage?.location})`,
            }}
          />
          <div className="details">
            <div>
              {logoUrl && <img className="logo" src={logoUrl} alt="logo" />}
              <h1>{toolInfo?.data?.description}</h1>
            </div>
            <LoginScreens successfulLoginRedirectUrl={routes.index} supportEmail={toolInfo?.data?.supportEmail} />
          </div>
        </div>
      ) : (
        <Notice
          type="warning"
          title="Cookies are disabled"
          message="Clatter requires cookies to be enabled. Please allow cookies and refresh this page."
        />
      )}
    </StyledLandingPage>
  );
};

export default LandingPageView;
