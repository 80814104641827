import { Card } from '../Card';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  padding: 35px 10px;
  min-height: ${({ minHeight }) => minHeight || 'inherit'};
`;

const TabsContent = ({ children, testId, minHeight }) => (
  <StyledCard data-testid={testId} minHeight={minHeight}>
    {children}
  </StyledCard>
);

export default TabsContent;
