import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNoticeByTypeAndMessage,
  noticesTypes,
  platformActions,
  platformSelectors,
  setNoticeContextKey,
} from '@clatter/platform';

const isEqual = (prev, next) => prev.length === next.length;

const getNoticeTitleByType = (type) => {
  switch (type) {
    case noticesTypes.ERROR:
      return 'Error'
    case noticesTypes.SUCCESS:
      return 'Success'
    case noticesTypes.WARNING:
      return 'Warning'
    case noticesTypes.INFO:
      return 'Info'
    default:
      return 'Info'
  }
}

export default function useNotices() {
  const location = useLocation();
  const dispatch = useDispatch();
  const notices = useSelector(
    platformSelectors.notices.selectAllNotices,
    isEqual,
  );

  const noticesContextKey = useSelector(
    (state) => state.platform.notices.contextKey,
  );

  // remove success notifications on location change
  useEffect(() => {
    removeNoticesByType('success');
  }, [location.pathname]);

  useEffect(() => {
    if (!noticesContextKey || noticesContextKey !== location?.pathname) {
      dispatch(setNoticeContextKey(location?.pathname));
    }
  }, [location?.pathname, noticesContextKey, dispatch]);

  const addCustomNotice = useCallback(
    ({ message, type, title }) =>
      dispatch(
        addNoticeByTypeAndMessage({
          id: +new Date(),
          message: message,
          type: type || 'info',
          title: title ? title : getNoticeTitleByType(type),
        }),
      ),
    [dispatch],
  );

  const removeNotice = useCallback(
    (id) => dispatch(platformActions.notices.remove(id)),
    [dispatch],
  );

  const removeNoticesByType = (type) => {
    const ids = notices.reduce((toRemove, notice) => {
      if (notice.type === type) {
        toRemove.push(notice.id);
      }
      return toRemove;
    }, []);

    return dispatch(platformActions.notices.removeMany(ids));
  };

  return {
    addNotice: addCustomNotice,
    removeNotice: removeNotice,
    removeNoticesByType: removeNoticesByType,
    notices: notices,
  };
}
