import React from 'react';
import styled, { css } from 'styled-components';
import Label from './Label';

export const StyledRadioGroup = styled.div`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;

      ${Label} {
        margin-right: 16px;
      }
    `}

  input[type='radio'] {
    padding: 0;
    margin: 4px 8px 0 0;
    align-self: flex-start;

    + span {
      margin: 0 24px 11px 0;
      line-height: 1.5em;
    }

    &:disabled + span {
      opacity: 0.6;
    }
  }
`;

const RadioGroup = React.forwardRef(
  (
    {
      disabled = false,
      inline = false,
      options = [],
      labelProperty = 'label',
      valueProperty = 'value',
      ...registerProps
    },
    ref,
  ) => (
    <StyledRadioGroup inline={inline}>
      {options.map((option) => (
        <Label key={option[valueProperty]}>
          <input
            disabled={disabled}
            ref={ref}
            type="radio"
            value={option[valueProperty]}
            {...registerProps}
          />
          <span>{option[labelProperty]}</span>
        </Label>
      ))}
    </StyledRadioGroup>
  ),
);

export default RadioGroup;
