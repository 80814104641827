import { format, isValid, parseISO } from 'date-fns';

export const isValidDate = (dateString) => {
  const isValidDate = isValid(parseISO(dateString))

  if (!isValidDate) {
    // make sure sentry will get the error message
    console.error('Invalid date format!', dateString);
  }

  return isValidDate
};

export const formatDate = (dateString, custom) => {
  if (!dateString || !isValidDate(dateString)) {
    return null;
  }

  try {
    return format(parseISO(dateString), custom ? custom : 'M/d/yyyy');
  } catch (error) {
    // make sure sentry will get the error message
    console.error('formatDate:: Invalid date format!', error?.message);
    return null
  }

};

export const formatDateTime = (dateString) => {
  if (!dateString || !isValidDate(dateString)) {
    return null;
  }

  try {
    return format(parseISO(dateString), 'M/d/yyyy h:mmaaa');
  } catch (error) {
    // make sure sentry will get the error message
    console.error('formatDateTime:: Error parsing date!', error?.message);
    return null
  }
};

export const formatDateColumnHeader = (dateString) => {
  if (!dateString || !isValidDate(dateString)) {
    return null;
  }

  try {
    return format(parseISO(dateString), 'MM/yyyy');
  } catch (error) {
    // make sure sentry will get the error message
    console.error('formatDateColumnHeader:: Error parsing date!', error?.message);
    return null
  }
};
