import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { gridFilterModelSelector } from '@mui/x-data-grid-premium';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useDeepCompareMemo } from '@clatter/platform';

// NOTE: Ideally, this should come from the theme, but it's a bit messy right now, so I'm keeping it here for now.
const DATA_GRID_TOOLBAR_BASE_COLOR = '#494949';
const DATA_GRID_TOOLBAR_HOVER_COLOR = '#f1f1f1';
const DATA_GRID_TOOLBAR_ACCENT_COLOR = '#1e90ff';

export const DataGridCustomToolbar = ({ renderCustomToolbar, customToolbarFromProps: CustomToolbarFromProps, apiRef }) => {

  // NOTE: migration - need to address this when migrating to the newest version
  const filtersApplied = useDeepCompareMemo(
    () => gridFilterModelSelector(apiRef)?.items?.length > 0,
    [gridFilterModelSelector(apiRef)],
  );

  const clearFilters = () => {
    apiRef.current.setFilterModel({ items: [] });
  };

  return (
    <div className="datagrid-custom-toolbar">
      <GridToolbarContainer
        style={{
          justifyContent: 'space-between',
        }}
        sx={{
          '.MuiSvgIcon-root': {
            color: DATA_GRID_TOOLBAR_BASE_COLOR,
          },
          '.MuiButtonBase-root': {
            color: DATA_GRID_TOOLBAR_BASE_COLOR,
          },
          '.MuiBadge-root': {
            '& .MuiBadge-badge': {
              backgroundColor: DATA_GRID_TOOLBAR_ACCENT_COLOR,
            },
          },
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />

          {filtersApplied && (
            <Button
              color="primary"
              startIcon={<CloseIcon />}
              variant="outlined"
              sx={{
                marginLeft: '8px',
                borderColor: DATA_GRID_TOOLBAR_BASE_COLOR,
                '&:hover': {
                  borderColor: DATA_GRID_TOOLBAR_BASE_COLOR,
                  backgroundColor: DATA_GRID_TOOLBAR_HOVER_COLOR,
                },
              }}
              onClick={() => clearFilters()}
            >
              Clear Filters
            </Button>
          )}
        </div>

        {CustomToolbarFromProps && <CustomToolbarFromProps />}
        {renderCustomToolbar && renderCustomToolbar()}
      </GridToolbarContainer>
    </div>
  );
};
