import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';

export const ROUTES_STATE_FEATURE_KEY = 'routesState';

export const initialRoutesStateState = {};

export const routesStateSlice = createSlice({
  name: ROUTES_STATE_FEATURE_KEY,
  initialState: initialRoutesStateState,
  reducers: {
    set: (state, { payload }) => {
      state[payload.routeKey] = { ...state[payload.routeKey], ...payload.data };
    },
  },
});

export const getRoutesState = (rootState) => rootState[PLATFORM_FEATURE_KEY][ROUTES_STATE_FEATURE_KEY];

export const routesStateSelectors = {
  selectRouteStateByKey: ({ toolSlug, routeKey }) =>
    createSelector(getRoutesState, (state) => state?.[routeKey]),
};
