import React from 'react';
import { getThumbnailWidthByZoomValue } from '@clatter/platform';
import placeholderImage from './placeholderImage.png';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledSlideThumbnailWrapper = styled.div`
  width: ${({ width }) => width};

  &.full-width {
    img {
      height: 200px;
      object-fit: contain;
    }
  }
`;

const StyledThumbnailContainer = styled.div`
  position: relative;
  transition: all 0.2s ease-in-out;
`;

const StyledSlideFooter = styled.div`
  padding: 0 8px 5px 8px;
`;

const StyledSlideTitle = styled.p`
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 0;
`;

const StyledActionButtonsWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  justify-content: flex-end;
`;

const StyledThumbnailContent = styled.div`
  background-color: #fff;

  &.full-width {
    background-color: transparent;
  }

  &:hover {
    > div:first-child {
      transform: scale(0.93);
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.3s ease-in-out;
    box-shadow: ${({theme}) => theme.slideSortThumbnail.hoverShadow};
  }

  &.disabled {
    img {
      cursor: not-allowed;
    }

    > div:first-child {
      transform: none;
    }

    box-shadow: none;
  }
`
const StyledMetadata = styled.div`
  margin-top: 10px;
`

export const AssetUsageThumbnail = ({
  title,
  metadata,
  thumbnail,
  zoomValue = 20,
  disabled = false,
  actionButtons = [],
  fullWidth,
}) => (
  <StyledSlideThumbnailWrapper
    className={classNames({ disabled: disabled, 'full-width': fullWidth  })}
    width={fullWidth ? '100%' : getThumbnailWidthByZoomValue(zoomValue, 20, 7)}
  >
    <StyledThumbnailContent className={classNames({ 'full-width': fullWidth  })}>
      <StyledThumbnailContainer>
        {actionButtons.length > 0 && <StyledActionButtonsWrapper>{actionButtons}</StyledActionButtonsWrapper>}

        <img width="100%" src={thumbnail.src || placeholderImage} alt={thumbnail.alt} />
      </StyledThumbnailContainer>
      <StyledSlideFooter>
        <StyledSlideTitle>{title}</StyledSlideTitle>
      </StyledSlideFooter>
    </StyledThumbnailContent>

    <StyledMetadata>{metadata}</StyledMetadata>
  </StyledSlideThumbnailWrapper>
);
