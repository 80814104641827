import { AxiosRequestConfig } from 'axios';
import { getAxiosClientWithRefreshToken } from './axiosWithRefreshToken';

const client = getAxiosClientWithRefreshToken({ baseURL: `${process.env.NX_CLATTER_API_URL}` });

/**
 * Axios instance that uses auth0 access token
 *
 * @param {AxiosRequestConfig} requestConfigOptions
 * @returns {Promise}
 */
const getAxiosWithAuthorization = (requestConfigOptions) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return client(requestConfigOptions).then(onSuccess);
};

export default getAxiosWithAuthorization;
