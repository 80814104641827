import { getAxiosWithAuthorization } from '../services/index';
import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const contentRepositoriesApi = {
  getUserContentRepositories: ({ userId }) =>
    getAxiosWithAuthorization({
      url: `${userId}/content-repository`,
      method: 'GET',
    }),
  getAllContentRepositories: () =>
    getAxiosWithAuthorization({
      url: `/content-repositories`,
      method: 'GET',
    }),
  getAllContentRepositoriesV2: () =>
    getAxiosApiV2WithAuthorization({
      url: `/content-repositories`,
      method: 'GET',
    }),
  fetchContentRepository: ({ repositoryId }) =>
    getAxiosWithAuthorization({
      url: `/content-repositories/${repositoryId}`,
      method: 'GET',
    }),
  updateContentRepository: ({ repositoryId, updatedRepo }) =>
    getAxiosWithAuthorization({
      url: `/content-repositories/${repositoryId}`,
      method: 'PATCH',
      data: updatedRepo,
    }),
  createContentRepository: ({ newRepoData }) =>
    getAxiosWithAuthorization({
      url: `/content-repositories`,
      method: 'POST',
      data: newRepoData,
    }),
};

export default contentRepositoriesApi;
