export const setLocalStorageAccessAuth = (authResult) => {
  const exp = authResult?.idTokenPayload?.exp;
  const cookieValue = window.btoa(
    JSON.stringify({
      accessToken: authResult.accessToken,
      expiresAt: exp,
    }),
  );

  localStorage.setItem('auth', cookieValue);
};

export const deleteLocalStorageAuth = () => {
  localStorage.removeItem('auth');
};

export const getLocalStorageAccessToken = () => {
  const data = localStorage.getItem('auth');

  if (!data) {
    return undefined;
  }

  const { accessToken } = JSON.parse(window.atob(data));

  return accessToken;
};
