import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';

export const useDeepCompareMemo = (cb, dependencies = []) => {
  const dependeciesRef = useRef();

  if (!dependencies || dependencies.length === 0) {
    throw new Error(
      '"useDeepCompareMemo" should not be used with no dependencies. Use "useMemo" instead.',
    );
  }

  if (!isEqual(dependeciesRef.current, dependencies)) {
    dependeciesRef.current = dependencies;
  }

  return useMemo(cb, [dependeciesRef.current]);
};
