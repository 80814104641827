import { has } from 'lodash';

const visit = (forest, vfn) => {
  forest.forEach((node) => {
    vfn(node);
    if ('children' in node) {
      visit(node.children, vfn);
    }
  });
};

const getSubheaders = (forest) => {
  let ids = [];
  const collect = (node) => {
    if (node.label === 'sub-header') {
      ids.push(node._id);
    }
  };
  visit(forest, collect);
  return ids;
};


export const getSlideThumbnailByTheme = ({ slide, themeId }) => {
  if (slide?.slideListByThemes?.length > 0) {
    const thumbnailByTheme = slide.slideListByThemes.find(
      (item) => item.themeId === themeId,
    );
    return thumbnailByTheme?.thumbnailLocation?.url ?? '';
  }
  return '';
}

export const getChildrenByParentId = (parentId, slidesHierarchy) => {
  let children = null;

  const collect = (node) => {
    if (node._id === parentId) {
      children = node;
    }
  };
  visit(slidesHierarchy, collect);
  return children;
};

export const getContentSlideParentByType = (
  contentSlideId,
  slidesHierarchy,
  type = 'sub-header',
) => {
  let subheader = null;
  const collect = (node) => {
    if (
      node.label === type &&
      node.children.find(({ _id }) => _id === contentSlideId)
    ) {
      if (!subheader) {
        subheader = node;
      }
    }
  };
  visit(slidesHierarchy, collect);

  return subheader;
};

export const getContentSlidesDecksIds = (libraryByTopicList) => {
  return getSubheaders(libraryByTopicList);
};

export const generateHashedSlideId = (id) => {
  const hash = Date.now() + Math.floor(Math.random() * 100);
  return `${id}-${hash}`;
};

export const getThumbnailUrlByThemeId = ({
  selectedThemeId,
  slideListByThemes,
}) =>
  slideListByThemes?.find(({ themeId }) => themeId === selectedThemeId)
    ?.thumbnailLocation?.url;

export const getDefaultCover = (coverSlides) => {
  const primaryCoverIndex = coverSlides.findIndex(
    (cover) => cover.slideType === 'PrimaryCover',
  );
  const coverIndex = primaryCoverIndex > -1 ? primaryCoverIndex : 0;

  return coverSlides[coverIndex];
};

export const getSlidesdGroups = (slides) => {
  return (slides || []).reduce((acc, slide) => {
    if (slide.group) {
      acc[slide.group._id]
        ? acc[slide.group._id].push(slide._id)
        : (acc[slide.group._id] = [slide._id]);
    }
    return acc;
  }, {});
};

export const getSlideGroupClassName = ({ slideGroup, slideId }) => {
  const slideIndex = slideGroup.findIndex((id) => id === slideId);
  switch (slideIndex) {
    case 0:
      return 'group-start';
    case slideGroup.length - 1:
      return 'group-end';
    default:
      return 'group-middle';
  }
};

export const getSlidesIdsBySlideOrder = ({
  slides,
  requiredSlidesIds = [],
}) => {
  const sortedSlidesIds = [...slides]
    .sort((a, b) => a.order - b.order)
    .map((slide) => slide?.content_slide_id);
  return [...requiredSlidesIds, ...sortedSlidesIds];
};

export const mapSelectedSlideData = ({ slide, selectedThemeId }) => {
  let imageUrl = slide?.thumbnailLocation?.url;

  if (slide?.slideType === 'Content') {
    imageUrl = getThumbnailUrlByThemeId({
      selectedThemeId: selectedThemeId,
      slideListByThemes: slide?.slideListByThemes,
    });
  }

  return {
    id: slide?._id,
    hashedId: generateHashedSlideId(slide?._id),
    title: slide?.title,
    imageUrl: imageUrl,
    slideType: slide?.slideType,
    isRequired: !!slide?.isRequired,
    isDisabled: !!slide?.isDisabled,
    groupId: slide?.group?._id ?? null,
  };
};

export const getIdsFromSlidesCollection = (slides = [], idPropName = '_id') => {
  if (slides?.length > 0 && !has(slides[0], idPropName)) {
    throw new Error(
      `getIdsFromSlidesCollection: slides collection does not include "${idPropName}" property`,
    );
  }
  return slides?.map((slide) => slide?.[idPropName]);
};

export const getSelectedSlidesData = ({ slidesIds = [], themeId, slidesMap }) =>
  slidesIds?.reduce((acc, slideId) => {
    acc.push(
      mapSelectedSlideData({
        selectedThemeId: themeId,
        slide: slidesMap[slideId],
      }),
    );
    return acc;
  }, []);
