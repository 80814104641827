import { css } from 'styled-components';

export const appGlobalStyles = css`
  * {
    box-sizing: border-box;
    font-family: 'Maven Pro', sans-serif;
  }

  .no-window-scrollbars::-webkit-scrollbar {
    display: none;
  }

  .no-window-scrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  // show scrollbars
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  // - end show scrollbars

  html,
  body,
  #root {
    height: 100%;
  }

  html {
    font-size: 14px;
  }

  body {
    overflow-y: scroll;
    background: #f4f7fc;
    margin: 0;
    line-height: 1.4;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 16px;
    padding: 0;
    font-weight: 400;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  .section-header {
    font-size: 16px;
    font-weight: 600;
    color: #454b49; // TODO: move to the theme?
  }

  p {
    margin: 0 0 16px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.linkColor};

    &:hover {
      color: ${({ theme }) => theme.palette.linkHoverColor};
    }
  }
`;
