import React, { useState } from 'react';
import { HelpOutlineOutlined as HelpOutlinedIcon } from '@mui/icons-material';
import { Dialog, Tooltip } from '@clatter/ui';
import { Stack } from '@mui/material';
import styled from 'styled-components';

const HelpIconContainer = styled.div`
  cursor: pointer;
`;

const StyledTextContainer = styled.div`
  @media (min-width: 800px) {
    max-width: 450px;
  }
`;

const InfoTooltip = ({
  tooltipText = "What's this?",
  title = '',
  helperText,
  externalUrl,
  hideTooltip = false,
  children,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false);

  const dialogTitle = title ? (
    <span>
      What's this?: <strong>{title}</strong>
    </span>
  ) : (
    <span>What's this?</span>
  );
  const handleCLick = () => {
    if (externalUrl) {
      window.open(externalUrl, '_blank', 'noopener');
      return;
    }

    if (helperText) {
      setDialogOpened(true);
    }
  };

  // let's return wrapped children if
  // tooltip shouldn't be shown...
  if (hideTooltip) {
    return (<>{ children }</>);
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        {children}
        <Tooltip text={tooltipText} left>
          <HelpIconContainer onClick={() => handleCLick()}>
            <HelpOutlinedIcon />
          </HelpIconContainer>
        </Tooltip>
      </Stack>

      <Dialog title={dialogTitle} onCloseDialog={() => setDialogOpened(false)} open={dialogOpened}>
        <StyledTextContainer>{helperText}</StyledTextContainer>
      </Dialog>
    </>
  );
};

export default InfoTooltip;
