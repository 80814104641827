import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { errorHandlingInterceptor } from '../helpers/error';
import { getAxiosClientWithRefreshToken } from './axiosWithRefreshToken';

/**
 * Creates a new instance of axios with a custom config
 *
 * @type {AxiosInstance} client
 */
const client = getAxiosClientWithRefreshToken({ baseURL: `${process.env.NX_CLATTER_API_V2_URL}` });

client.interceptors.response.use(
  (response) => response,
  errorHandlingInterceptor,
);

/**
 * Axios instance that uses auth0 access token
 *
 * @param {AxiosRequestConfig} requestConfigOptions
 * @returns {Promise}
 */
const getAxiosApiV2WithAuthorization = (requestConfigOptions) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return client(requestConfigOptions).then(onSuccess);
};

export default getAxiosApiV2WithAuthorization;
