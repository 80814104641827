import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';
import { starterDecksApi } from '../api';
import { getPresentationState } from './presentation.slice';

export const STARTER_DECKS_FEATURE_KEY = 'starterDecks';

export const clearStarterDecksState = createAction('CLEAR_STARTER_DECKS_STATE');

export const setSelectedDeck = createAction('SET_SELECTED_DECK');

export const fetchStarterDecks = createAsyncThunk(
  `${STARTER_DECKS_FEATURE_KEY}/fetchStarterDecks`,
  async ({ params } = {}, { rejectWithValue }) => {
    try {
      return await starterDecksApi.fetchStarterDecks({ params });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateStarterDeck = createAsyncThunk(
  `${STARTER_DECKS_FEATURE_KEY}/updateStarterDecks`,
  async ({ starterDeckId, payload } = {}, { rejectWithValue }) => {
    try {
      return await starterDecksApi.updateStarterDeck({
        starterDeckId,
        payload,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const createStarterDeck = createAsyncThunk(
  `${STARTER_DECKS_FEATURE_KEY}/createStarterDeck`,
  async ({ payload } = {}, { rejectWithValue }) => {
    try {
      return await starterDecksApi.createStarterDeck({ payload });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const starterDeckAdapter = createEntityAdapter({
  selectId: (row) => row.id,
});

export const initialState = starterDeckAdapter.getInitialState({
  selectedDeck: null,
  loadingStatus: requestStatus.initial,
  error: null,
});

export const starterDeckSlice = createSlice({
  name: STARTER_DECKS_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    add: starterDeckAdapter.addOne,
    remove: starterDeckAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedDeck, (state, { payload }) => {
        state.selectedDeck = payload || null;
      })
      .addCase(fetchStarterDecks.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchStarterDecks.fulfilled, (state, { payload }) => {
        if (payload?.data?.length > 0) {
          starterDeckAdapter.setAll(state, payload.data);
        } else {
          starterDeckAdapter.removeAll(state); // clear state if no data was returned
        }
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchStarterDecks.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(updateStarterDeck.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateStarterDeck.fulfilled, (state, { payload }) => {
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateStarterDeck.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(createStarterDeck.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(createStarterDeck.fulfilled, (state, { payload }) => {
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createStarterDeck.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(clearStarterDecksState, (state) => initialState);
  },
});

export const starterDecksReducer = starterDeckSlice.reducer;
export const starterDecksActions = starterDeckSlice.actions;

const { selectAll, selectEntities } = starterDeckAdapter.getSelectors();

export const getStarterDecksState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][STARTER_DECKS_FEATURE_KEY];

export const starterDecksSelectors = {
  selectAllStarterDecks: createSelector(getStarterDecksState, selectAll),
  selectStarterDecksEntities: createSelector(
    getStarterDecksState,
    selectEntities,
  ),
  selectLoadingStatus: createSelector(
    getPresentationState,
    (state) => state.loadingStatus,
  ),
  selectSelectedStartedDeck: createSelector(getStarterDecksState, (state) => {
    return state?.selectedDeck;
  }),
  selectStarterDeckById: (deckId) =>
    createSelector(
      getStarterDecksState,
      ({ entities }) => entities[deckId] || null,
    ),
  selectActiveStarterDecks: createSelector(getStarterDecksState, (state) => {
    const filtered = (selectAll(state) || [])?.filter(
      (item) => item.hasOwnProperty('active') && item?.active,
    );
    return filtered?.length > 0 ? filtered : null;
  }),
};
