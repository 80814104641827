import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledProfileWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.36);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const StyledProfileOptions = styled.ul`
  padding: 0 0 15px 0;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  width: 200px;
  background-color: ${({ theme }) => theme.palette.white};
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 3;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  list-style: none;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgb(255, 255, 255);
  }
`;

export const StyledProfileHeader = styled.li`
  padding: 16px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.14);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const StyledProfileListText = styled.span`
  margin-left: 9px;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.palette.darkGrey};
`;

export const StyledProfileListIcon = styled.span`
  display: inline-block;
  transform: translateY(1px);
`;

export const StyledProfileListLinkItem = styled(Link)`
  padding: 15px 17px 12px;
  text-decoration: none;
  display: block;
  transition: 0.5s all ease;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledProfileListTextItem = styled.li`
  padding: 15px 17px 12px;
  text-decoration: none;
  display: block;
  transition: 0.5s all ease;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledProfileVersionListItem = styled.span`
  padding: 15px 17px 12px;
  text-decoration: none;
  display: block;
  transition: 0.5s all ease;
  cursor: default;
  user-select: text;

  svg {
    width: 10px;
    height: 10px;
  }

  ${StyledProfileListText} {
    font-size: 0.9rem;
  }
`;
