import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import styled from 'styled-components';

const StyledCopyToClipBoard = styled.span`
  span {
    cursor: pointer;
  }

  svg {
    font-size: 1.3rem;
  }

  transition: all 0.5s ease-in 0s;

  &:hover {
    color: ${({ theme }) => theme.palette.grey};
    transition: all 0.2s ease-in 0s;
  }
`;

const CopyToClipBoard = ({
  tooltipTitle = 'Copied to clipboard!',
  delay = 600,
  textToCopy = '',
  boxProps = {},
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClose = () => setShowTooltip(false);
  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setShowTooltip(true);
  };

  return (
    <Tooltip
      open={showTooltip}
      title={tooltipTitle}
      leaveDelay={delay}
      onClose={handleClose}
    >
      <StyledCopyToClipBoard>
        <Box component="span" sx={{ ml: 1 }} {...props}>
          <ContentCopyIcon onClick={handleClick} />
        </Box>
      </StyledCopyToClipBoard>
    </Tooltip>
  );
};

export default CopyToClipBoard;
