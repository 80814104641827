import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCardContent = styled.div.attrs((props) => ({
  padding: props.padding || '15px 30px 10px',
}))`
  padding: ${({ padding }) => padding};
`;

const CardContent = forwardRef(({ children, ...props }, ref) => (
  <StyledCardContent ref={ref} {...props}>
    {children}
  </StyledCardContent>
));

export default CardContent;
