import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { debounce } from 'lodash';
import { useMountedEffect } from "@clatter/platform";

const StyledSearchInput = styled.div`
  position: relative;
  margin: auto auto 30px;
  background-color: rgba(230, 230, 230, 0.51);
  display: flex;
  flex-direction: column;

  svg {
    color: rgb(99, 99, 99);
    transform: translate(13px, 5px) rotateY(180deg);
    position: absolute;
    top: 10px;
    left: 0px;
  }

  input {
    display: block;
    line-height: 20px;
    padding: 12px 0px;
    border: none;
    outline: none;
    margin: 0px;
    background-color: transparent;
    box-sizing: border-box;
    height: 46px;
    min-width: auto;
    align-self: flex-end;
    width: calc(100% - 45px);
    color: -internal-light-dark(black, white);
  }
`;

const StyledCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 0;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const SearchInput = ({ onSearch, timeout }) => {
  const [showClearButton, setShowClearButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleReset = () => setSearchQuery('');

  const handleDebouncedSubmit = useCallback(
    debounce((search) => onSearch(search), timeout ?? 500),
    [],
  );

  useMountedEffect(() => {
    if (searchQuery.length > 0) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }

    handleDebouncedSubmit(searchQuery);
  }, [searchQuery]);

  return (
    <StyledSearchInput>
      <SearchIcon />
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Please enter a slide name or topic..."
      />
      {showClearButton && (
        <StyledCloseButton onClick={handleReset}>
          <CloseIcon />
        </StyledCloseButton>
      )}
    </StyledSearchInput>
  );
};

export default SearchInput;
