import { cookieService } from '../services/cookieService';
import { isLocalhost } from '../index';

export const setAuthCookie = (authResult) => {
  const exp = authResult?.idTokenPayload?.exp;
  const cookieValue = window.btoa('true');

  return cookieService.set(
    'auth_token',
    cookieValue,
    exp,
    _getAuthCookieDomain()
  );
};

export const deleteAuthCookie = () => {
  cookieService.delete('auth_token', _getAuthCookieDomain());
  cookieService.delete('pm_access_token', _getAuthCookieDomain());
};

export const isAuthCookieAvailable = () => {
  const cookie = cookieService.get('auth_token');
  if (!cookie) {
    return false;
  }
  return window.atob(cookie) === 'true';
};

const _getAuthCookieDomain = () => isLocalhost() ? 'localhost' : process.env.NX_AUTH_COOKIE_DOMAIN;
