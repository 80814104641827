import Joi from 'joi';

export const isEmailValid = (email) => {
  const { error } = Joi.object()
    .keys({ email: Joi.string().email({ tlds: { allow: false } }) })
    .validate({ email: email });
  return !error;
};

export const isUrlValid = (url) => {
  const regex = new RegExp("^(http|https)://", "i");
  return regex.test(url)
};
