import queryString from 'query-string';
import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

/*
  Usage:
  const countEventsStr = generateQueryString({
      queryName: 'event_types[]',
      values: event_types,
    });
*/
const generateQueryString = ({ queryName, values }) => {
  if (!values?.length) {
    return '';
  }
  return '&' + values.map((value) => `${queryName}=${value}`).join('&');
};

const reportsApi = {
  getLoginCounts: ({ startDate, endDate, notLoggedUsersOnly }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/login-counts`,
      params: {
        start_date: startDate,
        end_date: endDate,
        not_logged_users_only: notLoggedUsersOnly,
      },
    }),
  getPresentationsStats: ({ startDate, endDate, contentRepos }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/presentations-stats`,
      params: {
        start_date: startDate,
        end_date: endDate,
        content_repos: contentRepos,
      },
    }),
  getPresentationsByUser: ({ startDate, endDate }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/presentations-by-user`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }),
  getMicrositeAssetUsage: () =>
    getAxiosApiV2WithAuthorization({
      url: `/report/microsites-asset-usage`,
      method: 'get',
    }),
  getMicrositeUnusedAsset: () =>
    getAxiosApiV2WithAuthorization({
      url: `/report/microsites-unused-asset`,
      method: 'get',
    }),
  getSlideDownloadCounts: (data) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/slide-download-counts`,
      method: 'post',
      data: data,
    }),
  getPageview: ({ startDate, endDate }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/pageview`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }),
  getMicrositeDetails: ({ startDate, endDate }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/microsites-detail`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }),
  getSlidesUsage: ({ startDate, endDate }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/slides-usage`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }),
  getSlideDetail: () =>
    getAxiosApiV2WithAuthorization({
      url: `/report/slide-detail`,
    }),
  getActiveUsers: ({ startDate, endDate, granularity }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/active-users`,
      params: {
        start_date: startDate,
        end_date: endDate,
        granularity: granularity,
      },
    }),
  getSmDocumentsByUser: ({ startDate, endDate, toolId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/sm-documents-by-user`,
      params: {
        start_date: startDate,
        end_date: endDate,
        tool_id: toolId,
      },
    }),
  getSmDocumentsAssetUsage: ({ startDate, endDate, toolId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/documents-asset-usage`,
      params: { tool_id: toolId },
    }),
  getTemplateUsage: (params) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/template-usage`,
      params,
    }),
  getSmDocumentsUnusedAssets: ({ toolId, startDate, endDate }) =>
    getAxiosApiV2WithAuthorization({
      url: `/report/templates-unused-asset`,
      params: {
        start_date: startDate,
        end_date: endDate,
        tool_id: toolId,
      },
    }),
  getCmsStats: ({ metricType = null, startDate = null, endDate = null, tool = null }) => {
    const params = {};

    if (metricType) {
      params.metric_type = metricType;
    }

    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    if (tool) {
      params.tool = tool;
    }

    return getAxiosApiV2WithAuthorization({
      url: `/report/cms-stats`,
      params: params,
    });
  },
};

export default reportsApi;
