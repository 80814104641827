import { useSelector } from 'react-redux';
import { Loader } from '@clatter/ui';
import { platformSelectors, requestStatus } from '../../store/index';
import { useEffect, useState } from 'react';
import { useGetActiveTool } from '@clatter/platform';

const Analytics = () => {
  const analyticsConfig = useSelector(platformSelectors.toolInfo.selectToolInfo)?.data?.analyticsConfig;
  const isLoading = useSelector(({ platform }) => platform.toolInfo.loadingStatus) === requestStatus.pending;
  const isPlausibleEnabled = analyticsConfig?.plausible?.enabled;
  const activeTool = useGetActiveTool()?.slug?.toUpperCase();
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (!isLoading && isInitial && isPlausibleEnabled && activeTool) {
      const script = document.createElement('script');
      script.src = 'https://plausible.io/js/script.file-downloads.local.outbound-links.pageview-props.js';
      script.defer = true;
      script.setAttribute('data-domain', `clatter.io,${analyticsConfig?.plausible?.domain}`);
      script.setAttribute('event-tool', activeTool);
      document.head.appendChild(script);
      setIsInitial(false);
    }
  }, [isInitial, activeTool, isLoading, isPlausibleEnabled, analyticsConfig?.plausible?.domain]);

  return <>{isLoading && <Loader />}</>;
};

export default Analytics;
