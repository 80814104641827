import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDeepCompareEffect = (cb, dependencies = []) => {
  const dependeciesRef = useRef();

  if (!dependencies || dependencies.length === 0) {
    throw new Error(
      '"useDeepCompareEffect" should not be used with no dependencies. Use "useEffect" instead.',
    );
  }

  if (!isEqual(dependeciesRef.current, dependencies)) {
    dependeciesRef.current = dependencies;
  }

  useEffect(cb, [dependeciesRef.current]);
};
