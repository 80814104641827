const routes = {
  index: '/',
  logout: '/logout',
};

export const documentTitleMap = {
  index: 'Home'
}

export default routes;
