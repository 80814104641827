import React, { useEffect, useMemo } from 'react';
import { useGetActiveTool } from '@clatter/platform';

const UseDocumentTitle = (title, umountTitle) => {
  const activeTool = useGetActiveTool();
  const titlePrefix = useMemo(
    () => activeTool?.name || process.env.NX_TOOL_NAME,
    [activeTool],
  );

  const documentTitle = useMemo(
    () => `${titlePrefix} - ${title}`,
    [title, titlePrefix],
  );

  const documentFileName = useMemo(
    () => documentTitle.replace(/:/g, ' - '),
    [documentTitle],
  );

  useEffect(() => {
    document.title = title ? documentTitle : titlePrefix;

    return () => (document.title = umountTitle || titlePrefix);
  }, [title, titlePrefix, umountTitle]);

  return {
    documentFileName: documentFileName,
  };
};

export default UseDocumentTitle;
