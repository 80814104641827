import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  Close as CloseIcon,
  Add as AddIcon,
  OpenInNew as OpenInNewIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import {
  downloadFromUrl,
  logDownloadContentSlide,
  contentSlidesApi,
  formatDate,
  useInterval,
  deckBuildStatuses,
  getThumbnailUrlByThemeId,
  eventRequestTypes,
  useHasActiveFeatureFlag,
  featureFlagsMap,
  useEventTracking,
  platformSelectors,
  useDeepCompareMemo,
  mapSelectedSlideData,
  useGetActiveTool,
  setContentSlideDialogModalOpened,
  setContentSlideDialogPreviewMode,
  useImageProperties,
  usePresentationGeneratorDownload,
} from '@clatter/platform';
import {
  Button,
  buttonVariants,
  ControlError,
  CopyToClipboard,
  Loader,
} from '@clatter/ui';
import { useDispatch, useSelector } from 'react-redux';
import DownloadSlidesButton from './components/DownloadSlidesButton';
import DialogTitle, { StyledTitle } from './components/DialogTitle';
import { get, round, toLower } from 'lodash';
import { useParams } from 'react-router-dom';

const StyledButtonsContainer = styled.div`
  display: flex;

  > * {
    margin-right: 16px;
  }
`;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 252, 252);
  z-index: 10;
  max-height: 780px;
  max-width: 950px;

  @media (min-width: 1300px) {
    width: 60vw;
  }

  @media (min-width: 2560px) {
    width: 50vw;
    max-width: 1200px;
  }
`;

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  row-gap: 10px;

  .buttons-container {
    flex-direction: column-reverse;
    row-gap: 10px;
  }

  @media (min-width: 520px) {
    .buttons-container {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  @media (min-width: 930px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledFooter = styled.div`
  color: white;
  height: 60px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  background-color: rgb(99, 99, 99);
`;

const StyledCloseButtton = styled.div`
  cursor: pointer;
  position: absolute;
  right: -14px;
  top: -14px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;

  &:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border: 2px solid black;
    border-radius: 100%;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  svg {
    transition: all 0.2s ease-in-out;
    font-size: 1.7rem;
  }

  &:hover {
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.4);

    &:after {
      width: 23px;
      height: 23px;
      opacity: 1;
    }

    svg {
      transform: rotateZ(360deg);
      font-size: 1.3rem;
    }
  }
`;

const StyledFooterTitle = styled(StyledTitle)`
  color: white;
  width: 100%;
  padding-right: 30px;
  font-size: 1rem;
`;

const StyledDetailsContainer = styled.div`
  width: 100%;
  height: 57vh; // determines dialog height
  display: flex;
  padding: 0px 30px;

  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 2560px) {
    max-height: 650px;
  }
`;

const StyledDetailsLeft = styled.div`
  height: 100%;
  width: 40%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &.hidden {
    display: none;
  }

  @media (max-width: 425px) {
    overflow: scroll;
    width: 100%;
  }
`;

const StyledDetailsRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  @media (max-width: 425px) {
    height: 50%;
    padding: 0;
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: 95%;
    width: 2px;
    background-color: ${({ theme }) => theme.palette.grey};

    @media (max-width: 456px) {
      display: none;
    }
  }
`;

const StyledImageContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.landscape {
    width: calc(100% - 35px);
    margin-left: 35px;
  }

  &.opened {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }
`

const StyledPreviewImageContainer = styled.div`
  position: absolute; // this makes cover logo on right place
  overflow: hidden;
  object-fit: contain;

  &.landscape {
    width: 100%;
    height: auto;
  }

  &.portrait {
    width: auto;
    height: 100%;
  }
`;

const StyledPreviewImage = styled.img`
  border: 2px solid ${({ theme }) => theme.palette.grey};
  border-radius: 3px;
  height: 100%;
  width: 100%;

  &.opened {
    width: 100%;
    object-fit: contain;
  }
`;

const StyledToggleDetailsButton = styled.div`
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  left: -30px;
  top: 50%;
  padding: 4px 15px 4px 20px;
  color: rgb(99, 99, 99);
  background-color: ${({ theme }) => theme.palette.grey};
  transform: rotate(90deg);
  border-radius: 10px 10px 0 0;

  &.opened {
    box-shadow: 0px -5px 10px 1px rgba(0, 0, 0, 0.3);
  }

  span {
    font-weight: bold;
  }

  @media (max-width: 456px) {
    display: none;
  }
`;

const StyledModalNavigation = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSlideDetailsTitle = styled.h4`
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 3px;
  color: rgb(99, 99, 99);
`;

const StyledSlideDetailsText = styled.p`
  font-size: 0.8rem;
  padding-right: 30px;
  word-break: break-all;
`;

const StyledSlideLabel = styled.p`
  text-transform: capitalize;
  margin: auto;
`;

const StyledSlideNavButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: white;

  svg {
    font-size: 1.7rem;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const StyledErrorMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 50px;
`;

const StyledAddRemoveButtonWrapper = styled.div`
  white-space: nowrap;
`;

const ContentSlidesModalHeader = ({
  title,
  showAddButton,
  isSelected,
  onClose,
  onSelect,
  onDownload,
  previewMode,
  showDeckDownload,
  isRequired,
  hideDownloadButtons,
  enableTitleEdit,
  onTitleEdit,
  slideId,
}) => {
  const downloadButtonMenuItems = useMemo(() => {
    let items = [
      {
        label: 'Download Slide',
        value: 'slide',
        testId: 'download-slide-button',
      },
    ];

    if (showDeckDownload) {
      items.push({
        label: 'Download Deck',
        value: 'deck',
        testId: 'download-deck-button',
      });
    }

    return items;
  }, [showDeckDownload]);

  const showRemoveButton = isSelected || previewMode || isRequired;

  return (
    <StyledHeader>
      <DialogTitle
        title={title}
        enableEdit={enableTitleEdit}
        onChange={onTitleEdit}
        slideId={slideId}
      />

      <StyledButtonsContainer>
        {!hideDownloadButtons && (
          <DownloadSlidesButton
            onClick={onDownload}
            showDropdown={showDeckDownload}
            menuItems={downloadButtonMenuItems}
          />
        )}

        {showAddButton && (
          <StyledAddRemoveButtonWrapper>
            <Button
              icon={showRemoveButton ? CloseIcon : AddIcon}
              onClick={onSelect}
              disabled={isRequired}
              variant={
                showRemoveButton
                  ? buttonVariants.danger
                  : buttonVariants.primary
              }
            >
              {showRemoveButton
                ? 'Remove from presentation'
                : 'Add to presentation'}
            </Button>
          </StyledAddRemoveButtonWrapper>
        )}
      </StyledButtonsContainer>

      <StyledCloseButtton onClick={onClose} data-test-id="close-dialog-button">
        <CloseIcon />
      </StyledCloseButtton>
    </StyledHeader>
  );
};

const ContentSlidesModalNavigation = ({
  slides,
  currentIndex,
  onSlideChange,
}) => {
  const handleNextSlide = () => {
    onSlideChange('next');
  };

  const handlePreviousSlide = () => {
    onSlideChange('prev');
  };

  return (
    <StyledModalNavigation>
      <StyledSlideNavButton
        disabled={currentIndex <= 0}
        onClick={handlePreviousSlide}
      >
        <ChevronLeftIcon />
      </StyledSlideNavButton>

      <StyledSlideLabel>Slide</StyledSlideLabel>

      <StyledSlideNavButton
        disabled={currentIndex >= slides.length - 1}
        onClick={handleNextSlide}
      >
        <ChevronRightIcon />
      </StyledSlideNavButton>
    </StyledModalNavigation>
  );
};

const ContentSlidesModalFooter = ({
  slidePath,
  slides,
  selectedSlide,
  onSlideChange,
  currentIndex,
}) => {
  return (
    <StyledFooter>
      <StyledFooterTitle>{slidePath}</StyledFooterTitle>
      <ContentSlidesModalNavigation
        slides={slides}
        currentIndex={currentIndex}
        selectedSlide={selectedSlide}
        onSlideChange={onSlideChange}
      />
    </StyledFooter>
  );
};

const ContentSlideDetailsItem = ({ label, text, icon = null }) => {
  return (
    <div className={classNames({ 'direct-link': icon })}>
      <StyledSlideDetailsTitle>
        {label}
        {icon && icon}
      </StyledSlideDetailsTitle>

      <StyledSlideDetailsText>
        {icon ? (
          <a rel="noopener noreferrer" target="_blank" href={text}>
            {text} <OpenInNewIcon style={{ fontSize: '1rem' }} />
          </a>
        ) : (
          text
        )}
      </StyledSlideDetailsText>
    </div>
  );
};

const getAlignment = (alignment, direction = 'yAlignment') => {
  switch (alignment) {
    case 'top':
    case 'left':
      return 'flex-start';

    case 'bottom':
    case 'right':
      return 'flex-end';

    case 'center':
      return 'center';

    default:
      return direction === 'xAlignment' && !alignment ? 'flex-end' : 'center';
  }
};

const CobrandLogoWrapper = styled.div`
  width: ${(props) => props.width}%;
  height: ${(props) => props.height}%;
  position: absolute;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  display: flex;
  align-items: ${(props) =>
    getAlignment(
      toLower(get(props, `coBrandLogoData.yAlignment`)),
      'yAlignment',
    )};
  justify-content: ${(props) =>
    getAlignment(
      toLower(get(props, `coBrandLogoData.xAlignment`)),
      'xAlignment',
    )};
  flex-wrap: wrap;
`;

const CobrandLogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const ContentSlidesModalDetails = ({
  imageUrl,
  imageAlt,
  coBrandLogoData,
  slideDetails,
  onClick,
  opened,
}) => {
  const showCoBrandLogo = !!coBrandLogoData;
  const { imageOrientation } = useImageProperties(imageUrl);

  return (
    <StyledDetailsContainer>
      <StyledDetailsLeft
        className={classNames({
          hidden: !opened,
        })}
      >
        {slideDetails &&
          slideDetails.map(({ label, text, icon }) => (
            <ContentSlideDetailsItem
              key={label}
              label={label}
              text={text}
              icon={icon}
            />
          ))}
      </StyledDetailsLeft>

      <StyledDetailsRight className={classNames({ opened: !opened })}>
        <StyledToggleDetailsButton onClick={onClick} className={classNames({ opened: !opened } )}>
          <span>{opened ? 'Close' : 'Open'}</span>
          {opened ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </StyledToggleDetailsButton>

        <StyledImageContainerWrapper className={classNames(imageOrientation, { opened: !opened } )}>
          <StyledPreviewImageContainer className={classNames(imageOrientation, { opened: !opened })}>
            <StyledPreviewImage src={imageUrl} alt={imageAlt} className={classNames({ opened: !opened })} />
            {showCoBrandLogo && (
              <CobrandLogoWrapper
                width={round(get(coBrandLogoData, `position.width`, 0), 2)}
                height={round(get(coBrandLogoData, `position.height`, 0), 2)}
                top={round(get(coBrandLogoData, `position.y`, 0), 2)}
                left={round(get(coBrandLogoData, `position.x`, 0), 2)}
                coBrandLogoData={coBrandLogoData?.position}
              >
                <CobrandLogoImage src={coBrandLogoData?.logoUrl} />
              </CobrandLogoWrapper>
            )}
          </StyledPreviewImageContainer>
        </StyledImageContainerWrapper>
      </StyledDetailsRight>
    </StyledDetailsContainer>
  );
};

// selectedSlidesDataProp allows to override slides data
// structure used by default in the modal
// for now we only need it in the sort tab
const ContentSlidesDialog = ({
  dialogId,
  decksIds: customDecksIds = null,
  onChange,
  onTitleEdit,
  selectedContentSlidesIds,
  selectedContentThemeId,
  selectedContentRepositoryId,
  hideAddRemoveSlide = false,
  hideDownloadDeckButton = false,
  enableTitleEdit: enableTitleEditProp,
  selectedSlidesData: selectedSlidesDataProp,
  coverId,
  hideDownloadButtonsFF,
  includeCover = false,
  currentSlideIndex,
  setCurrentSlideIndex,
  showOnlySelected = false,
  onClose,
}) => {
  const { presentationId } = useParams();
  const {
    downloadSlide,
    downloadDeck,
    isLoading,
    errorMessage,
    setErrorMessage } = usePresentationGeneratorDownload({
    themeId: selectedContentThemeId
  });
  const [detailsOpened, setDetailsOpened] = useState(true);
  const dispatch = useDispatch();
  const dialogRef = useRef(null);

  const hideDownloadButtons = useHasActiveFeatureFlag(hideDownloadButtonsFF);

  //region REDUX
  const presentations = useSelector(
    platformSelectors.presentation.selectPresentationsEntities,
  );

  const decksIds = customDecksIds || useSelector(
    ({ platform: { contentSlides } }) => contentSlides.decksIds,
  );

  const dividerSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.dividerSlides,
  );

  const blankSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.blankSlides,
  );

  const coverSlides = useSelector(
    ({ platform: { contentSlides } }) => contentSlides.coverSlides,
  );

  const contentSlidesHierarchy = useSelector(
    platformSelectors.contentSlides.selectContentSlidesHierarchy,
  );

  const contentSlidesHierarchyIds = useSelector(
    platformSelectors.contentSlides.selectContentSlidesHierarchyIds,
  );

  const contentSlidesGroups = useSelector(
    ({ platform }) => platform.contentSlides.groups,
  );

  const previewMode = useSelector(
    ({ platform }) => platform.contentSlideDialog.previewMode,
  );

  const contentSLideDialogState = useSelector(
    ({ platform }) => platform.contentSlideDialog.opened,
  );
  //endregion

  const showContentSlideModal = contentSLideDialogState === dialogId;

  // If no selected slides data provide map selected ids to slides data
  const selectedSlidesData =
    selectedSlidesDataProp ||
    selectedContentSlidesIds?.map((slideId) => ({
      slide: contentSlidesHierarchy[slideId],
      selectedThemeId: selectedContentThemeId,
    }));

  // get list of slides for display in the content modal
  const allContentSlidesIds = useMemo(() => {
    let slidesIds = contentSlidesHierarchyIds;

    if (previewMode) {
      slidesIds = includeCover
        ? [coverId, ...selectedContentSlidesIds]
        : selectedContentSlidesIds;
    }

    if (showOnlySelected) {
      return selectedContentSlidesIds;
    }

    return slidesIds;
  }, [
    previewMode,
    includeCover,
    selectedContentSlidesIds,
    contentSlidesHierarchyIds,
  ]);

  const dividerSlideId = dividerSlides?.[0]?._id;
  const blankSlideId = blankSlides?.[0]?._id;
  const coverSlideId = coverSlides?.[0]?._id;
  const presentationData = presentations[presentationId] || {};

  const isDirectLinksEnabled = useHasActiveFeatureFlag(
    featureFlagsMap.PG.directLinks,
  );

  // get current slide data based on current index
  // display user edited title
  const selectedSlide = useDeepCompareMemo(() => {
    const slideId = allContentSlidesIds[currentSlideIndex];
    const updatedSlide = selectedSlidesData.find(({ id }) => id === slideId);
    const slide = contentSlidesHierarchy[slideId];
    if (updatedSlide) {
      return {
        ...slide,
        title: updatedSlide.title,
        isDisabled: updatedSlide?.isDisabled,
      };
    }
    return slide;
  }, [
    currentSlideIndex,
    selectedSlidesData,
    allContentSlidesIds,
    contentSlidesHierarchy,
  ]);

  const enableTitleEdit = enableTitleEditProp && !selectedSlide?.isDisabled;

  const isSelected = useMemo(
    () =>
      selectedSlide?._id &&
      selectedContentSlidesIds.includes(selectedSlide._id),
    [selectedContentSlidesIds, selectedSlide?._id],
  );

  const isInDeck = useMemo(() => {
    const index = decksIds.findIndex(
      (id) => id === selectedSlide?.contentSlideCategory,
    );
    return index !== -1;
  }, [decksIds, selectedSlide?._id]);

  const imageUrl = useMemo(() => {
    if (selectedSlide?.slideType !== 'Content') {
      return selectedSlide?.thumbnailLocation?.url;
    }

    if (selectedSlide?.slideListByThemes?.length > 0) {
      return getThumbnailUrlByThemeId({
        selectedThemeId: selectedContentThemeId,
        slideListByThemes: selectedSlide?.slideListByThemes,
      });
    }
    return '';
  }, [selectedSlide?._id, selectedContentThemeId]);

  const slideDetails = useMemo(() => {
    let data = [];
    if (selectedSlide?.author) {
      data.push({
        label: 'Author',
        text: selectedSlide.author,
      });
    }
    if (selectedSlide?.createdAt) {
      data.push({
        label: 'Date Created',
        text: formatDate(selectedSlide.createdAt),
      });
    }
    if (selectedSlide?.updatedAt) {
      data.push({
        label: 'Date Modified',
        text: formatDate(selectedSlide.updatedAt),
      });
    }

    if (isDirectLinksEnabled) {
      const baseUrl = window.location.origin;
      const directLinkSlideUrl = `${baseUrl}/share/slide/${selectedContentRepositoryId}/${selectedSlide?._id}?themeId=${selectedContentThemeId}`;
      const directLinkDeckUrl = `${baseUrl}/share/deck/${selectedContentRepositoryId}/${selectedSlide?.contentSlideCategory}`;

      data.push({
        icon: <CopyToClipboard textToCopy={directLinkSlideUrl} />,
        label: 'Direct Link to Slide',
        text: directLinkSlideUrl,
      });

      if (isInDeck) {
        data.push({
          icon: <CopyToClipboard textToCopy={directLinkDeckUrl} />,
          label: 'Direct Link to Deck',
          text: directLinkDeckUrl,
        });
      }
    }
    return data;
  }, [selectedSlide?._id]);

  const canShowAddButton =
    !hideAddRemoveSlide &&
    (selectedSlide?.slideType === 'Content' ||
      selectedSlide?.slideType === 'Divider' ||
      selectedSlide?.slideType === 'Blank');

  // direction can be "next" or  "prev"
  const handleSlideChange = (direction) => {
    const nextIndex =
      direction === 'next' ? currentSlideIndex + 1 : currentSlideIndex - 1;
    setCurrentSlideIndex(nextIndex);
  };

  const handleSelectSlides = ({ slideId, deselect }) => {
    const isInGroup = !!selectedSlide.group?._id;

    if (deselect) {
      if (isInGroup) {
        if (
          window.confirm(
            'Removing this slide will remove all the slides belonging to this group. Do you still want to continue?',
          )
        ) {
          const updated = [...selectedSlidesData].filter(
            (slide) => slide.groupId !== selectedSlide.group?._id,
          );
          onChange(updated);
        }
        return;
      }

      const nextCurrentIndex =
        currentSlideIndex < allContentSlidesIds.length - 1
          ? currentSlideIndex
          : 0;
      setCurrentSlideIndex(nextCurrentIndex);

      const selectedIndex = selectedContentSlidesIds.findIndex(
        (id) => id === slideId,
      );

      if (selectedIndex === -1) {
        return;
      }

      const updated = [...selectedSlidesData];

      updated.splice(selectedIndex, 1);

      onChange(updated);
      return;
    }

    if (isInGroup) {
      if (
        window.confirm(
          'Adding this slide will add all the slides belonging to this group. Do you still want to continue?',
        )
      ) {
        const slidesToAdd = contentSlidesGroups[selectedSlide.group._id].reduce(
          (acc, slideId) => {
            const slide = contentSlidesHierarchy[slideId];
            acc.push(
              mapSelectedSlideData({
                slide: slide,
                selectedThemeId: selectedContentThemeId,
              }),
            );
            return acc;
          },
          [],
        );
        onChange([...selectedSlidesData, ...slidesToAdd]);
      }
      return;
    }

    const slide = contentSlidesHierarchy[slideId];
    const slideData = mapSelectedSlideData({
      slide: slide,
      selectedThemeId: selectedContentThemeId,
    });

    onChange([...selectedSlidesData, slideData]);
  };

  const handleCloseDialog = () => {
    setCurrentSlideIndex(0);
    if (typeof onClose === 'function') {
      onClose();
      return;
    }
    dispatch(setContentSlideDialogModalOpened(null));
    if (previewMode) {
      dispatch(setContentSlideDialogPreviewMode(false));
    }
  };

  const handleClickOutsideDialog = (event) => {
    if (dialogRef.current && dialogRef.current === event.target) {
      handleCloseDialog();
    }
  };

  const handleSelectSlide = () => {
    if (isSelected) {
      handleSelectSlides({ slideId: selectedSlide._id, deselect: true });
      return;
    }
    handleSelectSlides({ slideId: selectedSlide._id, deselect: false });
  };

  const handleDownloadSlide = async (type) => {
    if (type === 'slide') {
      await downloadSlide(selectedSlide)
    }

    if (type === 'deck') {
      await downloadDeck({
        coverId: coverSlideId,
        blankId: blankSlideId,
        dividerId: dividerSlideId,
        deckId: selectedSlide.contentSlideCategory,
        contentRepositoryId: selectedContentRepositoryId,
      })
    }
  };

  const toggleOpenDetails = () => {
    setDetailsOpened((prev) => !prev);
  };

  // clear error message if switching slide
  useEffect(() => {
    if (selectedSlide?._id) {
      setErrorMessage(null);
    }
  }, [selectedSlide?._id]);

  useEffect(() => {
    if (previewMode && !selectedContentSlidesIds.length) {
      handleCloseDialog();
    }
  }, [previewMode, selectedContentSlidesIds]);

  // in case user deselect last item when dialog is open
  // properly handle closing the dialog
  useEffect(() => {
    if (!selectedSlide?._id) {
      handleCloseDialog();
    }
  }, [selectedSlide?._id]);

  if (!showContentSlideModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <StyledModal onMouseDown={handleClickOutsideDialog} ref={dialogRef}>
      {isLoading && <Loader />}
      <StyledContainer>
        <ContentSlidesModalHeader
          previewMode={previewMode}
          title={selectedSlide?.title}
          isRequired={selectedSlide?.isRequired}
          showAddButton={canShowAddButton}
          onClose={handleCloseDialog}
          onDownload={handleDownloadSlide}
          onSelect={handleSelectSlide}
          isSelected={isSelected}
          showDeckDownload={!hideDownloadDeckButton && isInDeck}
          hideDownloadButtons={hideDownloadButtons}
          enableTitleEdit={enableTitleEdit}
          onTitleEdit={onTitleEdit}
          slideId={selectedSlide?._id}
        />

        <StyledErrorMessageContainer>
          <ControlError message={errorMessage} />
        </StyledErrorMessageContainer>

        <ContentSlidesModalDetails
          imageUrl={imageUrl}
          coBrandLogoData={{
            logoUrl: presentationData?.customerLogo?.location?.url,
            position: selectedSlide?.coBrandLogoData,
          }}
          imageAlt={selectedSlide?.title ?? ''}
          slideDetails={slideDetails}
          onClick={toggleOpenDetails}
          opened={detailsOpened}
        />

        <ContentSlidesModalFooter
          contentSlideModalPreviewMode={previewMode}
          selectedSlide={selectedSlide}
          slidePath={selectedSlide?.path ?? ''}
          slides={allContentSlidesIds}
          currentIndex={currentSlideIndex}
          onSlideChange={handleSlideChange}
        />
      </StyledContainer>
    </StyledModal>,
    document.body,
  );
};

export default ContentSlidesDialog;
