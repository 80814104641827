import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRoutesState } from '@clatter/platform';
import { DataGridPremium as MaterialDataGrid, useGridApiRef } from '@mui/x-data-grid-premium';

import { StyledDataGridPremium } from './index';
import { DataGridCustomToolbar } from "./components/DataGridCustomToolbar";

// this method will check if filters in the persisted state are
// still available in the columns definition, and if not it will remove those
// from state so Data Grid wouldn't break on render
const compareFiltersState = (persistedState, columns) => {
  const clonedState = _.cloneDeep(persistedState);

  if (clonedState?.filter?.filterModel?.items) {
    const updatedFilterModel = clonedState.filter.filterModel.items.map((item) => {
      const { columnField, operatorValue } = item || {};

      const columnData = columns.find(column => column.field === columnField);

      // if columnData is not found, or it doesn't have filterOperators, keep the filter.
      if (!columnData || !columnData.filterOperators) {
        return item;
      }

      const hasPersistedFilterDefinition = columnData.filterOperators.some(op => op.value === operatorValue);

      return hasPersistedFilterDefinition ? item : null;
    });

    clonedState.filter.filterModel.items = updatedFilterModel.filter(item => item !== null);
  }

  return clonedState;
};

const DataGridPremium = ({
  apiRef: customApiRef,
  columns,
  getRowId,
  loading = false,
  name: tableName = 'table', // used to store in the persistence layer, should be unique per route
  onRowClick,
  rows,
  selectable,
  dataGridProps,
  renderCustomToolbar,
  onRowsPerSizeChange,
  initialRowsPerPage = 25,
  showVerticalSidebar = false,
  tableHeight = 'auto',
  defaultSortField,
  disableColumnFilter,
  rowsPerPageOptions,
  selectedRowsPerPage,
  multilineItemsLimit,
  disablePersist,
}) => {
  const apiRef = customApiRef || useGridApiRef();
  // const apiRef = useGridApiRef();
  const { setRouteStateProperty, getRouteStateProperty } = useRoutesState();
  const persistedState = getRouteStateProperty(tableName);

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const { components = {}, ...restDataGridProps } = dataGridProps || {};
  const { Toolbar: CustomToolbarFromProps, ...otherComponents } = components;

  //region METHODS
  const handleRowsPerPageChange = (nextRowsPerPage) => {
    if (onRowsPerSizeChange) {
      onRowsPerSizeChange(nextRowsPerPage);
    }
    setRowsPerPage(nextRowsPerPage);
  };

  const handleStateChange = useCallback(
    async (params) => {
      if (disablePersist) {
        return;
      }

      if (!apiRef?.current?.exportState) {
        console.debug('No Data Grid api ref!');
        return;
      }

      const state = apiRef?.current?.exportState();

      const withColumnVisibility = {
        ...state,
        pinnedColumns: params?.pinnedColumns,
        columns: {
          ...state?.columns,
          columnVisibilityModel: params?.columns?.columnVisibilityModel || {},
        },
      };

      // if there is no change in the grid state
      // do not trigger redux update, so we can avoid
      // unnecessary updates and app slow down
      if (_.isEqual(withColumnVisibility, persistedState)) {
        return;
      }

      // save state to persist reducer
      await setRouteStateProperty({
        [tableName]: withColumnVisibility,
      });
    },
    [persistedState, apiRef],
  );

  const handleGetRowHeight = (params) => {
    // skip (return default), if multiline items limit is not set
    if (multilineItemsLimit === undefined) {
      return null;
    }

    // let's start by getting visible columns with "multiline" prop set to true
    // then, check if current row has data that meets the "multiline" policy
    const visibleMultilineColumns = (apiRef?.current?.getVisibleColumns() || []).filter(column => !!column.multiline);

    // skip (return default), if there are NO visible multiline columns
    if (!visibleMultilineColumns.length) {
      return null;
    }

    let isRowAutoHeight = false;

    visibleMultilineColumns.forEach(column => {
      if (isRowAutoHeight) {
        return;
      }

      // note: this assumes that the "multiline" columns has array data
      if ((params?.model?.[column.field] || []).length > 2) {
        isRowAutoHeight = true;
      }
    });

    return isRowAutoHeight ? 'auto' : null;
  };
  //endregion

  //region EFFECTS
  useEffect(() => {
    let mounted = true;
    if(mounted) {
      if (selectedRowsPerPage && typeof selectedRowsPerPage === 'number') {
        setRowsPerPage(selectedRowsPerPage);
      }
    }

    return () => mounted = false;
  }, [selectedRowsPerPage]);
  //endregion

  return (
    <StyledDataGridPremium
      showVerticalSidebar={showVerticalSidebar}
      style={{ height: tableHeight === 'auto' ? 'unset' : tableHeight }}
    >
      <div className="wrapper" style={{ height: tableHeight === 'auto' ? 'unset' : '100%' }}>
        <MaterialDataGrid
          apiRef={apiRef}
          // state
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          initialState={
            persistedState ? compareFiltersState(persistedState, columns) : {
              pagination: { page: 0, pageSize: initialRowsPerPage },
              sorting: { sortModel: defaultSortField ? [defaultSortField] : [] },
            }
          }
          // handlers
          onStateChange={handleStateChange} // handle state persist
          onPageSizeChange={handleRowsPerPageChange}
          onRowClick={onRowClick}
          // customizations
          components={{
            Toolbar: () => (
              <DataGridCustomToolbar
                renderCustomToolbar={renderCustomToolbar}
                customToolbarFromProps={CustomToolbarFromProps}
                apiRef={apiRef}
              />
            ),
            ...otherComponents,
          }}
          autoPageSize={false}
          autoHeight={tableHeight === 'auto'}
          rowsPerPageOptions={rowsPerPageOptions || [25, 50, 100]}
          getRowId={getRowId}
          loading={loading}
          disableColumnFilter={disableColumnFilter}
          getRowHeight={handleGetRowHeight}
          checkboxSelection={selectable}
          pagination
          {...restDataGridProps}
        />
      </div>
    </StyledDataGridPremium>
  );
};

DataGridPremium.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DataGridPremium;
