import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { primaryColor, secondaryColor } from '../../config/theme';
import { textLinkVariants } from '../../config/consts';

const StyledTextLink = styled.a`
  cursor: pointer;
  display: inline-block;
  text-decoration: none !important;
  margin-bottom: 24px;
  font-weight: bold;

  &.${textLinkVariants.primary} {
    color: ${primaryColor} !important;
  }

  &.${textLinkVariants.secondary} {
    color: ${secondaryColor} !important;
  }

  &:hover {
    text-decoration: underline !important;
  }

  &.${textLinkVariants.clear} {
    font-weight: normal;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &.${textLinkVariants.clearDimmed} {
    font-weight: normal;
    text-decoration: underline !important;
    color: ${({ theme }) => theme?.palette?.textColor} !important;

    &:hover {
      text-decoration: none !important;
    }
  }


`;

const TextLink = ({
  onClick,
  label,
  testId,
  variant = textLinkVariants.primary,
}) => {
  return (
    <StyledTextLink
      href="#"
      className={variant}
      onClick={onClick}
      data-test-id={testId}
    >
      {label}
    </StyledTextLink>
  );
};

TextLink.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(textLinkVariants)),
};

export default TextLink;
