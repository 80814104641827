import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
export const PERSISTED_FEATURE_KEY = 'persisted';

export const initialPersistedState = {
  pm_attributes: [],
};

export const persistedSlice = createSlice({
  name: PERSISTED_FEATURE_KEY,
  initialState: initialPersistedState,
  reducers: {
    set: (state, { payload }) => {
      state[payload.type] = payload.data;
    },
  },
});

export const getImpersonateState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][PERSISTED_FEATURE_KEY];

export const persistedSelectors = {
  pmAttributes: createSelector(
    getImpersonateState,
    (state) => state?.pm_attributes,
  ),
};
