import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import contentRepositoriesApi from '../api/contentRepositories.api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';
import contentCategoryApi from '../api/contentCategory';
import { getContentRepoMetaDataFieldName } from '@clatter/platform';

export const CONTENT_REPOSITORIES_FEATURE_KEY = 'contentRepositories';
export const contentRepositoriesAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const setSelectedContentRepositoryId = createAction(
  'SET_SELECTED_CONTENT_REPOSITORY_ID',
);

export const fetchContentRepositories = createAsyncThunk(
  `${CONTENT_REPOSITORIES_FEATURE_KEY}/fetchContentRepositories`,
  async () => await contentRepositoriesApi.getAllContentRepositories(),
);

export const fetchContentRepositoriesV2 = createAsyncThunk(
  `${CONTENT_REPOSITORIES_FEATURE_KEY}/fetchContentRepositoriesV2`,
  async () => await contentRepositoriesApi.getAllContentRepositoriesV2(),
);

export const fetchContentRepository = createAsyncThunk(
  `${CONTENT_REPOSITORIES_FEATURE_KEY}/fetchContentRepository`,
  async ({ contentRepositoryId }) =>
    await contentRepositoriesApi.fetchContentRepository({
      repositoryId: contentRepositoryId,
    }),
);

export const fetchContentRepositoriesCategories = createAsyncThunk(
  `${CONTENT_REPOSITORIES_FEATURE_KEY}/fetchContentRepositoriesCategories`,
  async () => {
    try {
      const list = {};
      const contentRepositories =
        await contentRepositoriesApi.getAllContentRepositories();

      const enabledContentRepositories = (
        contentRepositories.data || []
      ).filter((repo) => repo.enable);

      const categoryRequests = [];
      enabledContentRepositories.forEach((contentRepository) => {
        categoryRequests.push(
          contentCategoryApi.getContentCategories({
            contentRepositoryId: contentRepository._id,
            excludeSlides: true,
          }),
        );
      });

      const categoryResponses = await Promise.all(categoryRequests);

      categoryResponses.forEach((category, index) => {
        const contentRepository = enabledContentRepositories[index];
        list[contentRepository._id] = {
          _id: contentRepository._id,
          title: contentRepository.name,
          enable: contentRepository.enable,
          categories: category.data.map((category) => ({
            _id: category._id,
            title: category.title,
            subcategories: category.children.reduce((acc, subcategory) => {
              if (subcategory.label === 'header') {
                acc.push({
                  _id: subcategory._id,
                  title: subcategory.title,
                  decks: subcategory.children.reduce((deckAcc, deck) => {
                    if (deck.label === 'sub-header') {
                      deckAcc.push({ _id: deck._id, title: deck.title });
                    }

                    return deckAcc;
                  }, []),
                });
              }

              return acc;
            }, []),
          })),
        };
      });

      return {
        hierarchy: list,
        attributes: groupContentRepositoriesAttributes(
          enabledContentRepositories,
        ),
      };
    } catch (e) {
      throw e;
    }
  },
);

export const fetchUserContentRepositories = createAsyncThunk(
  `${CONTENT_REPOSITORIES_FEATURE_KEY}/fetchUserContentRepositories`,
  async ({ userId }) =>
    await contentRepositoriesApi.getUserContentRepositories({ userId }),
);

export const initialContentRepositoriesState =
  contentRepositoriesAdapter.getInitialState({
    selectedContentRepositoryId: null,
    loadingStatus: requestStatus.initial,
    hierarchy: {},
    attributes: {},
    error: null,
  });

const mapAiToStore = (repository) => ({
  ...repository,
  label: repository.title,
});

const groupContentRepositoriesAttributes = (contentRepositories) =>
  contentRepositories.reduce(
    (acc, contentRepository) => ({
      ...acc,
      ...(contentRepository?.metadata || []).reduce(
        (contentRepositoryAttributes, attribute) => ({
          ...contentRepositoryAttributes,
          [getContentRepoMetaDataFieldName(attribute)]: {
            ...attribute,
            content_repos: [
              ...(acc[getContentRepoMetaDataFieldName(attribute)]
                ?.content_repos || []),
              {
                _id: contentRepository._id,
                name: contentRepository.name,
                enable: contentRepository.enable,
              },
            ],
          },
        }),
        {},
      ),
    }),
    {},
  );

export const contentRepositoriesSlice = createSlice({
  name: CONTENT_REPOSITORIES_FEATURE_KEY,
  initialState: initialContentRepositoriesState,
  reducers: {
    add: contentRepositoriesAdapter.addOne,
    remove: contentRepositoriesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedContentRepositoryId, (state, { payload }) => {
        state.selectedContentRepositoryId = payload;
      })
      .addCase(fetchContentRepository.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchContentRepository.fulfilled, (state, { payload }) => {
        contentRepositoriesAdapter.upsertOne(state, mapAiToStore(payload.data));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentRepository.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(fetchContentRepositories.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchContentRepositoriesCategories.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(
        fetchContentRepositoriesCategories.fulfilled,
        (state, { payload }) => {
          state.hierarchy = payload.hierarchy;
          state.attributes = payload.attributes;
          state.loadingStatus = requestStatus.fulfilled;
        },
      )
      .addCase(
        fetchContentRepositoriesCategories.rejected,
        (state, { error }) => {
          state.loadingStatus = requestStatus.error;
          state.error = error.message;
        },
      )
      .addCase(fetchContentRepositories.fulfilled, (state, { payload }) => {
        contentRepositoriesAdapter.upsertMany(
          state,
          payload.data.map(mapAiToStore),
        );
        state.attributes = groupContentRepositoriesAttributes(payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentRepositories.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(fetchContentRepositoriesV2.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchContentRepositoriesV2.fulfilled, (state, { payload }) => {
        contentRepositoriesAdapter.upsertMany(
          state,
          payload.data.map(mapAiToStore),
        );
        state.attributes = groupContentRepositoriesAttributes(payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentRepositoriesV2.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      .addCase(fetchUserContentRepositories.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchUserContentRepositories.fulfilled, (state, { payload }) => {
        contentRepositoriesAdapter.setAll(
          state,
          payload.data.map(mapAiToStore),
        );
        state.attributes = groupContentRepositoriesAttributes(payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchUserContentRepositories.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

export const contentRepositoriesReducer = contentRepositoriesSlice.reducer;
export const contentRepositoriesActions = contentRepositoriesSlice.actions;

const { selectAll, selectEntities, selectById } =
  contentRepositoriesAdapter.getSelectors();

export const getContentRepositoriesState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][CONTENT_REPOSITORIES_FEATURE_KEY];

export const repositoriesConfigSelectors = {
  selectAllContentRepositories: createSelector(
    getContentRepositoriesState,
    selectAll,
  ),
  selectContentRepositoriesEntities: createSelector(
    getContentRepositoriesState,
    selectEntities,
  ),
  selectContentRepositoryById: (selectedContentRepositoryId) =>
    createSelector(getContentRepositoriesState, (state) =>
      selectById(state, selectedContentRepositoryId),
    ),
  selectSelectedContentRepository: createSelector(
    getContentRepositoriesState,
    ({ entities, selectedContentRepositoryId }) => {
      return entities[selectedContentRepositoryId];
    },
  ),
  selectContentRepositoryHierarchy: createSelector(
    getContentRepositoriesState,
    (state) => state.hierarchy,
  ),
  selectContentRepositoryAttributes: createSelector(
    getContentRepositoriesState,
    (state) => state.attributes,
  ),
  selectLoadingStatus: createSelector(
    getContentRepositoriesState,
    (state) => state.loadingStatus,
  ),
};
