import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { primaryColor, secondaryColor, textColor } from '../../config/theme';
import { useSelector } from 'react-redux';

import { platformSelectors, requestStatus } from '@clatter/platform';

const StyledDropDown = styled.div`
  position: relative;

  .drop-down-value {
    background-color: ${primaryColor};
    border: 1px solid ${primaryColor};
    border-radius: 4px;
    outline: none;
    padding: 0 10px 0 14px;
    height: 40px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 700;
    }

    svg {
      color: #fff;
      font-size: 24px;
    }

    &:disabled {
      pointer-events: none;

      svg {
        display: none;
      }
    }
  }

  .drop-down-items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 14%) 4px 8px 15px 0;
    border-radius: 3px;

    .drop-down-item {
      display: block;
      color: ${textColor};
      cursor: pointer;
      line-height: 40px;
      padding: 0 10px;

      &:hover {
        background-color: rgba(37, 119, 137, 0.09);
      }
    }

    &.opened {
      display: block;
    }
  }

  &.drop-down-secondary {
    .drop-down-value {
      background-color: ${secondaryColor};
      border: 1px solid ${secondaryColor};
    }

    .drop-down-items {
      .drop-down-item {
        &:hover {
          background-color: rgba(232, 119, 34, 0.09);
        }
      }
    }
  }
`;

const DropDown = ({
  options = [],
  value = null,
  variant = 'primary',
  noValueLabel = '',
  hideActive = false,
}) => {
  const [currentOptions, setCurrentOptions] = useState(options);
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef(null);

  const { landingPageSiteName, loadingStatus } = useSelector(
    platformSelectors.systemProfile.selectSystemProfile,
  );

  const toggleOpened = () => {
    setOpened(!opened);
  };

  const handleClickOutside = (event) => {
    if (opened && dropDownRef && !dropDownRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);

  useEffect(() => {
    if (landingPageSiteName) {
      const newOptions = [
        {
          label: 'Home',
          url: process.env.NX_APP_ROOT_DOMAIN,
        },
        ...options,
      ];

      setCurrentOptions(newOptions);
    } else {
      setCurrentOptions(options);
    }
  }, [options, landingPageSiteName]);

  if (
    (!currentOptions || !currentOptions.length) &&
    loadingStatus !== requestStatus.fulfilled
  ) {
    return null;
  }

  return (
    <StyledDropDown className={`drop-down-${variant}`} ref={dropDownRef}>
      <button
        disabled={currentOptions.length < 2}
        className="drop-down-value"
        onClick={toggleOpened}
        type="button"
      >
        <span>{value && 'label' in value ? value.label : noValueLabel}</span>
        <ExpandMoreOutlinedIcon />
      </button>
      <div className={`drop-down-items ${opened ? 'opened' : ''}`}>
        {currentOptions.map(({ label, url }) =>
          hideActive && value?.url === url ? null : (
            <a href={url} key={label} className="drop-down-item">
              {label}
            </a>
          ),
        )}
      </div>
    </StyledDropDown>
  );
};

export default DropDown;
