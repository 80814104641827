import React, { useEffect } from 'react';
import { useAuth } from '@clatter/platform';
import { useHistory } from 'react-router-dom';

const LogoutPageView = () => {
  const history = useHistory();
  const { logout } = useAuth();
  const redirectTo = history?.location?.state?.redirectTo;
  const rootUrl = process.env.NX_APP_ROOT_DOMAIN || window.location.origin;

  useEffect(() => {
    logout({
      returnTo: redirectTo ? rootUrl + `?redirectTo=${redirectTo}` : rootUrl,
    });
  }, [logout, redirectTo]);

  return null;
};

export default LogoutPageView;
