import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';

const isEqual = (prev, next) => prev?.length === next?.length;

export const useGetActiveTool = () => {
  const toolSlug = new URL(window.location.origin).origin;
  const tools = useSelector(platformSelectors.tools.selectAllTools, isEqual);

  return useMemo(
    () => tools.find((tool) => new URL(tool.url).origin === toolSlug),
    [toolSlug, tools?.length],
  );
};
