import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { platformSelectors, Ribbon } from '@clatter/platform';
import { Breadcrumbs, EditableTextElement, InfoTooltip } from '../../index';
import styled from 'styled-components';
import classNames from 'classnames';
import Grid2 from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
  },
}));

const StyledPageTitle = styled.h2`
  display: flex;
  gap: 10px;
  font-size: 24px;
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 1.5rem;

  &.no-margin {
    margin-bottom: 0;
  }
`;

const renderTitle = ({ helpLink, pageTitle, pageTitlePrefix, noMargin }) => {
  const title = pageTitlePrefix ? `${pageTitlePrefix} ${pageTitle}` : pageTitle
  return helpLink ? (
    <InfoTooltip title="Help link" tooltipText="Documentation" externalUrl={helpLink} hideTooltip={!helpLink}>
      <StyledPageTitle className={classNames({ 'no-margin': noMargin })}>{title}</StyledPageTitle>
    </InfoTooltip>
  ) : (
    <StyledPageTitle className={classNames({ 'no-margin': noMargin })}>{title}</StyledPageTitle>
  );
};

const PageTitleHeader = ({
  headerActions,
  secondaryContent,
  pageTitle,
  noMargin,
  links,
  type,
  ribbonActions,
  disableDivider,
  text,
  titleOptions,
  pageTitlePrefix,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const helpjuiceLinks = useSelector(platformSelectors.toolInfo.selectToolInfo)?.data?.helpjuiceLinks || {};

  if (type === 'ribbon') {
    return <Grid2 container xs={12}>
      <Grid2 item xs={12} mb={2}>
        <Breadcrumbs links={links} history={history} pageTitle={pageTitle} />
      </Grid2>
      <Grid2 item xs={12}>
        <Ribbon pageTitle={
          renderTitle({
            pageTitle: pageTitle,
            helpLink: helpjuiceLinks[location?.pathname],
            noMargin: noMargin,
            pageTitlePrefix: pageTitlePrefix,
          })
        }
        text={text}
        disableDivider={disableDivider}
        ribbonActions={ribbonActions} />
      </Grid2>
    </Grid2>;
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item mb={2}>
          <Breadcrumbs links={links} history={history} pageTitle={pageTitle} />
        </Grid>

        <Grid item container justifyContent="space-between">
          <Grid item>
            {titleOptions?.editable ? (
              <EditableTextElement
                label={titleOptions?.label}
                initialValue={titleOptions?.titleInitialValue}
                onTextChange={titleOptions?.onTitleChange}
                customValidator={titleOptions?.customValidator}
              >
                {
                  renderTitle({
                    pageTitle: pageTitle,
                    helpLink: helpjuiceLinks[location?.pathname],
                    noMargin: true,
                    pageTitlePrefix: pageTitlePrefix,
                  })
                }
              </EditableTextElement>
            ) : (
              renderTitle({
                pageTitle: pageTitle,
                helpLink: helpjuiceLinks[location?.pathname],
                noMargin: noMargin,
                pageTitlePrefix: pageTitlePrefix,
              })
            )}
          </Grid>
          {headerActions && <Grid item>{headerActions}</Grid>}
        </Grid>
        {secondaryContent && (
          <Grid item xs>
            {secondaryContent}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PageTitleHeader;
