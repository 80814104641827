export const blobToDataUri = async (data, type) => {
  return new Promise((resolve) => {
    const blob = new Blob([data], type);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(blob);
  });
};

export const stringToImageData = async (data) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = data;

    resolve({
      width: image.width,
      height: image.height,
    });
  });
};

export const getImageSize = (uploadedImage) =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = window.URL.createObjectURL(uploadedImage);
    image.onload = () => resolve({ width: image.width, height: image.height });
  });


export const getThumbnailWidthByZoomValue = (zoomValue, gap, minSize = 9) => {
  switch (zoomValue) {
    case 0:
      return `calc(100% / ${minSize} - ${(gap * 8) / 9}px)`;
    case 20:
      return `calc(100% / 5 - ${(gap * 4) / 5}px)`;
    case 40:
      return `calc(100% / 4 - ${(gap * 3) / 4}px)`;
    case 60:
      return `calc(100% / 3 - ${(gap * 2) / 3}px)`;
    case 80:
      return `calc(100% / 2 - ${gap / 2}px)`;
    default:
      return '100%';
  }
};
