import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControl,
  StyledRadioGroup,
  Label,
} from '@clatter/ui';
import styled from 'styled-components';

const StyledFormWrapper = styled.div`
  margin-top: 22px;
`;

const SelectRepository = ({
  contentRepositories = [],
  onSelectContentRepository,
  isInEditMode = false,
  selectedContentRepositoryId = '',
}) => {
  const [repositoryId, setRepositoryId] = useState(selectedContentRepositoryId);

  useEffect(() => {
    if (contentRepositories.length > 0 && !isInEditMode) {
      setRepositoryId(contentRepositories[0]._id);
    }
  }, [contentRepositories.length]);

  return (
    <Card data-testid="select-repository-card">
      <CardContent>
        <h3>Select Repository</h3>
        <StyledFormWrapper>
          <FormControl>
            <StyledRadioGroup>
              {contentRepositories.map((repository) => (
                <Label key={repository._id}>
                  <input
                    onChange={({ currentTarget }) => {
                      onSelectContentRepository(currentTarget.value);
                      setRepositoryId(currentTarget.value);
                    }}
                    checked={repository._id === repositoryId}
                    name="repository"
                    type="radio"
                    value={repository._id}
                  />
                  <span>{repository.label}</span>
                </Label>
              ))}
            </StyledRadioGroup>
          </FormControl>
        </StyledFormWrapper>
      </CardContent>
    </Card>
  );
};

SelectRepository.propTypes = {
  contentRepositories: PropTypes.array.isRequired,
  onSelectContentRepository: PropTypes.func.isRequired,
};

export default React.memo(SelectRepository);
