import React, { useRef, useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';
import styled from 'styled-components';
import { RemoveRedEyeOutlined as VisibilityIcon } from '@mui/icons-material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from "../Tooltip/Tooltip";

const StyledTemplateSelector = styled.div`
  .template-selector {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
    overflow-x: auto;
  }

  .template-name {
    margin: 8px 4px;
    overflow: hidden;
    white-space: nowrap;
  }

  .ellipsis {
    text-overflow: ellipsis;
  }

  .multiline {
    overflow: visible;
    white-space: normal;
  }

  .template-header {
    color: ${({ theme }) => theme.sectionHeader.color};
    font-weight: 500;

    &.has-error {
      color: ${({ theme }) => theme.palette.error};
    }
  }
`;

export const StyledTemplateSelectorImageWrapper = styled.div`
  width: 19%;
  padding: 1px;
  border: 5px solid transparent;
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  color: #fff;
  margin: 6px;
  cursor: pointer;
  border: 1px solid #cccccc;

  background-color: #111564;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &.active {
    outline: 4px solid #111564;
    outline-offset: 2px;
  }

  &:active {
    outline: 4px solid #182098;
    outline-offset: 2px;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.disabled:not(.active) {
    opacity: 0.5;

    + .template-name {
      opacity: 0.5;
    }
  }
`;

const StyledPreviewIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 10px;
`;

const truncateLabel = ({ label, maxLength }) => {
  console.log('truncateLabel', maxLength);
  if (label.length > maxLength) {
    return label.slice(0, maxLength - 3) + '...';
  } else {
    return label;
  }
}

const TemplateSelector = ({
  title = 'Please select a template',
  templates = [],
  onChange,
  value,
  error = false,
  disabled = false,
  showPreviewIcon = false,
  onPreviewIconClick,
  isActive = (currentValue, template) => currentValue === template.id,
  renderImageWrapper = StyledTemplateSelectorImageWrapper,
  labelMaxLength = null,
  testId,
}) => {
  const [textOverflowMap, setTextOverflowMap] = useState({});

  const templateRefs = useRef([]);

  const CustomImageWrapper = renderImageWrapper;

  //region METHODS
  const onTemplateClick = (template) => {
    if (template.id !== value) {
      typeof onChange === 'function' && onChange(template);
    }
  };

  const handleResize = _debounce(() => {
    const newTextOverflowMap = {};

    templateRefs.current.forEach((templateRef, index) => {
      if (templateRef.current) {
        newTextOverflowMap[index] = templateRef.current.scrollWidth > templateRef.current.clientWidth;
      }
    });

    setTextOverflowMap(newTextOverflowMap);
  }, 300);
  //endregion

  //region EFFECTS
  useEffect(() => {
    let mounted = true
    if (mounted) {
      handleResize();

      window.addEventListener('resize', handleResize);
    }

    return () => {
      mounted = false;
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //endregion

  return (
    <StyledTemplateSelector>
      {title && <h4 className={classNames('template-header', { 'has-error': error })}>{title}</h4>}
      <div className="template-selector" data-test-id={testId || ''}>
        {templates.map((template, index) => {
          const templateRef = React.createRef();
          templateRefs.current[index] = templateRef;

          const textOverflow = textOverflowMap[index];

          return (
            <CustomImageWrapper key={template.id}>
              <StyledImage
                src={template.thumbnail}
                onClick={() => onTemplateClick(template)}
                className={classNames({
                  active: isActive(value, template),
                  disabled: disabled || template?.disabled,
                })}
              />
              {showPreviewIcon && (
                <StyledPreviewIcon onClick={() => onPreviewIconClick(template)}>
                  <VisibilityIcon />
                </StyledPreviewIcon>
              )}
              {template.name && (
                <Tooltip
                  materialProps={{ title: template.name, placement: 'bottom-start' }}
                  enabled={textOverflow}
                  material
                >
                  <div
                    className={classNames('template-name', {
                      ellipsis: !labelMaxLength & textOverflow,
                      multiline: labelMaxLength,
                    })}
                    ref={templateRef}
                  >
                    {labelMaxLength
                      ? truncateLabel({ label: template.name, maxLength: labelMaxLength })
                      : template.name
                    }
                  </div>
                </Tooltip>
              )}
            </CustomImageWrapper>
          );
        })}
      </div>
    </StyledTemplateSelector>
  );
};

TemplateSelector.propTypes = {
  title: PropTypes.string,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      thumbnail: PropTypes.string.isRequired,
    }),
  ),
  thumbnailSize: PropTypes.oneOf(['medium', 'large']),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showPreviewIcon: PropTypes.bool,
  onPreviewIconClick: PropTypes.func,
};

export default TemplateSelector;
