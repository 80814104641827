import { getAxiosWithAuthorization } from '../services/index';

const contentFiltersApi = {
  getContentFiltersForRepository: ({ contentRepositoryId, excludeEmptyParents = true }) =>
    getAxiosWithAuthorization({
      url: `/content-repositories/${contentRepositoryId}/content-filters?excludeEmptyParents=${excludeEmptyParents}`,
      method: 'GET',
    })
};

export default contentFiltersApi;
