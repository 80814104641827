import { getAxiosApiV2WithAuthorization } from '../services/index';

const CUSTOM_ATTRIBUTES = '/user-custom-attributes';

const userCustomAttributesApi = {
  getUserCustomAttributes: () =>
    getAxiosApiV2WithAuthorization({
      url: CUSTOM_ATTRIBUTES,
      method: 'GET',
    }),
  updateUserCustomAttribute: ({ attributeId, updatedAttribute }) =>
    getAxiosApiV2WithAuthorization({
      url: `${CUSTOM_ATTRIBUTES}/${attributeId}`,
      method: 'PUT',
      data: updatedAttribute,
    }),
  createUserCustomAttribute: ({ newAttributeData }) =>
    getAxiosApiV2WithAuthorization({
      url: CUSTOM_ATTRIBUTES,
      method: 'POST',
      data: newAttributeData,
    }),
  deleteUserCustomAttribute: ({ attributeId }) =>
    getAxiosApiV2WithAuthorization({
      url: `${CUSTOM_ATTRIBUTES}/${attributeId}`,
      method: 'DELETE',
    }),
};

export default userCustomAttributesApi;
