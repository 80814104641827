import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HtmlTooltip } from '@clatter/ui';
import { ImageNotSupported as ImageNotSupportedIcon } from '@mui/icons-material';

const StyledImage = styled.img`
  width: ${({ size }) => `${size.width}px`};
  height: ${({ size }) => `${size.height}px`};
  background-color: ${({ theme }) => theme.palette.grey};
  border: 1px solid ${({ theme }) => theme.palette.darkGrey};
`;

const StyledNoImage = styled.div`
  width: 300px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.darkGrey};
  border: 1px solid ${({ theme }) => theme.palette.darkGrey};
  background-color: ${({ theme }) => theme.palette.grey};
`;

const NoImage = () => {
  return (
    <StyledNoImage>
      <ImageNotSupportedIcon />
    </StyledNoImage>
  );
};

const getImageSize = ({ url, windowHeight, callback }) => {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    const aspectRatio = img.width / img.height;
    let maxHeightPercentage =  0.65
    let maxHeightLimit = 300;

    if (aspectRatio <= 1) {
      // Portrait or square aspect ratio
      maxHeightPercentage = 0.43;
    }

    const calculatedMaxHeight = windowHeight * maxHeightPercentage;
    const maxHeight = Math.min(calculatedMaxHeight, maxHeightLimit);

    let width = Math.min(maxHeight, img.width);
    let height = width / aspectRatio;

    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    callback({ width: width, height: height });
  };
};

const ThumbnailTooltip = ({ children, url, alt }) => {
  const isValidLink = url.startsWith('https://');
  const [imageSize, setImageSize] = useState(null);

  const calculateImageSize = () => {
    const windowHeight = window.innerHeight;
    getImageSize({ url, windowHeight, callback: setImageSize });
  };

  useEffect(() => {
    calculateImageSize(); // Initial calculation
    window.addEventListener('resize', calculateImageSize);

    return () => {
      window.removeEventListener('resize', calculateImageSize);
    };
  }, [url, window.innerHeight]);

  if (!imageSize) {
    return <>{children}</>;
  }

  return (
    <HtmlTooltip renderHtml={isValidLink ? <StyledImage src={url} alt={alt} size={imageSize} /> : <NoImage />}>
      {children}
    </HtmlTooltip>
  );
};

ThumbnailTooltip.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default ThumbnailTooltip;
