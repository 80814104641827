import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  current,
} from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
export const NOTICES_FEATURE_KEY = 'notices';
export const noticesAdapter = createEntityAdapter();

export const addNoticeByTypeAndMessage = createAction(
  'ADD_NOTICE_BY_TYPE_AND_MESSAGE',
);

export const setNoticeContextKey = createAction('SET_NOTICE_CONTEXT_KEY');

export const initialNoticesState = noticesAdapter.getInitialState({
  contextKey: null,
  noticesByContextKey: {}
});

export const noticesSlice = createSlice({
  name: NOTICES_FEATURE_KEY,
  initialState: initialNoticesState,
  reducers: {
    add: noticesAdapter.addOne,
    remove: noticesAdapter.removeOne,
    removeMany: noticesAdapter.removeMany,
  },
  extraReducers: (builder) => {
    builder.addCase(setNoticeContextKey, (state, data) => {
      if(state.contextKey === data.payload) {
        return
      }
      // clear all notices from previus context
      // and set new contextKey
      noticesAdapter.removeAll(state)
      state.contextKey = data.payload;
    });
    builder.addCase(addNoticeByTypeAndMessage, (state, { payload }) => {
      const currentState = current(state)?.entities;
      for (let existingNoticeKey in currentState) {
        // do not add a notice to state
        // if it is already added in the selected type
        if (
          currentState[existingNoticeKey]?.type === payload?.type &&
          currentState[existingNoticeKey]?.message === payload?.message
        ) {
          return;
        }
      }
      noticesAdapter.addOne(state, payload);
    });
  },
});

const { selectAll } = noticesAdapter.getSelectors();
export const getNoticesState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][NOTICES_FEATURE_KEY];

export const noticesSelectors = {
  selectAllNotices: createSelector(getNoticesState, selectAll),
};
