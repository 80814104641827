import React from 'react';
import styled from 'styled-components';
import { noticeTypes, Typography } from '@clatter/ui';
import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon,
} from '@mui/icons-material';

export const noticeProps = {
  [noticeTypes.error]: {
    color: '#f4e5e5',
    icon: ErrorOutlineIcon,
  },
  [noticeTypes.success]: {
    color: '#e7f4e5',
    icon: CheckCircleOutlinedIcon,
  },
  [noticeTypes.info]: {
    color: '#e5e9f4',
    icon: InfoOutlinedIcon,
  },
  [noticeTypes.warning]: {
    color: '#f4ece5',
    icon: WarningAmberOutlinedIcon,
  },
};

const StyledNotice = styled.div`
  box-shadow: none;
  background-color: ${({ type }) => noticeProps[type]?.color};
  padding: 6px 16px;
  margin-bottom: 15px;

  display: flex;
  align-items: center;

  .icon {
    opacity: 0.9;
    margin-right: 12px;
    padding: 7px 0;
  }

  .message {
    padding: 8px 0;

    .title {
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  .action {
    cursor: pointer;
    margin-left: auto;
    padding: 4px 0 0 16px;
    display: flex;
    align-items: flex-start;
  }
`;

const Notice = ({ title, type, message, id, onClose }) => {
  const Icon = noticeProps[type]?.icon;
  const showCloseButton = onClose && typeof onClose === 'function';
  return (
    <StyledNotice type={type}>
      {Icon && (
        <div className="icon">
          <Icon color={type} />
        </div>
      )}
      <div className="message">
        {title && (
          <Typography className="title" component="p" variant="body1">
            {title}
          </Typography>
        )}
        {message && (
          <Typography component="span" variant="body2">
            {message}
          </Typography>
        )}
      </div>
      {showCloseButton && (
        <div className="action" data-id={id} onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </StyledNotice>
  );
};

export default Notice;
