import getAxiosWithAuthorization from '../services/axiosFactory';

const toolInfoApi = {
  getInfo: () =>
    getAxiosWithAuthorization({
      url: `/info`,
      method: 'GET',
    }),
};

export default toolInfoApi;
