import coley from './coley-1.png';
import optum from './optum-1.png';
import uhc from './uhc-1.png';
import mediatavern from './mediatavern.png';
import fallback from './favicon-1.png';

import logoOptum from './logos/optum.png';
import logoOptumRx from './logos/optumrx-rgb.png';
import logoUhc from './logos/uhc-logo.png';
import logoUhcBlue from './logos/U-mark-blue.svg';
import logoMediatavern from './logos/mediatavern.jpeg';
import logoSurest from './logos/surest.png';
import logoUmr from './logos/umr.jpeg';
import uhcFooter from './logos/uhc-wordmark-stacked-white-01.svg';
import uhcFooterGroupMedicare from './logos/UHC_U-mark_GroupMedicareAdvantage_lockup_wht_RGB.svg';
import logoOptumFinancial from './logos/optum-financial-ora-rgb.svg';

export const favicons = {
  fallback: fallback,
  coley: coley,
  optum: optum,
  uhc: uhc,
  uhcprovider: uhc,
  optumenterprise: optum,
  mediatavern: mediatavern,
  urs: uhc,
};

export const coBrandLogoImage = {
  UMR: logoUmr,
  SUREST: logoSurest,
}

export const clientLogoImage = {
  fallback: logoOptum,
  optum: logoOptumRx,
  optumFinancial: logoOptumFinancial,
  uhc: logoUhc,
  uhcBlue: logoUhcBlue,
  uhcFooter: uhcFooter,
  uhcFooterGroupMedicare: uhcFooterGroupMedicare,
  uhcprovider: logoUhc,
  optumenterprise: logoOptum,
  mediatavern: logoMediatavern,
  urs: logoUhc,
};
