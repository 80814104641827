import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import MaterialTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as muiStyled } from '@mui/system';

const LightTooltip = muiStyled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme?.palette?.common?.white || 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: 11,
  },
}));

const DefaultTooltip = muiStyled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 1,
    lineHeight: '20px',
    padding: '0 8px',
    fontSize: '12px',
  },
}));

const StyledTooltipTrigger = styled.div`
  display: inline-flex;

  & + & {
    margin-left: 8px;
  }
`;

const StyledTooltip = styled.div`
  position: fixed;
  top: ${(props) => ('top' in props ? props.top : 0)};
  left: ${(props) => ('left' in props ? props.left : 0)};
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
  color: #fff;
  line-height: 20px;
  padding: 0 8px;
  font-size: 11px;
  z-index: 9999;
`;

const Tooltip = ({ children, text, material, enabled, materialProps }) => {
  const [position, setPosition] = useState(null);

  const handleShowTooltip = (event) => {
    const {
      top,
      left,
      width,
      height,
    } = event.currentTarget.getBoundingClientRect();

    setPosition({
      top: `${top - height - 8}px`,
      left: `${left + width / 2}px`,
    });
  };

  const handleHideTooltip = () => {
    setPosition(null);
  };

  if (material) {
    const StyledMaterialTooltip = materialProps?.type === 'light' ? LightTooltip : DefaultTooltip;

    return (
      <>
        {
          enabled ?
            <StyledMaterialTooltip {...materialProps}>
              {children}
            </StyledMaterialTooltip>
            : <> { children } </>
        }
      </>
    )
  }

  return (
    <StyledTooltipTrigger
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
    >
      {children}
      {position &&
        ReactDOM.createPortal(
          <StyledTooltip top={position?.top} left={position?.left}>
            {text}
          </StyledTooltip>,
          document.body,
        )}
    </StyledTooltipTrigger>
  );
};

export default Tooltip;
