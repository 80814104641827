import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Stack } from '@mui/material';
import { FormControl, TextControl, Button, TextLink, textLinkVariants } from '@clatter/ui';

const StyledMessage = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 1.2rem;

  p {
    font-size: 1.2rem !important;
  }
`;

const StyledFormContainer = styled.div`
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    margin-bottom: 0;
  }
`;

const PasswordRecoveryForm = ({ onValidSubmit, onBackToLogin, showMessage }) => {
  const formContext = useForm({
    mode: 'onChange',
    defaultValues: { email: '' },
  });
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = formContext;

  const onSubmit = ({ email }) => {
    onValidSubmit({ email });
  };

  return (
    <StyledFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={4}>
          <div>
            {!showMessage && (
              <FormControl label={'Email'} error={errors?.email}>
                <TextControl type="email" placeholder="Email address" required {...register('email')} />
              </FormControl>
            )}
            {showMessage && (
              <StyledMessage>
                <p>
                  An email has been sent to the supplied email address. Follow the instructions in the email to reset
                  your password.
                </p>
                <p>If it doesn't arrive soon, check your spam folder or contact support.</p>
              </StyledMessage>
            )}
          </div>
          <Stack direction="column" spacing={1}>
            {!showMessage && (
              <Button disabled={!isValid} type="submit" fullWidth>
                Send Reset Password Email
              </Button>
            )}
            <TextLink
              onClick={onBackToLogin}
              variant={textLinkVariants.clearDimmed}
              label={!showMessage ? 'Already have an account? Login' : '< Back to login'}
            />
          </Stack>
        </Stack>
      </form>
    </StyledFormContainer>
  );
};

PasswordRecoveryForm.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
  onBackToLogin: PropTypes.func.isRequired,
};

export default PasswordRecoveryForm;
