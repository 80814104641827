import React from 'react';
import styled from 'styled-components';
import ControlError from './ControlError';
import Label from './Label';

const StyledFormControl = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  &.no-padding {
    padding: 0;
  }

  &.align-left {
    flex-direction: row;
    gap: ${({ spacing }) => (spacing ? `${spacing}px` : '20px')};
  }

  label {
    min-width: ${({ labelMinWidth }) =>
      labelMinWidth ? `${labelMinWidth}px` : 'inherit'};
  }
`;

const FormControl = ({
  children,
  label,
  error,
  noPadding,
  spacing,
  labelMinWidth,
  alignLeft = false,
}) => (
  <StyledFormControl
    spacing={spacing}
    labelMinWidth={labelMinWidth}
    className={`${noPadding ? 'no-padding' : ''} ${alignLeft && 'align-left'} form-control`}
  >
    {!!label && <Label>{label}</Label>}
    {children}
    {error && <ControlError message={error.message} />}
  </StyledFormControl>
);

export default FormControl;
