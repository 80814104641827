import { messageTypes } from '@clatter/ui';
import { filter, get, map, toLower } from 'lodash';
import { presentationApi } from '../api';

export const getContentRepoMetaDataDefaultValue = (meta) => {
  if (meta?.type === 'list') {
    // when OPTIONS is available then we have new content-repo structure
    // otherwise we have only defaultValue
    if (meta?.options) {
      return meta?.defaultValue;
    }

    if (meta?.defaultValue?.length > 0) {
      return meta?.defaultValue[0];
    }

    return '';
  }

  return meta?.defaultValue || '';
};

export const getContentRepoMetaDataListOptions = (meta) => {
  if (meta?.type !== 'list') {
    return undefined;
  }

  // when OPTIONS is available then we have new content-repo structure
  // otherwise we have only defaultValue
  if (meta?.options) {
    return meta.options;
  }

  if (Array.isArray(meta?.defaultValue)) {
    return meta?.defaultValue;
  }

  return [];
};

export const getContentRepoMetaDataFieldName = (meta) => {
  if (meta?.name) {
    return meta.name;
  }

  return meta.label;
};

export const checkOverviewSlideLimit = (slides) => {
  const { dividerCount, slideCount } = slides.reduce(
    (acc, slide) => {
      if (slide?.slideType === 'Divider') {
        acc.dividerCount++;
      } else {
        acc.slideCount++;
      }
      return acc;
    },
    {
      dividerCount: 0,
      slideCount: 0,
    },
  );

  return dividerCount <= 100 && slideCount <= 150;
};

export const getAvailableDownloadOptions = (presentation) => {
  const choices = [];

  if (presentation?.pptLocation?.url) {
    choices.push({
      label: 'PowerPoint (.PPTX)',
      value: 'ppt',
    });
  }

  if (presentation?.pdfLocation?.url) {
    choices.push({
      label: 'PDF',
      value: 'pdf',
    });
  }

  if (presentation?.zipLocation?.url) {
    choices.push({
      label: 'Both',
      value: 'zip',
    });
  }

  return choices;
};

const getCoBrandLogoData = (slideType) => {
  const slideList = slideType || [];

  if (slideList.length && slideList[0].coBrandLogoData) {
    return slideList[0].coBrandLogoData;
  }
  return null;
};

export const getCobrandLogoDataBasedOnSlideType = ({
  slide,
  coverSlides,
  dividers,
  blankSlides,
  contentRepo,
  themeId,
}) => {
  const slideLayoutType =
    toLower(get(slide, 'slideLayoutType')) || toLower(get(slide, `slideType`));

  switch (slideLayoutType) {
    case 'content':
      const { slideListByThemes } = slide;
      let isCobrandLogoDataPresent = null;

      if (!contentRepo) {
        return null;
      }
      const { dimensions } = contentRepo;

      map(slideListByThemes, (eachElement) => {
        if (eachElement.themeId === themeId && eachElement.coBrand) {
          let { coBrandData } = eachElement;

          isCobrandLogoDataPresent = {
            width: (coBrandData.width / dimensions.width) * 100,
            height: (coBrandData.height / dimensions.height) * 100,
            x: (coBrandData.x / dimensions.width) * 100,
            y: (coBrandData.y / dimensions.height) * 100,
          };
        } else if (
          eachElement.themeId === themeId &&
          get(eachElement, 'coBrand') === false
        ) {
          isCobrandLogoDataPresent = 'dontShowLogo';
        }
      });

      if (
        isCobrandLogoDataPresent &&
        isCobrandLogoDataPresent !== 'dontShowLogo'
      ) {
        return getCoBrandLogoData([
          {
            coBrandLogoData: isCobrandLogoDataPresent,
          },
        ]);
      } else if (!isCobrandLogoDataPresent) {
        return getCoBrandLogoData(slide);
      }

      break;

    case 'divider':
      return getCoBrandLogoData(dividers);

    case 'blank':
      return getCoBrandLogoData(blankSlides);

    case 'cover':
      return getCoBrandLogoData([slide]);

    case 'primarycover':
      const primaryCoverDetails = coverSlides.filter(
        (element) => element.slideType === 'PrimaryCover',
      );
      const selectedPrimaryCoverDetail = [];
      selectedPrimaryCoverDetail.push(...primaryCoverDetails);
      return getCoBrandLogoData(selectedPrimaryCoverDetail);

    default:
      return null;
  }
};

export const handleSharePresentation = async ({
  type,
  note,
  emails,
  sendMailToSelf,
  presentationId,
  impersonatedUser,
}) => {
  const result = {
    message: '',
    success: true,
  };

  const formatedData = {
    emails: emails,
    sendMailToSelf: sendMailToSelf,
    presentationId: presentationId,
    type: type,
    note: note,
  };

  if (impersonatedUser) {
    formatedData.impersonatedUserId = impersonatedUser?.user_id.split('|')[1];
    formatedData.impersonatedUserEmail = impersonatedUser?.email;
  }

  try {
    const res = await presentationApi.sharePresentation({
      data: formatedData,
    });

    const message = {
      type: messageTypes.success,
      text: `Presentation has been shared successfully.`,
    };

    if (res.data.invalid?.length) {
      const emails = [...res.data.invalid].join(', ');
      message.type = messageTypes.warning;
      message.text = `Presentation has been shared to ${res.data.sent?.length} email(s). Could not share the Presentation to: ${emails}`;
    }

    result.message = message;
  } catch (error) {
    result.success = false;
    result.message = {
      type: messageTypes.error,
      text:
        error.response.data?.error?.message ??
        'Sorry, there was an error sharing Presentation.',
    };
  }

  return result;
};
