import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import { AuthState, initSentry, initTagManager, updateFavicon } from '@clatter/platform';
import { store } from './app/store';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY);

updateFavicon();
initTagManager();
initSentry();

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <BrowserRouter>
        <AuthState>
          <App />
        </AuthState>
      </BrowserRouter>
    </StrictMode>
  </Provider>,
  document.getElementById('root'),
);
