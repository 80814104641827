import React from 'react';
import styled from 'styled-components';

const StyledControlError = styled.div`
  line-height: 24px;
  font-size: 12px;
  color: #fe615a;
`;

const ControlError = ({ message }) => {
  if (!message) {
    return null;
  }

  return <StyledControlError>{message}</StyledControlError>;
};

export default ControlError;
