import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StyledTabsContainer = styled.div`
  display: flex;
  flex-direction: row;

  &.scrollable {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const StyledTabsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'row' : 'column')};

  ${({ vertical }) =>
    vertical &&
    css`
      ${StyledTabsContainer} {
        flex-direction: column;
      }
    `}
`;

const StyledTab = styled.div`
  padding: 0.75rem 1rem;
  background-color: #c1c1c1;
  cursor: pointer;
  color: #666666;
  min-width: 140px;
  display: flex;
  justify-content: space-between;

  &:hover {
    filter: brightness(120%);
    transition: all 0.2s ease-out;
  }

  &.active {
    background-color: white;
    color: black;
    font-weight: bold;

    &:hover {
      filter: none;
    }
  }
`;

const Tabs = ({
  tabs = [],
  activeTab,
  onTabChange,
  children,
  vertical = false,
  scrollable = false,
  onExtraContentClick,
}) => {
  return (
    <StyledTabsWrapper vertical={vertical}>
      <StyledTabsContainer className={classNames({ scrollable: scrollable })}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={tab.label}
            className={classNames({
              active: tab?.active || activeTab === index,
            })}
            onClick={() =>
              tab?.onClick && typeof tab?.onClick === 'function'
                ? tab?.onClick({ tab, index })
                : onTabChange(index)
            }
          >
            {tab.label}
            {tab?.extraContent &&
              typeof tab?.extraContent === 'function' &&
              tab?.extraContent({
                inactive: activeTab !== index,
                tabData: tab,
                onExtraContentClick: onExtraContentClick,
              })}
          </StyledTab>
        ))}
      </StyledTabsContainer>
      {children[activeTab]}
    </StyledTabsWrapper>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  initialActiveTab: PropTypes.number,
  onTabSelect: PropTypes.node,
};

export default Tabs;
