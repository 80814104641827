import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  htmlTooltip: {
    position: 'relative',
    background: 'none',
    border: 'none',
    left: -10,
  },
}));

// this element is needed for tooltip to work properly in case that provided children can't hold ref
const StyledAnchor = styled.div`
  width: 100%;
`;

const HtmlTooltip = ({ children, renderHtml }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.htmlTooltip,
      }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 100 }}
      disableInteractive
      placement="bottom-start"
      title={renderHtml}
    >
      <StyledAnchor>{children}</StyledAnchor>
    </Tooltip>
  );
};

HtmlTooltip.propTypes = {
  renderHtml: PropTypes.node.isRequired,
};

export default HtmlTooltip;
