import React from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useNotices } from '@clatter/platform';
import Notice from './Notice';

const StyledNoticesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .item-enter {
    max-height: 0;
    opacity: 0;
  }

  .item-enter-active {
    max-height: 350px;
    opacity: 1;
    transition: all 300ms;
  }

  .item-exit {
    max-height: 350px;
    opacity: 1;
  }

  .item-exit-active {
    max-height: 0;
    opacity: 0;
    transition: all 300ms;
  }
`;

const NoticesList = () => {
  const { removeNotice, notices } = useNotices();

  const handleRemoveNoticeClick = (event) =>
    removeNotice(event.currentTarget.dataset.id);

  const items = notices.map(({ id, type, message, title }) => {
    return (
      <CSSTransition key={id} timeout={300} classNames="item">
        <div>
          <Notice
            id={id}
            type={type}
            title={title}
            message={message}
            onClose={handleRemoveNoticeClick}
          />
        </div>
      </CSSTransition>
    );
  });

  return (
    <StyledNoticesWrapper id="notices-container">
      <TransitionGroup component={null}>{items}</TransitionGroup>
    </StyledNoticesWrapper>
  );
};

export default NoticesList;
