import { getAxiosWithAuthorization } from '@clatter/platform';

const contentSlidesApi = {
  getContentSlides: ({ contentRepositoryId, themeId }) =>
    getAxiosWithAuthorization({
      url: `v2/${contentRepositoryId}/${themeId}/content-slides`,
      method: 'GET',
    }),

  getContentSlidesByType: ({ contentRepositoryId, themeId, type }) =>
    getAxiosWithAuthorization({
      url: `/${contentRepositoryId}/${themeId}/slides?type=${type}`,
      method: 'GET',
    }),

  downloadContentSlidesDeck: ({
    contentRepositoryId,
    contentSlideCategoryId,
    data,
  }) =>
    getAxiosWithAuthorization({
      url: `/content-repository/${contentRepositoryId}/category/${contentSlideCategoryId}/deck`,
      method: 'POST',
      data,
    }),

  getIngestStatus: ({ ingestId }) =>
    getAxiosWithAuthorization({
      url: `/status/${ingestId}`,
      method: 'GET',
    }),

  downloadContentSlide: (data) =>
    getAxiosWithAuthorization({
      url: `/downloads`,
      method: 'POST',
      data,
    }),
};

export default contentSlidesApi;
