export const featureFlagsMap = {
  SL: {
    reports: 'sl-reports',
    reportPresentationsDownloads: 'sl-report-presentations-downloads',
    reportPresentationsCreate: 'sl-report-presentations-create',
    reportSlidesDownloads: 'sl-report-slides-downloads',
    reportDecksDownloads: 'sl-report-decks-downloads',
    help: 'sl-help',
    hideDownloadSlideDownloadDeckButtons:
      'sl-hide-download-slide-download-deck-buttons',
    starterDeck: 'sl-starter-deck',
    directLinks: 'sl-direct-links',
  },
  SM: {
    reports: 'sm-reports',
    help: 'sm-help',
    reportDocumentsByUSer: 'sm-report-documents-by-user',
    reportDocumentsAssetUsage: 'sm-report-asset-usage',
    reportDocumentsDownloads: 'sm-report-documents-downloads',
    reportDocumentsCreate: 'sm-report-documents-create',
    reportTemplateUsage: 'sm-report-template-usage',
    reportTemplatesUnusedAssets: 'sm-report-template-unused-assets',
  },
  QC: {
    reports: 'qc-reports',
    help: 'qc-help',
  },
  OE: {
    reports: 'oe-reports',
    help: 'oe-help',
  },
  OEKIT: {
    reports: 'oekit-reports',
    help: 'oekit-help',
  },
  CM: {
    reports: 'cm-reports',
    help: 'cm-help',
  },
  MSM: {
    reports: 'msm-reports',
    pageviews: 'msm-reports-pageviews',
    micrositeStatistics: 'msm-reports-microsites-statistics',
    micrositesDetail: 'msm-reports-microsites-detail',
    micrositesCreate: 'msm-reports-microsites-create',
    micrositesPageCreate: 'msm-reports-microsites-page-create',
    micrositesAssetUsage: 'msm-reports-microsites-asset-usage',
    micrositesUnusedAsset: 'msm-reports-microsites-unused-asset',
    trackingPixel: 'msm-tracking-pixel',
    help: 'msm-help',
    analytics: 'msm-analytics',
  },
  ADMIN: {
    reports: 'admin-reports',
    help: 'admin-help',
    userLoginsLastLoginColumn: 'admin-userLogin-last-login-column',
    reportsActiveUsers: 'admin-report-active-users',
    reportsPageViews: 'admin-report-page-views',
    reportsAssetUsage: 'admin-report-asset-usage',
    reportsUnusedAsset: 'admin-report-unused-asset',
    userCustomAttributes: 'user-custom-attributes',
    analytics: 'admin-analytics',
  },
  HOME: {
    reports: 'home-reports',
    help: 'home-help',
  },
  PG: {
    buildPresentationNewUX: 'build-presentation-ux-new',
    ingestJobs: 'pg-ingest-jobs',
    reports: 'pg-reports',
    reportPresentationsByUser: 'pg-report-presentations-by-user',
    reportPresentationsByUserSpike: 'pg-report-presentations-by-user-spike',
    reportSlidesByUser: 'pg-report-slides-by-user',
    reportSlidesDetail: 'pg-report-slides-detail',
    reportSlideUsage: 'pg-report-slide-usage',
    reportPresentationsDownloads: 'pg-report-presentations-downloads',
    reportPresentationsCreate: 'pg-report-presentations-create',
    reportSlidesDownloads: 'pg-report-slides-downloads',
    reportDecksDownloads: 'pg-report-decks-downloads',
    slideDetailView: 'pg-slide-detail-view',
    reportPresentationDetail: 'pg-report-presentation-detail',
    help: 'pg-help',
    hideDownloadSlideDownloadDeckButtons:
      'pg-hide-download-slide-download-deck-buttons',
    starterDeck: 'pg-starter-deck',
    directLinks: 'pg-direct-links',
  },
  common: {
    allowBuildAllPresentationTypes: 'allow-build-all-presentation-types',
  },
};
