import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledTextControl = styled.input`
  border: 1px solid #ddd;
  font-family: 'Maven Pro', sans-serif;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  outline: none;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};

  &:focus:not(:read-only) {
    border-color: #1890ff;
  }

  &:read-only {
    background-color: #f4f4f4;
  }

  &.error {
    border-color: red;

    &:focus:not(:read-only) {
      border-color: red;
    }

  }
`;

const TextControl = React.forwardRef(
  ({ type = 'text', maxLength, hasError = false, fullWidth = true, ...inputProps }, ref) => (
    <StyledTextControl
      ref={ref}
      type={type}
      maxLength={maxLength}
      fullWidth={fullWidth}
      className={classNames({ error: hasError })}
      {...inputProps}
    />
  ),
);

export default TextControl;
