export const noticesTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const imageOrientationTypes = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
}

export const mimeTypes = {
  ZIP: 'application/zip',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PDF: 'application/pdf',
};

export const mimeTypesToFileExtensionMap = {
  [mimeTypes.PDF]: 'pdf',
  [mimeTypes.ZIP]: 'zip',
  [mimeTypes.PPTX]: 'pptx',
};

export const contentSlideDialogIdsMap = {
  SLIDE_SELECTOR: 'slide-selector-dialog',
  SORT_TAB: 'sort-tab-dialog',
  SHARE_DECK: 'share-deck-dialog',
  PRESENTATION_DETAILS: 'presentation-details-dialog',
  SHARE_DECK_SL: 'share-deck-dialog',
  ASSET_USAGE_GALLERY: 'asset-usage-gallery',
};

export const downloadButtonEntries = {
  PPTX: {
    value: mimeTypes.PPTX,
    label: 'Download PPTX',
  },
  PDF: {
    value: mimeTypes.PDF,
    label: 'Download PDF',
  },
  ZIP: {
    value: mimeTypes.ZIP,
    label: 'Download ZIP',
  },
};

export const msmPageTemplatesMap = {
  LIFE_SCIENCES_OPTUM_BRAND: 'Life Sciences - Optum brand',

  PARTNER_SITE: 'Partner Site',
  PARTNER_SITE_COLEY: 'Partner Site - Coley',
  PARTNER_SITE_OPTUM: 'Partner Site - Optum brand',
  PARTNER_SITE_OPTUM_RX: 'Partner Site - Optum Rx brand',
  PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX: 'Partner Site with two content and team - Optum Rx brand', // display: 'Partner Site with two content and team (Optum Rx)',

  PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_RX: 'Partner Site with two content and additional information - Optum Rx brand', // display: 'Partner Site with two content and additional information (Optum Rx)',

  PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_FINANCIAL: 'Partner Site with two content and additional information - Optum Financial', //  display: 'Partner Site with two content and additional information (Optum Financial)',

  PROSPECT_SITE_UHC: 'Prospect Site UHC',
  PROSPECT_SITE_UHC_UMR: 'Prospect Site UHC - UMR',
  PROSPECT_SITE_UHC_SUREST: 'Prospect Site UHC - Surest',
  PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX: 'Prospect Site with Resources and Library - Optum Rx brand', // display: 'Prospect Site with Resources and Library (Optum Rx)'

  RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND: 'Resource and Document Library - Optum brand', // display: 'Resource and Document Library - Optum Brand (Marketing Only)'

  RESOURCES_TEMPLATE_OPTUM: 'Optum Resources - Optum brand',

  EVENTS_OPTUM: 'Events - Optum brand',
  STATE_GOVERNMENT_OPTUM: 'State Government - Optum brand',
  DOWNLOAD_DECKS_UHC: 'Download Decks - UHC brand',
  ADDITIONAL_RESOURCES_WITH_VIDEOS_OPTUM_FINANCIAL: "Additional Resources with videos - Optum financial",
  ADDITIONAL_RESOURCES_OPTUM_FINANCIAL: "Additional Resources - Optum financial",

  UHC_MAIN: 'UHC Main',
  UHC_ADDITIONAL_RESOURCES: 'UHC Additional Resources',
  UHC_VIDEO: 'UHC Video',
  UHC_ADVOCACY_TEMPLATE: 'UHC Advocacy Template',

  URS_MAIN_MA: 'URS Main (MA Logo)',
  URS_ADDITIONAL_RESOURCES_MA: 'URS Additional Resources (MA Logo)',
  URS_VIDEO_MA: 'URS Video (MA Logo)',
  LANDING_PAGE_MA: 'URS LP (MA Logo)',
  LANDING_PAGE_UHC: 'UHC LP',

  EXTERNAL_LINK: 'External Link',
  OLS_LANDING_PAGE: 'OLS Landing Page',
};

export const UNAUTHORIZED_STATUS_CODE = 401;

export const API_UNAVAILABLE_STATUS_CODES = [502, 503, 504];

export const slideTypes = {
  CONTENT: 'Content',
  BLANK: 'Blank',
  DIVIDER: 'Divider',
  COVER: 'Cover',
  PRIMARY_COVER: 'PrimaryCover',
  DECK: 'contentSlideCategory',
}

export const downloadTypes = {
  PRESENTATION: 'presentation',
  ...slideTypes,
}

export const topicErrorsMap = {
  INVALID_SIZE: 'invalidSize'
}

export const topicErrorMessagesMap = {
  [topicErrorsMap.INVALID_SIZE]: 'Invalid size'
}
export const dateRangeGranularityOptions = {
  PAST_30_DAYS: {
    value: 'past-30-days',
    label: 'Past 30 Days',
  },
  LAST_90_DAYS: {
    value: 'last-90-days',
    label: 'Last 90 Days',
  },
  LAST_365_DAYS: {
    value: 'last-365-days',
    label: 'Last 365 Days',
  },
  TODAY: {
    value: 'today',
    label: 'Today',
  },
  THIS_MONTH: {
    value: 'this-month',
    label: 'This Month',
  },
  THIS_QUARTER: {
    value: 'this-quarter',
    label: 'This Quarter',
  },
  THIS_YEAR: {
    value: 'year',
    label: 'This Year',
  },
  PREVIOUS_MONTH: {
    value: 'previous-month',
    label: 'Previous Month',
  },
  PREVIOUS_QUARTER: {
    value: 'previous-quarter',
    label: 'Previous Quarter',
  },
  PREVIOUS_YEAR: {
    value: 'previous-year',
    label: 'Previous Year',
  }
}
