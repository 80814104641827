import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  EditOutlined as EditOutlinedIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const StyledDialogTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 350px;

  .edit-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
  }

  .icon-button {
    cursor: pointer;
    opacity: 0.6;
    color: rgb(99, 99, 99);
  }

  input {
    background: none;
    border: none;
    flex-grow: 1;
    font-weight: bold;
    font-size: 16px;
    color: rgb(99, 99, 99);

    &:active {
      border: none !important;
    }
  }
`;

const StyledInput = styled.input`
  padding: 5px;
  min-width: 240px;
`;

export const StyledTitle = styled.h4`
  margin-bottom: 0;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  flex-grow: 1;
  width: 100%;
  min-width: 250px;
  color: rgb(99, 99, 99);

  @media (min-width: 785px) {
    width: 40%;
  }
`;

const DialogTitle = ({ title, enableEdit, onChange, slideId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const titleInput = useRef(null);

  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onChange(editedTitle);
    setIsEditing(false);
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  // update title when selected slide changes
  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  useEffect(() => {
    handleReset();
  }, [slideId]);

  useEffect(() => {
    if (isEditing && titleInput.current) {
      titleInput.current.focus();
    }
  }, [isEditing, titleInput.current]);

  return (
    <StyledDialogTitle>
      {isEditing ? (
        <>
          <StyledInput
            ref={titleInput}
            value={editedTitle}
            onKeyDown={handleKeyDown}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <div className="edit-buttons">
            <DoneIcon className="icon-button" onClick={handleSave} />
            <CloseIcon className="icon-button" onClick={handleReset} />
          </div>
        </>
      ) : (
        <>
          <StyledTitle>{editedTitle}</StyledTitle>
          {enableEdit && (
            <EditOutlinedIcon
              title="Edit"
              className="icon-button"
              onClick={handleOpenEdit}
            />
          )}
        </>
      )}
    </StyledDialogTitle>
  );
};

export default DialogTitle;
