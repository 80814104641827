export const errorMessages = {
  REQUIRED: {
    type: 'required',
    message: 'This field is required.',
  },
  INVALID: {
    type: 'invalid',
    message: 'Please provide a valid value.',
  },
  UNIQUE: {
    type: 'unique',
    message: 'Please provide an unique value',
  },
  NUMBER: {
    type: 'number.base',
    message: 'Please enter a valid number',
  },
  URI: {
    type: 'string.uri',
    message: 'Please enter a valid url',
  },
  SLIDES_MAX: {
    type: 'array.max',
    message: 'Number of slides exceeds number of maximum slides allowed.',
  },
  SLIDES_REQUIRED: {
    type: 'required',
    message: 'Please select at least one slide.',
  }
};
