import React from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';

export const hasActiveFeatureFlag = ({ featureFlags, flagName }) => {
  const featureFlag = featureFlags.find(({ name }) => name === flagName);

  return !!featureFlag && featureFlag.active;
};

export const useHasActiveFeatureFlag = (flagName) => {
  const featureFlags = useSelector(
    platformSelectors.featureFlag.selectAllFeatureFlags,
  );

  return hasActiveFeatureFlag({
    featureFlags: featureFlags,
    flagName: flagName,
  });
};
