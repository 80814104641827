import { getAxiosWithAuthorization } from '@clatter/platform';

const systemConfigurationApi = {
  updateSystemConfiguration: ({ configurationId, data }) =>
    getAxiosWithAuthorization({
      url: `/system-configuration/${configurationId}`,
      method: 'patch',
      data: data,
    }),
  getSearchPriorities: () => getAxiosWithAuthorization({
    url: `/system-configuration/search-priority`,
    method: 'get',
  }),
};

export default systemConfigurationApi;
