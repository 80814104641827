import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledCheckbox = styled.label`
  input[type='checkbox'] {
    float: left;
    vertical-align: middle;
    margin: 0px 12px 0px 0px;
    width: 16px;
    height: 16px;
    border-radius: 1px;
    border: 2px solid rgb(151, 151, 151);
    position: relative;
    appearance: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }
  input[type='checkbox']:checked,
  &.checked-partially > input[type='checkbox'] {
    background-color: ${({ theme }) => theme.palette.primaryColor};
    border: 2px solid ${({ theme }) => theme.palette.primaryColor};
  }
  &.checked-partially > input[type='checkbox']:after {
    opacity: 0;
  }
  input[type='checkbox']:disabled {
    background-color: ${({ theme }) => theme.palette.darkGrey};
    border-color: ${({ theme }) => theme.palette.darkGrey};
  }
  // checked symbol
  input[type='checkbox']:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
  }
  span {
    cursor: pointer;
    font-size: 12px;
    display: block;
    margin-left: 2rem;
  }
  &.checkbox-secondary {
    span {
      color: #636363;
    }
  }
`;

const CheckboxLegacy = ({
  label,
  value,
  onChange,
  onLabelMouseOver,
  onLabelMouseLeave,
  onLabelClick,
  variant,
  checkedPartially,
  enableLabelClick = false,
  disabled = false,
  testId,
}) => {
  const handleLabelClick = (e) => {
    if (enableLabelClick) {
      e.preventDefault();
      onLabelClick(label);
    }
  };

  return (
    <StyledCheckbox
      className={classNames(variant, {
        'checked-partially': checkedPartially,
      })}
    >
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        disabled={disabled}
        data-test-id={testId || ''}
      />
      {!!label && (
        <span
          onMouseOver={onLabelMouseOver}
          onMouseLeave={onLabelMouseLeave}
          onClick={handleLabelClick}
        >
          {label}
        </span>
      )}
    </StyledCheckbox>
  );
};

export default CheckboxLegacy;
