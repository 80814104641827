import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import contentFiltersApi from '../api/contentFilters.api';
import { PLATFORM_FEATURE_KEY } from './index';
import requestStatus from './requestStatus';

export const CONTENT_FILTERS_FEATURE_KEY = 'contentFilters';
export const contentFiltersAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const setSelectedContentFiltersIds = createAction(
  'SET_SELECTED_CONTENT_FILTERS_IDS',
);

export const fetchContentFiltersForRepository = createAsyncThunk(
  `${CONTENT_FILTERS_FEATURE_KEY}/fetchContentFiltersForRepository`,
  async ({ contentRepositoryId }) =>
    await contentFiltersApi.getContentFiltersForRepository({
      contentRepositoryId,
    }),
);
export const initialContentFiltersState = contentFiltersAdapter.getInitialState(
  {
    loadingStatus: requestStatus.initial,
    selectedContentFiltersIds: [],
    error: null,
  },
);

const mapAiToStore = (filter) => ({
  ...filter,
  label: filter.title,
  children:
    filter.children.length > 0 &&
    filter.children.map((item) => ({
      ...item,
      label: item.title,
    })),
});

export const contentFiltersSlice = createSlice({
  name: CONTENT_FILTERS_FEATURE_KEY,
  initialState: initialContentFiltersState,
  reducers: {
    add: contentFiltersAdapter.addOne,
    remove: contentFiltersAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedContentFiltersIds, (state, { payload }) => {
        state.selectedContentFiltersIds = payload;
      })
      .addCase(fetchContentFiltersForRepository.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(
        fetchContentFiltersForRepository.fulfilled,
        (state, { payload }) => {
          contentFiltersAdapter.setAll(state, payload.data.map(mapAiToStore));
          state.loadingStatus = requestStatus.fulfilled;
        },
      )
      .addCase(
        fetchContentFiltersForRepository.rejected,
        (state, { error }) => {
          state.loadingStatus = requestStatus.error;
          state.error = error.message;
        },
      );
  },
});

export const contentFiltersReducer = contentFiltersSlice.reducer;
export const contentFiltersActions = contentFiltersSlice.actions;

const { selectAll, selectEntities } = contentFiltersAdapter.getSelectors();

export const getContentFiltersState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][CONTENT_FILTERS_FEATURE_KEY];

export const contentFiltersConfigSelectors = {
  selectAllContentFilters: createSelector(getContentFiltersState, selectAll),
  selectContentFiltersEntities: createSelector(
    getContentFiltersState,
    selectEntities,
  ),
  selectSelectedFiltersCount: createSelector(
    getContentFiltersState,
    (state) => state.selectedContentFiltersIds.length,
  ),
};
