export const cookieService = {
  get: (name) => {
    const cookieNameLength = name?.length + 1;
    return document.cookie
      .split(';')
      .reduce((defaultValue, rawCookie) => {
        const cookie = rawCookie.trim();
        if (cookie.substring(0, cookieNameLength) === `${name}=`) {
          defaultValue = decodeURIComponent(cookie.substring(cookieNameLength));
        }
        return defaultValue;
    }, null);
  },
  set: (name, value, expiresAt, domain) => {
    let expires = '';
    let domainValue = '';

    if (expiresAt !== undefined) {
      const date = new Date(expiresAt * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }

    if (domain) {
      domainValue = `; domain=${domain}`;
    }

    document.cookie = `${name}=${value || ''}${expires};path=/${domainValue}`;
  },
  delete: (name, domain) => {
    let domainValue = '';

    if (domain) {
      domainValue = `; domain=${domain}`;
    }

    document.cookie = `${name} =; Path=/${domainValue}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  },
};
