import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chip from '../Chip/Chip';
import { initial } from 'lodash';

const StyledTextTagsControl = styled.div`
  cursor: text;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  width: 100%;
  min-height: 40px;
  max-height: 130px;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;

  &:focus-within {
    border-color: #1890ff;
  }
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  display: inline-block;
  height: 35px;
  min-width: 100px;
  background-color: transparent;
  scroll-snap-align: end;
  flex: 1;
`;

const TextTagsControl = React.forwardRef(
  (
    {
      type,
      tags = [],
      maxLength,
      inputValue,
      onTagsChange,
      fullWidth = true,
      ...inputProps
    },
    ref,
  ) => {
    const handleKeyDown = (e) => {
      if (['Backspace'].includes(e.key) && !e.target.value) {
        e.preventDefault();
        if (tags.length > 0) {
          onTagsChange(initial(tags));
        }
      }

      if (['Enter', ','].includes(e.key)) {
        e.preventDefault();

        if (!inputValue) {
          return;
        }

        const trimmed = inputValue.trim();
        const isInValues = tags.find((item) => item === trimmed);

        if (!trimmed || isInValues) {
          return onTagsChange(null);
        }

        onTagsChange([...tags, trimmed]);
      }
    };

    const handleRemoveTag = (tagToRemove) => {
      onTagsChange(tags.filter((tag) => tag !== tagToRemove));
    };

    return (
      <StyledTextTagsControl fullWidth={fullWidth}>
        {tags.map((tag) => (
          <Chip label={tag} id={tag} key={tag} onClick={handleRemoveTag} />
        ))}

        <StyledInput
          ref={ref}
          type={type ?? 'text'}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
      </StyledTextTagsControl>
    );
  },
);

TextTagsControl.propTypes = {
  type: PropTypes.string,
  tags: PropTypes.array,
  fullWidth: PropTypes.bool,
  maxLength: PropTypes.number,
  inputValue: PropTypes.string,
  onTagsChange: PropTypes.func,
};

export default TextTagsControl;
