import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SIZE = 44;
const DEFAULT_CIRCLE_SIZE = 40;

const StyledCircularProgressBar = styled.div.attrs((props) => ({
  color: props.color || props.theme.palette.secondaryColor,
  size: props.size,
}))`
  display: inline-flex;
  position: relative;

  .circular-progress {
    color: ${({ color }) => color};
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}`};
    transform: rotate(-90deg);

    svg {
      display: block;
      color: ${({ color }) => color};
      overflow: hidden;

      circle {
        stroke: currentColor;
        transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
  .label {
    inset: 0;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
`;

const CircularProgressBar = ({
  value = 0,
  showLabel = true,
  size = DEFAULT_CIRCLE_SIZE,
  thickness = 3.6,
}) => {
  const circleStyle = {};

  const circumference = 2 * Math.PI * ((SIZE - thickness) / 2);
  circleStyle.strokeDasharray = circumference.toFixed(3);
  circleStyle.strokeDashoffset = `${(
    ((100 - value) / 100) *
    circumference
  ).toFixed(3)}px`;

  return (
    <StyledCircularProgressBar className="progressbar" size={size}>
      <div
        className="circular-progress"
        role="progressbar"
        aria-valuenow={Math.round(value)}
      >
        <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
          <circle
            cx={SIZE}
            cy={SIZE}
            r={(SIZE - thickness) / 2}
            fill="none"
            strokeWidth={thickness}
            style={circleStyle}
          />
        </svg>
      </div>

      {showLabel && <div className="label">{Math.round(value)}%</div>}
    </StyledCircularProgressBar>
  );
};

CircularProgressBar.propTypes = {
  value: (props, propName, componentName) => {
    if (props[propName] < 0 || props[propName] > 100) {
      return new Error(
        `Invalid prop ${propName} value (${props[propName]}) supplied to ${componentName}. Validation failed.`,
      );
    }
  },
  showLabel: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number,
};

export default CircularProgressBar;
