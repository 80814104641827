import React from 'react';
import styled from 'styled-components';
import { useAuth } from '@clatter/platform';
import { Button } from '@clatter/ui';

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 760px) {
    margin-top: 20%;
    justify-content: flex-start;
  }
`;

export const SSOLoginScreen = ({ supportEmail, ssoSubject = 'Clatter Access Request' }) => {
  const { loginWithSSO } = useAuth();

  return (
    <StyledContainer>
      <Button fullWidth testId="login_with_sso" onClick={() => loginWithSSO()}>
        Log In with {process.env.NX_SSO_DISPLAY_NAME || 'UnitedHealth Group SSO'}
      </Button>
      <p>
        Don't have an account?{' '}
        <a href={`mailto:${supportEmail}?subject=${ssoSubject}`}>Click here to get started</a>
      </p>
    </StyledContainer>
  );
};
