import { getAxiosApiV2WithAuthorization, getAxiosWithAuthorization } from '../services';

const usersApi = {
  resetPassword: (email) =>
    getAxiosWithAuthorization({
      url: '/users/reset-password',
      method: 'post',
      data: { email: email },
    }),
  fetchUsers: () =>
    getAxiosApiV2WithAuthorization({
      url: `/user`,
      method: 'get',
    }),
};

export default usersApi;
