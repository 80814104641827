import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Stack } from '@mui/material';
import { FormControl, TextControl, Button, TextLink, textLinkVariants } from '@clatter/ui';

const StyledError = styled.p`
  color: ${({ theme }) => theme?.palette?.error} !important;
  margin: 0;
  padding-top: 0 !important;
`;

const LoginForm = ({ onValidSubmit, onForgotPassword, submitDisabled, submitButtonContent, errorMessage }) => {
  const formContext = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = formContext;

  const onSubmit = ({ email, password }) => {
    onValidSubmit({ email, password });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl label={'Email'} error={errors?.email}>
          <TextControl data-test-id="email" type="email" placeholder="Email address" required {...register('email')} />
        </FormControl>
        <FormControl label={'Password'} error={errors?.password}>
          <TextControl
            data-test-id="password"
            type="password"
            placeholder="Password"
            autoComplete="false"
            required
            {...register('password')}
          />
        </FormControl>
      </div>
      <Stack direction="column" spacing={1}>
        <div>
          <StyledError>{errorMessage || '\u00A0'} </StyledError>
          <Button disabled={!isValid || submitDisabled} type="submit" fullWidth testId="form_submit">
            {submitButtonContent ? submitButtonContent : 'Log In'}
          </Button>
        </div>

        <TextLink
          data-test-id="password_recovery"
          onClick={onForgotPassword}
          variant={textLinkVariants.clearDimmed}
          label="Forgot password?"
        />
      </Stack>
    </form>
  );
};

LoginForm.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
