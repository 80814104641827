import {
  createEntityAdapter,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';

export const APP_UI_FEATURE_KEY = 'appUi';
export const appUiAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

export const toggleShowContentSlideModal = createAction(
  'TOGGLE_SHOW_CONTENT_SLIDE_MODAL',
);

export const setContentSlideModalPreviewMode = createAction(
  'SET_CONTENT_SLIDE_MODAL_PREVIEW_MODE',
);

export const initialAppUiState = appUiAdapter.getInitialState({
  showContentSlideModal: false,
  contentSlideModalPreviewMode: false,
});

export const appUiSlice = createSlice({
  name: APP_UI_FEATURE_KEY,
  initialState: initialAppUiState,
  reducers: {
    add: appUiAdapter.addOne,
    remove: appUiAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(toggleShowContentSlideModal, (state) => {
      state.showContentSlideModal = !state.showContentSlideModal;
    });
    builder.addCase(setContentSlideModalPreviewMode, (state, { payload }) => {
      state.contentSlideModalPreviewMode = payload;
    });
  },
});

export const appUiReducer = appUiSlice.reducer;
export const appUiActions = {
  toggleShowContentSlideModal,
  setContentSlideModalPreviewMode,
};
