import { authActions } from './AuthState';
export const getAuth0UserId = (userId) => userId?.split('|').pop();

export default (state, action) => {
  switch (action.type) {
    case authActions.LOGOUT:
      return {
        ...state,
        error: undefined,
        isAuthenticated: false,
        isLoading: false,
        user: undefined,
        impersonate: undefined,
      };
    case authActions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case authActions.SET_IMPERSONATED_USER:
      return {
        ...state,
        error: undefined,
        isAuthenticated: !!action.payload.user,
        isLoading: false,
        impersonate: action?.payload?.user
          ? {
              ...action.payload.user,
              id: getAuth0UserId(action.payload.user.user_id),
            }
          : null,
      };
    case authActions.SET_USER:
      return {
        ...state,
        error: undefined,
        isAuthenticated: !!action.payload.user,
        isLoading: false,
        user: {
          ...action.payload.user,
          id: getAuth0UserId(action.payload.user.sub),
        },
      };
    default:
      return state;
  }
};
