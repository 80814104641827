import { filter } from 'lodash';
import { dfs } from './graph';

export const getSlides = (hierarchy) => {
  const slides = [];

  const appendSlide = (branch) => {
    if ('slide' === branch.label) {
      slides.push(branch);
    }
  };

  hierarchy && hierarchy.forEach((tree) => dfs(tree, appendSlide));
  return slides;
};

const intersect = (a, b) => {
  // console.log(a, b);
  var setB = new Set(b);
  return filter(a, (x) => setB.has(x));
};

/*
 slides have a .contentFilter[] which is the list of filter-value ids
 that apply to the slide
 filterValues is the set of filter-value ids selected in the UI.
 for example, when just one filter-value is selected:
[ "605e79ec8661fdd052ba3b78" ]
 when the filter-value-set is checked (i.e. [x] All), then the
 'F-0' value is included:
[
  "F-0",
  "605e79ec8661fdd052ba3b78",
  "605e79ec8661fdd052ba3b79"
]
 currently, we only implement simple OR across all selected filter values

 the complete solution consists of
    filter fn creator makeSlideFilter( filters ) => fn(slide) => boolean
    input: the repo filters
    output: a function that takes a slide and returns true if the
      slide should be included i.e. it's a pass filter for use with
      Array.filter below
    for each filter, create either an Or term or and And term based
    on the filter .queryType
    then, combine the filter terms in an Or term
    finally, evaluate the expression and return the result
    an Or term looks like:
    return true if ANY of the filters values (i.e. .children) are both

    a) in selectedFilterValues and
    b) in slide.contentFilter
    Or( filter, slide, selectedFilterValues ) {
      let passValues = intersect( filter.children, selectedFilterValues);
      if( 0 == passValues.length )
        // if none of this filters values are selected then
        // we don't even need to check the slide
        return false;
      // there are some of this filter's values selected
      // check if this slide has any of them
      return 0 < intersect(slide.contentFilter, passValues)
    }
    an And term looks like:
    Or( filter, slide, selectedFilterValues ) {
      return intersect(slide.contentFilter, filter.children)
    }

 */
const makeOrTerm = (filter, selectedFilterValues) => {
  //console.debug('makeOrTerm: ', {filter,selectedFilterValues});
  const OrTerm = (slide) => {
    //console.debug('OrTerm: ', {children:filter.children});
    let filterValues = filter.children.map((c) => c._id);
    //console.debug('OrTerm: ', {filterValues});
    let passValues = intersect(filterValues, selectedFilterValues);
    //console.debug('OrTerm: ', {passValues});

    if (0 === passValues.length)
      // if none of this filters values are selected then
      // we don't even need to check the slide
      return false;

    // there are some of this filter's values selected
    // check if this slide has any of them

    return 0 < intersect(slide.contentFilter, passValues).length;
  };
  return OrTerm;
};

const makeAndTerm = (filter, selectedFilterValues) => {
  //console.debug('makeAndTerm: ', {filter,selectedFilterValues});
  const AndTerm = (slide) => {
    let filterValues = filter.children.map((c) => c._id);
    let passValues = intersect(filterValues, selectedFilterValues);
    //console.debug('AndTerm: ', {passValues});

    if (0 === passValues.length)
      // if none of this filters values are selected then
      // we don't even need to check the slide
      return false;

    // there are some of this filter's values selected
    // check if this slide has all of them

    return (
      passValues.length === intersect(slide.contentFilter, passValues).length
    );
  };
  return AndTerm;
};

const makeSlideFilter = (filters, selectedFilterValues) => {
  // console.log("FILTERS:: makeSlideFilter:", filters)
  const passFilters = filters.map((filter) =>
    'or' === filter.queryType
      ? makeOrTerm(filter, selectedFilterValues)
      : makeAndTerm(filter, selectedFilterValues),
  );
  const passFilter = (slide) => passFilters.some((f) => f(slide));
  return passFilter;
};

// we need a copy of the hierachy with only the filtered results. ViewByTopic displays
// the hierarchy it is given. it's clearly a "better" idea in terms of memory pressure to
// 'mark' the slides as e.g. hidden or not. And, to modify ViewByTopic to not display slides
// when slide.hidden etc.
export const updateFilteredSlides = (hierarchy, filteredSlides) => {
  const filteredSlideIds = filteredSlides.map((s) => s._id);
  const updateHidden = (v) => {
    if ('slide' === v.label) {
      v.hidden = filteredSlideIds.indexOf(v._id) === -1;
      //console.debug("updateFilteredSlides: ", v._id, v.hidden, filteredSlideIds.length > 0 ? filteredSlideIds[0] : "[]")
    }
  };
  hierarchy &&
    hierarchy.forEach((t) => {
      dfs(t, updateHidden);
    });
};

export const getFilteredSlides = (slides, filters, selectedFilterValues) => {
  if (selectedFilterValues.length < 1) {
    return slides;
  }

  const passFilter = makeSlideFilter(filters, selectedFilterValues);

  return slides.filter(passFilter);
};

export const filterDataGridTableRowByTableColumns = (
  row,
  tableColumns,
  searchQuery,
) => {
  const escapeRegExp = (value) =>
    value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const searchRegex = new RegExp(escapeRegExp(searchQuery), 'i');
  return (tableColumns || []).some((col) => {
    if ('valueGetter' in col) {
      return searchRegex.test(col.valueGetter({ row: row }));
    }

    if ('valueFormatter' in col) {
      return searchRegex.test(
        col.valueFormatter({
          value: row[col.field],
        }),
      );
    }

    return searchRegex.test(row[col.field]);
  });
};
