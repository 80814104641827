import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { PLATFORM_FEATURE_KEY } from '.';
import { isApiUnavailableError } from '../helpers/error';

export const GLOBAL_ERROR_FEATURE_KEY = 'globalError';

export const throwGlobalError = createAction(
  'THROW_GLOBAL_ERROR',
  (payload) => ({ payload }),
);

export const globalErrorHandlerMiddleware =
  (store) => (next) => async (action) => {
    const res = await next(action);
    if (isApiUnavailableError(res?.error))
      store.dispatch(throwGlobalError(res.error));
    return res;
  };

const globalErrorInitialState = {
  error: null,
};

export const globalErrorSlice = createSlice({
  name: GLOBAL_ERROR_FEATURE_KEY,
  initialState: globalErrorInitialState,
  extraReducers: (builder) => {
    builder.addCase(throwGlobalError, (state, { payload }) => {
      state.error = state.error ?? payload;
    });
  },
});

export const getGlobalErrorsState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][GLOBAL_ERROR_FEATURE_KEY];

export const getError = createSelector(
  getGlobalErrorsState,
  ({ error }) => error,
);

export const globalErrorSelectors = {
  getError,
};
