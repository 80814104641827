import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ClearOutlined as ClearOutlinedIcon,
  Download as DownloadIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@mui/icons-material';
import CircularProgress from '../ProgressBar/CircularProgressBar';
import IconButton from '../IconButton/IconButton';
import classNames from 'classnames';

export const formatFileSize = (fileSize, format = 'KB') => {
  switch (format) {
    case 'KB':
      return `${Math.floor(fileSize / 1000)} KB`;
    case 'MB':
      return `${Math.floor(fileSize / 1000000)} MB`;
    default:
      return `${fileSize} B`;
  }
};

const StyledFileUpload = styled.div.attrs((props) => {
  const styles = {
    borderColor: '#1890ff',
    backgroundColor: '#fff',
  };

  if (props.disabled) {
    styles.borderColor = props.theme.palette.darkGrey;
    styles.backgroundColor = props.theme.palette.grey;
  } else if (props.error) {
    styles.borderColor = props.theme.palette.error;
  }

  return styles;
})`
  .uploader {
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: relative;
    border: ${({ borderColor }) => `1px dashed ${borderColor}`};
    border-radius: 4px;
    padding: 48px 24px;
    text-align: center;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      border-style: solid;
    }

    p {
      margin: 0;
    }

    .progressbar {
      margin-top: 1em;
    }
  }

  .upload-text {
    line-height: 24px;
  }

  .file {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px 12px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .file-icon {
      fill: #1890ff;
      font-size: 16px;
    }

    .file-name {
      &.file-download {
        color: inherit;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      font-weight: 500;
      padding: 0 8px;
      font-size: 14px;
      max-width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-size {
      padding: 0 8px;
      font-size: 12px;
    }

    .file-action {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const FileUpload = ({
  error,
  acceptedFileTypes = '*/*',
  multiple = false,
  onChange,
  onDownload,
  previewOnTop = false,
  renderCustomPreview,
  value = null,
  disabled = false,
  showAcceptedFileTypes = false,
  showProgressbar = false,
  progressbarPercent = 0,
  customText,
  allowDeleteFile = true,
  showDeleteFileConfirmation = false,
  single,
}) => {
  const [files, setFiles] = useState(value);

  const handleChange = (event) => {
    if (event.target.files.length) {
      let nextFiles;

      if (multiple) {
        nextFiles = files
          ? [...files, ...event.target.files]
          : event.target.files;
      } else {
        nextFiles = event.target.files[0];
      }

      setFiles(nextFiles);
      typeof onChange === 'function' && onChange(nextFiles);

      // clear selected files to prevent second upload
      // of the same files when dropping new set of files on the component
      if (multiple) {
        setFiles(null)
      }
    }
  };

  const handleClick = (event) => {
    event.target.value = null;
  };

  const handleRemoveFileClick = (event) => {
    const handleRemoveFileClickInner = () => {
      let nextFiles = null;
      if (multiple) {
        const fileIndex = Number(event.currentTarget.dataset.fileIndex);
        nextFiles = files.filter((file, index) => index !== fileIndex);
      }
      setFiles(nextFiles);
      typeof onChange === 'function' && onChange(nextFiles);
    };

    if (showDeleteFileConfirmation) {
      if (window.confirm('Are you sure you want to remove this file?')) {
        return handleRemoveFileClickInner();
      }

      return false;
    }

    return handleRemoveFileClickInner();
  };

  const renderPreviewItem = (file, index = 0) => {
    const allowFileDownload = typeof onDownload === 'function';
    return (
      <div className="file" key={`${file.name}-${file?.size || 0}`}>
        <InsertDriveFileOutlinedIcon className="file-icon" />
        <div
          onClick={() => allowFileDownload && onDownload(index)}
          className={classNames('file-name', {
            'file-download': allowFileDownload,
          })}
        >
          {file.name}
        </div>
        {file?.size && (
          <div className="file-size">{formatFileSize(file.size)}</div>
        )}

        {typeof onDownload === 'function' && (
          <IconButton
            className="file-action"
            data-file-index={index}
            onClick={() => onDownload(index)}
          >
            <DownloadIcon />
          </IconButton>
        )}
        {allowDeleteFile && (
          <IconButton
            className="file-action"
            data-file-index={index}
            onClick={handleRemoveFileClick}
          >
            <ClearOutlinedIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const renderPreview = () => {
    if (!files || (Array.isArray(files) && files.length === 0)) {
      return null;
    }

    if (typeof renderCustomPreview === 'function') {
      return renderCustomPreview(files, handleRemoveFileClick);
    }

    if (multiple || Array.isArray(files)) {
      return files.map(renderPreviewItem);
    }

    return renderPreviewItem(files);
  };

  if(single && value?.length) {
    return (
      <StyledFileUpload error={error & !disabled} disabled={disabled}>
        {previewOnTop && renderPreview()}
      </StyledFileUpload>
    )
  }

  return (
    <StyledFileUpload error={error & !disabled} disabled={disabled}>
      {previewOnTop && renderPreview()}
      <div className="uploader">
        <p className="upload-text">
          {customText
            ? customText
            : `Drop file${multiple ? 's' : ''} here or click to upload`}
        </p>
        {showAcceptedFileTypes && (
          <p className="upload-text">
            {`Accepted file types: ${acceptedFileTypes}`}
          </p>
        )}
        {showProgressbar && <CircularProgress value={progressbarPercent} />}
        <input
          disabled={disabled}
          type="file"
          id="file"
          name="file"
          onChange={handleChange}
          onClick={handleClick}
          accept={acceptedFileTypes}
          multiple={multiple}
        />
      </div>
      {!previewOnTop && renderPreview()}
    </StyledFileUpload>
  );
};

const FileType = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.number,
});

FileUpload.propTypes = {
  acceptedFileTypes: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default FileUpload;
