import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { nth } from 'lodash';
import systemSettingsApi from '../api/systemSettings.api';
import requestStatus from './requestStatus';
import { PLATFORM_FEATURE_KEY } from '@clatter/platform';
export const SYSTEM_PROFILE_FEATURE_KEY = 'systemProfile';

export const fetchSystemProfile = createAsyncThunk(
  `${SYSTEM_PROFILE_FEATURE_KEY}/fetchStatus`,
  systemSettingsApi.getSystemProfile,
);

export const initialSystemProfileState = {
  landingPageUrl: undefined,
  landingPageSiteName: undefined,
  loadingStatus: requestStatus.initial,
  error: null,
};

export const systemProfileSlice = createSlice({
  name: SYSTEM_PROFILE_FEATURE_KEY,
  initialState: initialSystemProfileState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemProfile.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchSystemProfile.fulfilled, (state, { payload }) => {
        state.landingPageUrl = payload.data.landing_page_url;
        (state.landingPageSiteName = payload.data.landing_page_url
          ? nth(payload.data.landing_page_url.split('/'), -2)
          : undefined),
          (state.loadingStatus = requestStatus.fulfilled);
      })
      .addCase(fetchSystemProfile.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      });
  },
});

export const systemProfileReducer = systemProfileSlice.reducer;

export const systemProfileActions = systemProfileSlice.actions;

export const getSystemProfileState = (rootState) =>
  rootState[PLATFORM_FEATURE_KEY][SYSTEM_PROFILE_FEATURE_KEY];

export const systemProfileSelectors = {
  selectSystemProfile: createSelector(getSystemProfileState, (state) => state),
  loadingStatus: createSelector(
    getSystemProfileState,
    ({ loadingStatus }) => loadingStatus,
  ),
};
